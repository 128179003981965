export default {
  key1000000: `copy`,
  key1000001: `Title:`,
  key1000002: `Link address:`,
  key1000003: `The content has been deactivated or deleted!`,
  key1000004: `Successfully copied!`,
  key1000005: `Please enter the menu keywords`,
  key1000006: `Please enter the correct keywords`,
  key1000007: `Please enter a keyword`,
  key1000008: `Publishing tools`,
  key1000009: `Transit Listing`,
  key1000010: `YMS Mall`,
  key1000011: `Please enter keywords.`,
  key1000012: `search`,
  key1000013: `All product categories`,
  key1000014: `Currently, there are`,
  key1000015: `The order has exceeded the deadline and has not been shipped,`,
  key1000016: `Please click to process`,
  key1000017: `operation management`,
  key1000018: `Warehouse Management`,
  key1000019: `View more`,
  key1000020: `Time zone:`,
  key1000021: `Your current time:`,
  key1000022: `UTC:`,
  key1000023: `Log out`,
  key1000024: `Operating system`,
  key1000025: `Supplier system`,
  key1000026: `Distributor System`,
  key1000027: `Merchant ID:`,
  key1000028: `Tongtool Customer code:`,
  key1000029: `Exiting`,
  key1000030: `Become a distributor`,
  key1000031: `Sign up now`,
  key1000032: `Becoming a supplier`,
  key1000033: `Supplier settlement`,
  key1000034: `Entry Guide`,
  key1000035: `Supplier login`,
  key1000036: `Help Center`,
  key1000037: `common problem`,
  key1000038: `Legal Notices`,
  key1000039: `Terms of Service`,
  key1000040: `Privacy Policy`,
  key1000041: `About Us`,
  key1000042: `Introduction to YMS Mall`,
  key1000043: `Platform business`,
  key1000044: `Contact us`,
  key1000045: `Business Consulting`,
  key1000046: `Distributor Online Services`,
  key1000047: `Shenzhen Yunmai Supply Chain Management Co., Ltd.`,
  key1000048: `Record number: Guangdong ICP No. 2020140415-1`,
  key1000049: `Many`,
  key1000050: `Massive high-quality supply`,
  key1000051: `20w+SKU, daily update of 500+`,
  key1000052: `Quick`,
  key1000053: `One-click upload to all platforms`,
  key1000054: `A must for opening a store, open a store in 5 minutes`,
  key1000055: `Okay`,
  key1000056: `Professional team quality assurance`,
  key1000057: `Professional picking, sorting, packaging, shipping and other services`,
  key1000058: `Province`,
  key1000059: `0 inventory to open a store, no financial pressure`,
  key1000060: `No need to stock up and stock up, pay first and then pay`,
  key1000061: `Please enter keywords to search`,
  key1000062: `Upload image search`,
  key1000063: `One-stop cross-border clothing distribution`,
  key1000064: `The format of the uploaded file is incorrect,`,
  key1000065: `Please select a file in jpg, jpeg, or png format`,
  key1000066: `File size is limited, file`,
  key1000067: `too big,`,
  key1000068: `No more than 20M`,
  key1000069: `All products`,
  key1000070: `Discontinued`,
  key1000071: `Merchant number:`,
  key1000072: `Exit`,
  key1000073: `Please log in`,
  key1000074: `free registration`,
  key1000075: `Message`,
  key1000076: `Getting started`,
  key1000077: `personal center`,
  key1000078: `my collection`,
  key1000079: `My Order`,
  key1000080: `Hi，欢迎来到TONGTOOL！`,
  key1000081: `Hi, welcome to YMS Mall!`,
  key1000082: `Search from the whole site`,
  key1000083: `Search from our shop`,
  key1000084: `The operation was successful!`,
  key1000085: `product name:`,
  key1000086: `Please select`,
  key1000087: `No matching data`,
  key1000088: `Loading`,
  key1000089: `No Data`,
  key1000090: `More`,
  key1000091: `Delete`,
  key1000092: `No filter data`,
  key1000093: `Confirm`,
  key1000094: `Reset`,
  key1000095: `All`,
  key1000096: `Confirm`,
  key1000097: `Cancel`,
  key1000098: `Custom Column Display`,
  key1000099: `Custom column display`,
  key1000100: `Restore default`,
  key1000101: `Save as default`,
  key1000102: `Save successfully`,
  key1000103: `More Options(Recipient country, Payment date, etc.)`,
  key1000104: `Last week`,
  key1000105: `Last month`,
  key1000106: `Last three months`,
  key1000107: `Spot shipping`,
  key1000108: `Logistics waybill`,
  key1000109: `Yuncang noodles`,
  key1000110: `No waybill`,
  key1000111: `Mixed waybill`,
  key1000112: `File size exceeds the limit, up to 10M`,
  key1000113: `The system is busy and the file upload failed!`,
  key1000114: `File format error`,
  key1000115: `pack up`,
  key1000116: `No relevant records`,
  key1000117: `System Busy, Please Try Again`,
  key1000118: `Password can not be blank`,
  key1000119: `The password cannot contain Chinese characters`,
  key1000120: `Please fill in the email address.`,
  key1000121: `Not valid mailbox or incorrect mailbox format`,
  key1000122: `Mobile phone number cannot be empty`,
  key1000123: `Incorrect phone number format`,
  key1000124: `Only numeric format numbers are allowed to be entered`,
  key1000125: `The fixed phone number format is incorrect`,
  key1000126: `The address of a province or city cannot be empty`,
  key1000127: `Please select the complete province/city address`,
  key1000128: `Confirm whether to remove`,
  key1000129: `Tips`,
  key1000130: `Successful operation`,
  key1000131: `Parcel number`,
  key1000132: `Data coverage reminder`,
  key1000133: `The new sales product information will overwrite the already generated sales product information, which cannot be restored after generation. Do you want to generate it?`,
  key1000134: `Generate Now`,
  key1000135: `Please check the data to generate sales information first!`,
  key1000136: `The task has been successfully generated. Please check the results in the Generate Sales Information module.`,
  key1000137: `The current user does not have warehouse management settings that are not enabled`,
  key1000138: `Got it`,
  key1000139: `The current user has enabled warehouse management settings`,
  key1000140: `Price adjustment`,
  key1000141: `Jump failed!`,
  key1000142: `General cargo`,
  key1000143: `Pure battery`,
  key1000144: `Battery matching`,
  key1000145: `liquid`,
  key1000146: `Drugs`,
  key1000147: `powder`,
  key1000148: `Dangerous goods`,
  key1000149: `Built-in battery`,
  key1000150: `Button Battery`,
  key1000151: `With magnetic`,
  key1000152: `1 day`,
  key1000153: `2 days`,
  key1000154: `3 days`,
  key1000155: `4 days`,
  key1000156: `5 days`,
  key1000157: `6 days`,
  key1000158: `7 days`,
  key1000159: ` same`,
  key1000160: `image`,
  key1000161: `Unmatched`,
  key1000162: `Specified products`,
  key1000163: `Designated site`,
  key1000164: `Designated account number`,
  key1000165: `and`,
  key1000166: `or`,
  key1000167: `with`,
  key1000168: `not with`,
  key1000169: `Transportation type is null.`,
  key1000170: `Add success`,
  key1000171: `Operation failed, please try again.`,
  key1000172: `Delete failed, please try again`,
  key1000173: `Designated shop`,
  key1000174: `designated country`,
  key1000175: `Specified classification`,
  key1000176: `Please enter a full postcode.`,
  key1000177: `Designate basic logistics`,
  key1000178: `Multiple products`,
  key1000179: `Single item`,
  key1000180: `Pick list:`,
  key1000181: `Picking warehouse:`,
  key1000182: `Logistics provider:`,
  key1000183: `Picking order type:`,
  key1000184: `Number of outbound orders:`,
  key1000185: `Creation time:`,
  key1000186: `Number of SKUs:`,
  key1000187: `Pickers:`,
  key1000188: `Number of SKUs:`,
  key1000189: `Storage area`,
  key1000190: `Storage bin`,
  key1000191: `Quantity to be picked`,
  key1000192: `Chinese description`,
  key1000193: `Product variation`,
  key1000194: `English description`,
  key1000195: `Batch No.`,
  key1000196: `Logistics providers`,
  key1000197: `Waiting`,
  key1000198: `Logistics channel`,
  key1000199: `Multiple shipping method`,
  key1000200: `Select the referenced channel`,
  key1000201: `Please select a logistics provider:`,
  key1000202: `Please select a logistics channel:`,
  key1000203: `Logistics channel name cannot be null.`,
  key1000204: `Close`,
  key1000205: `SKU Information`,
  key1000206: `Specifications:`,
  key1000207: `Allocated quantity`,
  key1000208: `Quantity Available`,
  key1000209: `The available quantity must be an integer greater than or equal to 0`,
  key1000210: `Supplier Inventory Details`,
  key1000211: `Details of central warehouse storage`,
  key1000212: `Processing...`,
  key1000213: `Choose category`,
  key1000214: `Selected category name:`,
  key1000215: `Operation log`,
  key1000216: `attribute`,
  key1000217: `Attribute value`,
  key1000218: `Operation type`,
  key1000219: `description of operation content`,
  key1000220: `operation time`,
  key1000221: `Operator`,
  key1000222: `Distributor Operations`,
  key1000223: `Supplier`,
  key1000224: `Original value:`,
  key1000225: `After modification:`,
  key1000226: `Edit Properties`,
  key1000227: `Required`,
  key1000228: `Not required`,
  key1000229: `Recommended`,
  key1000230: `Specification properties`,
  key1000231: `Normal attributes`,
  key1000232: `Multiple selection of attribute values`,
  key1000233: `Single Choice of Attribute Values`,
  key1000234: `It is a size attribute`,
  key1000235: `Not a size attribute`,
  key1000236: `Allow custom attribute values`,
  key1000237: `Custom attribute values are not allowed`,
  key1000238: `Edit attribute values`,
  key1000239: `Menu settings`,
  key1000240: `Quick search sidebar menu`,
  key1000241: `：warehouse:`,
  key1000242: `Upload file:`,
  key1000243: `File Name:`,
  key1000244: `When imported data already exists:`,
  key1000245: `Please select the file`,
  key1000246: `Template download`,
  key1000247: `ignore`,
  key1000248: `Update`,
  key1000249: `Overwrite`,
  key1000250: `add`,
  key1000251: `导入成功，当前导入的ymsSku不存在：`,
  key1000252: `Warehouse`,
  key1000253: `Import`,
  key1000254: `Failed to upload files, please try again`,
  key1000255: `Tips`,
  key1000256: `Upload success`,
  key1000257: `The storage area of the location does not match:`,
  key1000258: `The selected warehouse does not have this storage area. Please verify and try again`,
  key1000259: `The selected warehouse does not have this location. Please verify and try again`,
  key1000260: `There is duplicate data, please confirm and try again`,
  key1000261: `导入新增库存货主角色存在异常，请核对后重试`,
  key1000262: `导入新增库存货品供应商商户错误`,
  key1000263: `Download file template failed!`,
  key1000264: `Please select an upload file first`,
  key1000265: `Export type:`,
  key1000266: `Task Number:`,
  key1000267: `Export time:`,
  key1000268: `Select a date`,
  key1000269: `query`,
  key1000270: `Serial number`,
  key1000271: `Task Number`,
  key1000272: `export type`,
  key1000273: `Export time`,
  key1000274: `export status`,
  key1000275: `Exporting`,
  key1000276: `System operation`,
  key1000277: `Remarks`,
  key1000278: `Operation`,
  key1000279: `The file is kept for 7 days, and it cannot be downloaded after 7 days!`,
  key1000280: `download files`,
  key1000281: `in exporting`,
  key1000282: `complete export`,
  key1000283: `Failed to export`,
  key1000284: `Date format error`,
  key1000285: `Message Center`,
  key1000286: `View all notifications`,
  key1000287: `Please input`,
  key1000288: `Selected`,
  key1000289: `term`,
  key1000290: `Submit`,
  key1000291: `skip`,
  key1000292: `other`,
  key1000293: `Required fields cannot be empty!`,
  key1000294: `Please enter category keywords`,
  key1000295: `All categories`,
  key1000296: `Please enter the name of the carrier`,
  key1000297: `Carrier Name:`,
  key1000298: `Carrier`,
  key1000299: `Carrier name`,
  key1000300: `Carrier phone`,
  key1000301: `Usable or not`,
  key1000302: `available`,
  key1000303: `Unavailable`,
  key1000304: `Pick`,
  key1000305: `The name of the carrier cannot be empty!`,
  key1000306: `Reminder later`,
  key1000307: `Successfully reviewed, the system will send an announcement at the specified time`,
  key1000308: `Successfully returned`,
  key1000309: `Cancellation success`,
  key1000310: `The operation was successful！`,
  key1000311: `添加多张图片`,
  key1000312: `已选择上传图片：`,
  key1000313: `图片要求：`,
  key1000314: `建议上传800*800像素以上正方形实拍大图，比如：800*800px，1000*1000px，1500*1500px，最大不超过5MB，，若不符合该尺寸则会导致上传失败。`,
  key1000315: `Clear images without watermarks;`,
  key1000316: `Include the overall style and product details of the product, such as selfie model images, poster images, white background images, 3D images, detail images, etc.`,
  key1000317: `4.点击图片可使用系统提供的裁切工具助您更好的展示商品的特点！`,
  key1000318: `点击裁剪`,
  key1000319: `删除图片`,
  key1000320: `Upload pictures`,
  key1000321: `确定上传`,
  key1000322: `图片加载失败`,
  key1000323: `已成功上传`,
  key1000324: `张图片`,
  key1000325: `张图片，上传失败`,
  key1000326: `张图片,`,
  key1000327: `失败原因：图片尺寸`,
  key1000328: `and`,
  key1000329: `文件大小超出限制，最大为5M！`,
  key1000330: `上传图片格式错误！系统仅支持JPG、JPEG、PNG、WEBP、BMP格式图片上传`,
  key1000331: `上传图片中...`,
  key1000332: `Have read and accepted`,
  key1000333: `and`,
  key1000334: `Successfully opened!`,
  key1000335: `Need to read and accept the service terms and privacy policy`,
  key1000336: `Out of stock quantity cannot be empty`,
  key1000337: `Expected delivery time cannot be empty`,
  key1000338: `Please select the estimated delivery time`,
  key1000339: `View by Order`,
  key1000340: `View by SKU`,
  key1000341: `stockout`,
  key1000342: `Not out of stock`,
  key1000343: `replenishment`,
  key1000344: `Halt sales`,
  key1000345: `The system will automatically suspend the sale of this SKU`,
  key1000346: `A maximum of 100 rows of order data can be processed in a single pop-up window`,
  key1000347: `Process Later`,
  key1000348: `Confirm`,
  key1000349: `Timed order processing`,
  key1000350: `Order No.`,
  key1000351: `Quantity `,
  key1000352: `Out of stock quantity`,
  key1000353: `Estimated delivery time`,
  key1000354: `mark as out-of-stock`,
  key1000355: `The following order has expired. Please update the estimated delivery time.`,
  key1000356: `The selected order product information is as follows:`,
  key1000357: `The expected delivery time should be greater than today's date!`,
  key1000358: `Successfully marked!`,
  key1000359: `Import Freight Template`,
  key1000360: `Billing method:`,
  key1000361: `Weight calculation method:`,
  key1000362: `Select the file:`,
  key1000363: `Import:`,
  key1000364: `(Volume weight:`,
  key1000365: `Bulk upload`,
  key1000366: `Download`,
  key1000367: `Save`,
  key1000368: `General accounting`,
  key1000369: `Continuous recosting`,
  key1000370: `fixed charges`,
  key1000371: `Actual weight`,
  key1000372: `Throwing weight`,
  key1000373: `Comparison between the two`,
  key1000374: `Overwrite the original data`,
  key1000375: `The same coverage of the country, different additions`,
  key1000376: `Select upload file`,
  key1000377: `select designated exception`,
  key1000378: `This condition is used to screen abnormal situations. If any one of the following conditions is met, this condition is considered to be satisfied.`,
  key1000379: `The number of spaces in the name characters is less than`,
  key1000380: `Russian Post requires to write the full name of recipient, here you can enter 2`,
  key1000381: `The number of name characters is less than`,
  key1000382: `Entering 1 means the recipient name is empty.`,
  key1000383: `The character length of address is less than`,
  key1000384: `The total character length of address1 and address2`,
  key1000385: `The character length of city name is less than`,
  key1000386: `Entering 1 means the city name is empty.`,
  key1000387: `The character length of province/state is less than`,
  key1000388: `Entering 1 means the province/state name is empty.`,
  key1000389: `The character length of postcode is less than`,
  key1000390: `Entering 1 means the zip code is empty.`,
  key1000391: `The character length of telephone number is less than`,
  key1000392: `The condition will be established only if both telephone number and cellphone number are less than the value.`,
  key1000393: `Automatically generated after approval`,
  key1000394: `Category:`,
  key1000395: `Product status:`,
  key1000396: `Chinese title:`,
  key1000397: `Supplier SPU:`,
  key1000398: `Number of SKUs:`,
  key1000399: `Total number of products:`,
  key1000400: `Temporary storage`,
  key1000401: `on sale`,
  key1000402: `Supplier SKU`,
  key1000403: `Specification`,
  key1000404: `Product Status`,
  key1000405: `Supply price`,
  key1000406: `on shelf`,
  key1000407: `Print barcode`,
  key1000408: `Print QR code`,
  key1000409: `Are you sure to delete?`,
  key1000410: `Print prompt`,
  key1000411: `SKU contains Chinese and currently does not support barcode printing. Please use QR code printing!`,
  key1000412: `Reason for rejection`,
  key1000413: `A large number of spot`,
  key1000414: `Promote with confidence`,
  key1000415: `图片裁剪`,
  key1000416: `裁切须知`,
  key1000417: `1.为了保证您的商品可以更好的展示给分销商并带来更多的订单，上传的商品图片长宽尺码必须≥800。`,
  key1000418: `2.若原始上传的图片尺码长宽≤800，则需要重新上传图片。`,
  key1000419: `3.商户可通过裁切，调整已上传图片的展示比例，突出商品的特点。`,
  key1000420: `图片宽度：`,
  key1000421: `图片高度：`,
  key1000422: `Preview`,
  key1000423: `+重新上传图片`,
  key1000424: `图片尺寸至少要≥`,
  key1000425: `文件大小超出限制，最大为`,
  key1000426: `加载图片中...`,
  key1000427: `裁剪图片.jpeg`,
  key1000428: `return`,
  key1000429: `Return instructions:`,
  key1000430: `Please enter a return description`,
  key1000431: `After returning, this announcement will be circulated as pending modification. Please confirm whether to return the modification?`,
  key1000432: `Return description cannot be empty`,
  key1000433: `Log content`,
  key1000434: `Change to`,
  key1000435: `Operation date`,
  key1000436: `Operating system operation`,
  key1000437: `Supplier system operation`,
  key1000438: `SPU price adjustment`,
  key1000439: `Partial SKU price adjustment`,
  key1000440: `SKU price adjustment`,
  key1000441: `Issue orders of different storage area groups are allowed to enter the same pick list？`,
  key1000442: `Issue orders from different storage area areas are allowed to enter the same pick list？`,
  key1000443: `Packages from different logistics providers are allowed to enter the same picking list？`,
  key1000444: `Allow packages of the same logistics provider with different shipping methods to enter the same picking list？`,
  key1000445: `Maximum number of packages per regular picking list`,
  key1000446: `Maximum number of packages per stocking and picking list`,
  key1000447: `Allow`,
  key1000448: `Not allowed`,
  key1000449: `所属站点：`,
  key1000450: `Specifications/General Properties:`,
  key1000451: `Attribute value single selection/multiple selection:`,
  key1000452: `Fill in requirements:`,
  key1000453: `Size attribute:`,
  key1000454: `是否需填写比例：`,
  key1000455: `ordinary`,
  key1000456: `Multiple choices`,
  key1000457: `Single choice`,
  key1000458: `No`,
  key1000459: `Yes`,
  key1000460: `Attribute name`,
  key1000461: `Optional value`,
  key1000462: `Allow customization`,
  key1000463: `尺码组名：`,
  key1000464: `Add attribute values`,
  key1000465: `The property has been used and cannot be modified.`,
  key1000466: `The attribute value has been used and cannot be modified.`,
  key1000467: `New attributes`,
  key1000468: `Chinese Name `,
  key1000469: `English-US`,
  key1000470: `English-UK`,
  key1000471: `English-AU`,
  key1000472: `English-EU`,
  key1000473: `French name`,
  key1000474: `German name`,
  key1000475: `Spanish name`,
  key1000476: `Dutch name`,
  key1000477: `Polish name`,
  key1000478: `Portuguese name`,
  key1000479: `Italian name`,
  key1000480: `罗马尼亚语名称`,
  key1000481: `法码`,
  key1000482: `德码`,
  key1000483: `西班牙码`,
  key1000484: `荷兰码`,
  key1000485: `波兰码`,
  key1000486: `葡萄牙码`,
  key1000487: `意大利码`,
  key1000488: `罗马尼亚码`,
  key1000489: `An attribute with the same name already exists. Please modify the attribute name before submitting!`,
  key1000490: `Chinese, English US attribute names cannot be empty!`,
  key1000491: `同语种的属性值都要必填!`,
  key1000492: `Attribute values cannot be the same in the same language!`,
  key1000493: `Add Successfully`,
  key1000494: `Failed to upload`,
  key1000495: `是否取消该尺码组别？`,
  key1000496: `是否确认取消该尺码组别？确认后该尺码组别不会显示在属性值里，所填写的数据也会清空。`,
  key1000497: `确定取消`,
  key1000498: `state`,
  key1000499: `Enable`,
  key1000500: `Disable`,
  key1000501: `Reference image`,
  key1000502: `brief introduction`,
  key1000503: `请填写国家尺码信息`,
  key1000504: `同语种下属性值不能相同`,
  key1000505: `Choose a store to publish`,
  key1000506: `Site:`,
  key1000507: `Publishing store:`,
  key1000508: `Publication template:`,
  key1000509: `Set up`,
  key1000510: `Please select a publishing store`,
  key1000511: `Please select a publication template`,
  key1000512: `Failed to publish draft!`,
  key1000513: `Message notification`,
  key1000514: `No more data...`,
  key1000515: `Let's see later`,
  key1000516: `您的商品：`,
  key1000517: `，审核未通过，驳回原因：`,
  key1000518: `in processing`,
  key1000519: `Marked as read!`,
  key1000520: `cannot be empty`,
  key1000521: `Supplier Currency`,
  key1000522: `Add Supplier Currency`,
  key1000523: `Add Distributor Currency`,
  key1000524: `Change currency`,
  key1000525: `Distributor Currency`,
  key1000526: `Currency`,
  key1000527: `Inventory event details`,
  key1000528: `Inventory change type:`,
  key1000529: `Operating time:`,
  key1000530: `Select start date`,
  key1000531: `Select end date`,
  key1000532: `to`,
  key1000533: `Export excel`,
  key1000534: `Changes in available inventory`,
  key1000535: `Allocated inventory changes`,
  key1000536: `Event type`,
  key1000537: `Allocate inventory`,
  key1000538: `Cancel distribution`,
  key1000539: `Outbound`,
  key1000540: `Check inventory`,
  key1000541: `Sort Recall`,
  key1000542: `Processing and warehousing`,
  key1000543: `Processing outbound`,
  key1000544: `Available inventory balance`,
  key1000545: `Allocation inventory changes`,
  key1000546: `Allocation of inventory balance`,
  key1000547: `Reference no.`,
  key1000548: `receive the products`,
  key1000549: `Allocate`,
  key1000550: `Unassign`,
  key1000551: `Inventory movement`,
  key1000552: `Stock freeze`,
  key1000553: `Unfreeze`,
  key1000554: `Inventory adjustment`,
  key1000555: `Transfer out`,
  key1000556: `Receipt on shelf`,
  key1000557: `Adjust the order`,
  key1000558: `Cancel out`,
  key1000559: `Synchronize inventory`,
  key1000560: `Cancel receiving`,
  key1000561: `Recycle inventory`,
  key1000562: `Return to the warehouse`,
  key1000563: `Import inventory`,
  key1000564: `Update inventory`,
  key1000565: `Failed to get list data`,
  key1000566: `选择日期时间`,
  key1000567: `Please enter the template name`,
  key1000568: `The generated report file can be kept by the system for a maximum of 7 days, and downloading is not supported if it exceeds 7 days.`,
  key1000569: `View Export List`,
  key1000570: `Export template:`,
  key1000571: `Manage export templates`,
  key1000572: `Back`,
  key1000573: `Time range:`,
  key1000574: `Please select an export dimension:`,
  key1000575: `Please check the fields to be exported:`,
  key1000576: `Select All`,
  key1000577: `Save as new template`,
  key1000578: `export`,
  key1000579: `Not selected`,
  key1000580: `added successfully!`,
  key1000581: `The template name cannot be empty!`,
  key1000582: `Do you want to delete the current template?`,
  key1000583: `Deleting Success`,
  key1000584: `Select a shipping method`,
  key1000585: `Pack up`,
  key1000586: `spread`,
  key1000587: `Image upload specifications`,
  key1000588: `Uploading specifications for rotation charts`,
  key1000589: `It is recommended to upload images in the following order, with a reasonable and clear image sequence for easy viewing of products.`,
  key1000590: `Single image size`,
  key1000591: `The recommended size is 800 * 800px or above (800 * 800px, 1000 * 1000px, 1500 * 1500px), and the maximum size should not exceed 5MB.`,
  key1000592: `The proportion is square, and the appropriate size can clearly see the details.`,
  key1000593: `I got it!`,
  key1000594: `Enter can enter custom attributes`,
  key1000595: `Please select a specification`,
  key1000596: `Essential information`,
  key1000597: `Product category:`,
  key1000598: `Commodity number/SPU:`,
  key1000599: `Please enter the product number/SPU`,
  key1000600: `Product rotation chart:`,
  key1000601: `Type:`,
  key1000602: `Batch edit assembly information:`,
  key1000603: `Image type:`,
  key1000604: `Product title composition: Product features+product category, no need to add words such as year, eBay, Amazon, etc`,
  key1000605: `Commodity attributes`,
  key1000606: `Please enter attribute values`,
  key1000607: `Price Inventory`,
  key1000608: `Service and Commitment`,
  key1000609: `Commodity service:`,
  key1000610: `Delivery time:`,
  key1000611: `Product sales suggestions`,
  key1000612: `Viewing Examples`,
  key1000613: `Assembly information`,
  key1000614: `The main attribute image cannot be empty`,
  key1000615: `供货价格`,
  key1000616: `设置此商品在`,
  key1000617: `累计销售达到一定数量后进行优惠的规则，此优惠将直接给到分销商，但分销商无法直接查看设置的优惠规则，仅在分销商达到某一分层的数量后才能查看下一分层优惠，请放心设置。`,
  key1000618: `Submit`,
  key1000619: `Select product category`,
  key1000620: `Modify Category`,
  key1000621: `Accurately selecting categories is beneficial for product exposure and promotion of conversion.`,
  key1000622: `For the convenience of management, you can customize the product code, such as the product number, which will take effect after successful publication.`,
  key1000623: `Home image`,
  key1000624: `Upload network images`,
  key1000625: `Priority should be given to using background model puzzles for the first image. If there are no puzzles, choose the front image of the model. Models with backgrounds should be arranged in a certain order.`,
  key1000626: `View specifications`,
  key1000627: `Picture requirements`,
  key1000628: `建议上传800*800像素以上正方形实拍大图，比如：800*800px，1000*1000px，1500*1500px，最大不超过5MB，若不符合该尺寸则会导致上传失败。`,
  key1000629: `推荐JPG、JPEG格式图片，但平台支持PNG、WEBP、BMP格式图片上传（受限平台限制会将非JPG、JPEG格式都转化为JPEG格式，但会导致图片会有略微失真）`,
  key1000630: `Normal products`,
  key1000631: `Assembling products`,
  key1000632: `raw material`,
  key1000633: `edit`,
  key1000634: `Self portrait of a model`,
  key1000635: `Internet celebrity chart`,
  key1000636: `Picture of physical placement`,
  key1000637: `Automatic generation`,
  key1000638: `Listing product attribute information:`,
  key1000639: `Expand and fill in more attributes`,
  key1000640: `以下属性需填写属性值比例：`,
  key1000641: `Please select a product category first`,
  key1000642: `Please fill in the attributes accurately. More product attributes will help improve the conversion rate of orders.`,
  key1000643: `Product specifications`,
  key1000644: `Only the first set of specification setting images are supported. The set image should be selected as the set image, and a maximum of 5 images can be uploaded for each specification. The recommended size is 800 * 800px.`,
  key1000645: `Multi specification sorting`,
  key1000646: `Specification details`,
  key1000647: `Custom Columns`,
  key1000648: `Size chart`,
  key1000649: `Please add product specifications first`,
  key1000650: `Fixed amount`,
  key1000651: `discount`,
  key1000652: `Add Layering`,
  key1000653: `Not available temporarily after submission for review`,
  key1000654: `Approved`,
  key1000655: `Reject`,
  key1000656: `Product classification cannot be empty`,
  key1000657: `Item No./SPU cannot be empty`,
  key1000658: `The product rotation chart cannot be empty`,
  key1000659: `The image type cannot be empty`,
  key1000660: `Accumulated purchase quantity`,
  key1000661: `To`,
  key1000662: `Cannot be less than or equal to the left interval`,
  key1000663: `Discount amount`,
  key1000664: `Discount coefficient`,
  key1000665: `Please enter the discount amount, such as 1.00`,
  key1000666: `Please enter a discount factor, such as 0.1`,
  key1000667: `Layered Price Reference`,
  key1000668: `Original price:`,
  key1000669: `Discount price:`,
  key1000670: `infinite`,
  key1000671: `Remove hierarchy`,
  key1000672: `Product`,
  key1000673: `The attribute value has been deactivated. Please replace it with a similar attribute value`,
  key1000674: `Abnormal attribute value, please replace the attribute value`,
  key1000675: `You can only select up to 5 optional values for basic attributes!`,
  key1000676: `This property already exists. Please enter a different property name.`,
  key1000677: `Update successfully`,
  key1000678: `Option not filled in completely`,
  key1000679: `属性值比例不能为空`,
  key1000680: `属性值比例必须是大于0的正整数`,
  key1000681: `每个属性的属性值比例总和要等于100%`,
  key1000682: `规格属性存在已停用属性值，更换为相近的属性值后重试。`,
  key1000683: `规格属性属性值异常，请更换属性值后重试。`,
  key1000684: `基础属性的属性值异常，请更换属性值后重试。`,
  key1000685: `One size`,
  key1000686: `Enter after entering the value`,
  key1000687: `Automatic generation`,
  key1000688: `Automatically generate sku rules: item number/SPU + specification attribute`,
  key1000689: `Packaging size (length, width, height cm)`,
  key1000690: `The maximum length of each side of the packaging shall not exceed 40cm, except for swimwear. The maximum height of spring and summer styles shall not exceed 3cm, and the minimum height shall not be less than 1cm`,
  key1000691: `long`,
  key1000692: `Width`,
  key1000693: `Height`,
  key1000694: `Length/Width/Height`,
  key1000695: `Weight`,
  key1000696: `Weight(g)`,
  key1000697: `Fill in the maximum weight in yards and grams`,
  key1000698: `Supply price (CNY)`,
  key1000699: `Supply price（`,
  key1000700: `Suggested supply price is less than or equal to 1688 price`,
  key1000701: `inventory`,
  key1000702: `库存为整数`,
  key1000703: `Assembled or not`,
  key1000704: `(Attribute value has been deactivated)`,
  key1000705: `Preview image`,
  key1000706: `Activity price`,
  key1000707: `Activity price（`,
  key1000708: `Sales price configuration`,
  key1000709: `The percentage markup value cannot be empty`,
  key1000710: `Fixed markup value cannot be empty`,
  key1000711: `Only supports inputting numbers and decimal points`,
  key1000712: `The price reduction cannot be greater than or equal to the supply price`,
  key1000713: `The downward float cannot be greater than or equal to 100%`,
  key1000714: `Only allow up to four decimal places to be entered`,
  key1000715: `Upward floating`,
  key1000716: `Downward float`,
  key1000717: `Markup`,
  key1000718: `Price reduction`,
  key1000719: `Percentage markup`,
  key1000720: `Fixed markup`,
  key1000721: `Please enter the floating ratio`,
  key1000722: `Please enter numerical values`,
  key1000723: `Sales price`,
  key1000724: `Sales price（`,
  key1000725: `Product item number/SPU cannot be empty!`,
  key1000726: `Size`,
  key1000727: `Upload up to 5 images per specification`,
  key1000728: `Close prompt`,
  key1000729: `Content not saved reminder`,
  key1000730: `Unsaved content will be lost. Are you sure you want to exit?`,
  key1000731: `Confirm Exit`,
  key1000732: `图片尺寸至少要≥800且<=6000`,
  key1000733: `Title cannot be empty`,
  key1000734: `Billing Model Editing`,
  key1000735: `Support Chinese, English and Binary Code Search`,
  key1000736: `Volume (kg):`,
  key1000737: `Volume weight:`,
  key1000738: `(Volume (kg)=Length (cm) x Width (cm) x Height (cm) ÷`,
  key1000739: `验证运费是否正确：重量(`,
  key1000740: `Selected countries:`,
  key1000741: `Clear`,
  key1000742: `Set postal code`,
  key1000743: `View postal code`,
  key1000744: `Add weight range`,
  key1000745: `Verify delivery fee`,
  key1000746: `Delivery fee:`,
  key1000747: `Freight = (Weight * Unit Registration Fee Operating Fee)* (1 Surcharge)`,
  key1000748: `If Package weight<=Primary weight, Freight=minimum price*(1+additional price)`,
  key1000749: `If package weight is greater than the Primary weight, the shipping cost = {Primary weight cost + rounding up (total weight-Primary weight)/renewed weight per unit) * additional weight unit price + registration fee + operation fee}* (1+ surcharge)`,
  key1000750: `Freight= (Unit Registration Fee Operating Fee)* (1 Surcharge)`,
  key1000751: `Not within the weight range`,
  key1000752: `Starting weight`,
  key1000753: `Cut-off weight`,
  key1000754: `Registration fee`,
  key1000755: `Operation cost`,
  key1000756: `Surcharge (%)`,
  key1000757: `Price`,
  key1000758: `首重（`,
  key1000759: `First weight freight（`,
  key1000760: `续重单位重量（`,
  key1000761: `Renewal unit price（`,
  key1000762: `Cut-off weight should be greater than initial weight`,
  key1000763: `Please choose the country`,
  key1000764: `Price cannot be empty.`,
  key1000765: `The first priority should not be empty`,
  key1000766: `Priority freight cannot be empty`,
  key1000767: `Continuous weight per unit weight must not be empty`,
  key1000768: `Continuous unit price cannot be empty`,
  key1000769: `Volume weight cannot be empty`,
  key1000770: `Product search:`,
  key1000771: `Please enter the complete SPU/SKU or product name`,
  key1000772: `Subscription status:`,
  key1000773: `items`,
  key1000774: `Selected:`,
  key1000775: `Set as main material`,
  key1000776: `SPU,支持多个按行分隔`,
  key1000777: `SKU,支持多个按行分隔`,
  key1000778: `Central warehouse inventory`,
  key1000779: `Add product`,
  key1000780: `Supplier SPU`,
  key1000781: `Please enter the supplier SPU, support multiple separated by line`,
  key1000782: `collected`,
  key1000783: `Not Favorite`,
  key1000784: `Supplier Merchant Number`,
  key1000785: `Product title`,
  key1000786: `Please enter the product title`,
  key1000787: `Product information`,
  key1000788: `Supplier name`,
  key1000789: `Supplier inventory`,
  key1000790: `Product SPU:`,
  key1000791: `Product SKU:`,
  key1000792: `Available stock`,
  key1000793: `Sales in the past 30 days`,
  key1000794: `Choice`,
  key1000795: `My inventory`,
  key1000796: `在途库存：`,
  key1000797: `可用库存：`,
  key1000798: `Unit Price`,
  key1000799: `length, width and height(cm)`,
  key1000800: `trial sale`,
  key1000801: `Clearance`,
  key1000802: `The quantity of goods cannot be empty!`,
  key1000803: `Please select the product first!`,
  key1000804: `System notification`,
  key1000805: `No more reminders`,
  key1000806: `Main materials`,
  key1000807: `accessories`,
  key1000808: `Please enter the complete SPU or product name`,
  key1000809: `The quantity of auxiliary materials cannot be empty!`,
  key1000810: `The quantity of main materials cannot be empty!`,
  key1000811: `Please select the main material data first!`,
  key1000812: `You do not have permission to access this page.`,
  key1000813: `Back`,
  key1000814: `Sorry, the page you visited does not exist!`,
  key1000815: `Return to the system homepage`,
  key1000816: `Return to the menu with permissions`,
  key1000817: `Please check the custom list fields that need to be displayed first!`,
  key1000818: `Medium material `,
  key1000819: `Tag size`,
  key1000820: `Start and end information`,
  key1000821: `Maximum support 100mm`,
  key1000822: `Typeface`,
  key1000823: `About 35mm Width/Pt `,
  key1000824: `Note: The printed content can be dragged to change the position.`,
  key1000825: `Printing`,
  key1000826: `Not print`,
  key1000827: `Bar code`,
  key1000828: `custom content`,
  key1000829: `Storage bin`,
  key1000830: `Preview and occupy storage bin`,
  key1000831: `Storage area`,
  key1000832: `Preview and occupy storage area`,
  key1000833: `Reference Number `,
  key1000834: `preview and occupy reference number`,
  key1000835: `Adjust specification sorting by dragging and dropping`,
  key1000836: `Print box labels`,
  key1000837: `Creation time:`,
  key1000838: `Print quantity:`,
  key1000839: `打印YMS面单`,
  key1000840: `The number of prints must be greater than 0`,
  key1000841: `No options available`,
  key1000842: `Classification retrieval`,
  key1000843: `Recently used:`,
  key1000844: `Selected category:`,
  key1000845: `Switch category confirmation`,
  key1000846: `After switching categories, information such as product attributes and specifications needs to be filled out again. Please confirm whether to switch categories?`,
  key1000847: `Confirm switch`,
  key1000848: `Please select a type`,
  key1000849: `Please enter your phone number`,
  key1000850: `Please enter the graphic verification code`,
  key1000851: `Captcha image`,
  key1000852: `please enter verification code`,
  key1000853: `Input Password`,
  key1000854: `Please confirm the password again`,
  key1000855: `Email (optional)`,
  key1000856: `Improve account information`,
  key1000857: `Kind reminder: For the security of your account, please bind your phone number. After binding, you can log in through your phone number and password.`,
  key1000858: `Bind mobile phone number`,
  key1000859: `Temporarily not bound`,
  key1000860: `Send the verification code`,
  key1000861: `The graphic verification code cannot be empty`,
  key1000862: `verification code must be filled`,
  key1000863: `Resend`,
  key1000864: `The phone verification code has been sent!`,
  key1000865: `Confirm that the new password cannot be empty`,
  key1000866: `The password does not match the confirmed password`,
  key1000867: `Successfully bound phone`,
  key1000868: `Please check the read and accept`,
  key1000869: `Edit Template`,
  key1000870: `Template name:`,
  key1000871: `Edit successfully!`,
  key1000872: `Supports batch input, please separate with line breaks`,
  key1000873: `Before truncating the postal code`,
  key1000874: `position`,
  key1000875: `Before postal code`,
  key1000876: `Position:`,
  key1000877: `Postcode:`,
  key1000878: `Postcode cannot be empty.`,
  key1000879: `The postal code format can only be numbers or letters`,
  key1000880: `Order cancellation prompt`,
  key1000881: `There are cancelled orders in this assignment, and the detailed information of the outbound order included in the order is as follows.`,
  key1000882: `Continue to sort`,
  key1000883: `Remove cancelled outbound orders`,
  key1000884: `Outbound order number `,
  key1000885: `Print Configuration`,
  key1000886: `Regular order waybill`,
  key1000887: `Lemgth:`,
  key1000888: `Width:`,
  key1000889: `AliExpress fully managed product label`,
  key1000890: `AliExpress fully managed logistics waybill`,
  key1000891: `The length and width of the regular order waybill cannot be empty!`,
  key1000892: `The length and width of the AliExpress fully managed product label cannot be empty!`,
  key1000893: `The length and width of the AliExpress fully managed logistics waybill cannot be empty!`,
  key1000894: `Saved successfully！`,
  key1000895: `商品驳回通知`,
  key1000896: `以下商品审核被驳回，请修改后重新提交审核:`,
  key1000897: `下次提醒`,
  key1000898: `Copied!`,
  key1000899: `Choose the platform to publish`,
  key1000900: `This category has not yet maintained platform mapping. Please consult customer service if necessary.`,
  key1000901: `There are currently no platforms to support publishing!`,
  key1000902: `Unbound store`,
  key1000903: `Your account is currently not linked to a store, or the linked store has expired.`,
  key1000904: `Go to Bind`,
  key1000905: `Adding pictures from addresses`,
  key1000906: `Image URL:`,
  key1000907: `请填写图片地址`,
  key1000908: `Incorrect link address format`,
  key1000909: `获取上传图片失败！`,
  key1000910: `New product`,
  key1000911: `Authorized list`,
  key1000912: `You can enter code and account name to search`,
  key1000913: `Code`,
  key1000914: `Account`,
  key1000915: `Effective`,
  key1000916: `invalid`,
  key1000917: `Update shop`,
  key1000918: `Add shop`,
  key1000919: `key words`,
  key1000920: `Rule`,
  key1000921: `You can enter multiple postcodes, separated by a comma or newline`,
  key1000922: `Enter more than one, separated by English commas`,
  key1000923: `Please enter key words...`,
  key1000924: `Multi-postcode wrap requests half-width input comma to separate`,
  key1000925: `Please choose platform`,
  key1000926: `Please enter the shipping method to search.`,
  key1000927: `Add transpotation type`,
  key1000928: `Are you sure to delete?`,
  key1000929: `(USD)`,
  key1000930: `or (EUR)`,
  key1000931: `or (GBP)`,
  key1000932: `or (AUD)`,
  key1000933: `Or RMB`,
  key1000934: `Please enter the SKU. Use comma or new line to separate multiple SKUs`,
  key1000935: `Please Choose or Input Location`,
  key1000936: `Please select the shop or enter the search.`,
  key1000937: `Please select a logistics`,
  key1000938: `Ten-day orders are X-day orders, such as 5-days, 10-days`,
  key1000939: `Specified scope`,
  key1000940: `Designated type`,
  key1000941: `Start`,
  key1000942: `Ending`,
  key1000943: `Specified law`,
  key1000944: `designated SKU`,
  key1000945: `Designated warehouse`,
  key1000946: `or`,
  key1000947: `Designated platform`,
  key1000948: `Selected conditions`,
  key1000949: `Items included in the order:`,
  key1000950: `volumetric factor`,
  key1000951: `The total quantity of products in the package meets the following selected conditions:`,
  key1000952: `The weight meets all the following conditions:`,
  key1000953: `The length of the product should meet all the following conditions:`,
  key1000954: `The total width meets all the following conditions:`,
  key1000955: `The height of products should satisfy all the following conditions:`,
  key1000956: `The product volume of the order meets all the following conditions`,
  key1000957: `The total quantity of products in the order meets the following conditions:`,
  key1000958: `The character length of the zip code should meet the following conditions:`,
  key1000959: `Products in the order:`,
  key1000960: `and the sum of the quantity of the above goods meets all the conditions selected below:`,
  key1000961: `The heavier one of volumetric weight and actual weight will be the final weight of order.`,
  key1000962: `Note: The longest side of each item recorded in the commodity management is a1. The middle side is b1, and the shortest is c,`,
  key1000963: `The longest side of each item recorded in the commodity management is a1. The middle side is b1, and the shortest is c. The maximum value of all a1 is a, and the maximum value of all b1 is b. The sum of the short sides c of all items in the order is d,`,
  key1000964: `then the maximum value of a, b, and d is the total length, the middle value is the total width, and the minimum value is the total height.`,
  key1000965: `volumetric weight(g)= (a*b*d)/ volumetric factor*1000`,
  key1000966: `Note: The longest side of each item recorded in the commodity management is a1. The middle side is b1, and the shortest is c,`,
  key1000967: `The longest side of each item recorded in the commodity management is a1. The middle side is b1, and the shortest is c. The maximum value of all a1 is a, and the maximum value of all b1 is b. The sum of the short sides c of all items in the order is d,`,
  key1000968: `then the maximum value of a, b, and d is the total length, the middle value is the total width, and the minimum value is the total height.`,
  key1000969: `then the maximum value of a, b, and d is the total length, the middle value is the total width, and the minimum value is the total`,
  key1000970: `height, a*b*d is the total volume of all the products in the order.`,
  key1000971: `Order includes multiple transactions(and use the same shipping method)`,
  key1000972: `The order only has one transaction.`,
  key1000973: `The order contains multiple transactions (and the shipping methods are not the exact same`,
  key1000974: `specified conditions`,
  key1000975: `The contact number of recipient should meet the following conditions:`,
  key1000976: `readable character length of mobile number≤`,
  key1000977: `Readable character length of fixed phone number≤`,
  key1000978: `Mobile phone prefix`,
  key1000979: `designated string`,
  key1000980: `appoint the character to be searched in the address(only Street1 + Street2, not include the information of the country, province/state and city):`,
  key1000981: `Add`,
  key1000982: `Designated length`,
  key1000983: `The character length of the shipping address is less than`,
  key1000984: `*。The address information is combined by street1 and stree2, which does not include the information of the country, province/state and city.`,
  key1000985: `, and`,
  key1000986: `, or`,
  key1000987: `includes`,
  key1000988: `specified format`,
  key1000989: `The postcode should meet at least one of the following selected conditions (input content is limited to numbers, English letters, dashes and spaces):`,
  key1000990: `Designated exception`,
  key1000991: `and the postcode is in`,
  key1000992: `Postcode section`,
  key1000993: `Postcode list`,
  key1000994: `Have chosen`,
  key1000995: `,Note: For other currencies to be converted into USD, all options must be filled in`,
  key1000996: `Freight paid by customer convert to (currency):`,
  key1000997: `：then meet all the following conditions:`,
  key1000998: `Specified product status`,
  key1000999: `And for`,
  key1001000: `Designated products`,
  key1001001: `Designated mode of mailing`,
  key1001002: `The total amount of the package includes the amount of all orders merged into one package. The amount of orders in different currencies will be converted to the currencies in the following settings according to the exchange rate.`,
  key1001003: `Currency:`,
  key1001004: `Enter the characters to be searched in Customer ID`,
  key1001005: `Rule Name:`,
  key1001006: `Optional conditions`,
  key1001007: `Please designate`,
  key1001008: `No rule selected`,
  key1001009: `Rule name cannot be empty`,
  key1001010: `More Platforms`,
  key1001011: `Import type：`,
  key1001012: `Please enter the task number`,
  key1001013: `Import time：`,
  key1001014: `Importing`,
  key1001015: `complete import`,
  key1001016: `Failed to import`,
  key1001017: `import type`,
  key1001018: `import files`,
  key1001019: `time of import`,
  key1001020: `import status`,
  key1001021: `Success/Failure`,
  key1001022: `export successfully`,
  key1001023: `failed to export`,
  key1001024: `Tracking information`,
  key1001025: `Waybill number:`,
  key1001026: `Status:`,
  key1001027: `To be queried`,
  key1001028: `Querying`,
  key1001029: `Not found`,
  key1001030: `In transit`,
  key1001031: `Waiting to receive`,
  key1001032: `Have been received`,
  key1001033: `Delivery failed`,
  key1001034: `Transported too long`,
  key1001035: `May be abnormal`,
  key1001036: `Apply to become a distributor`,
  key1001037: `Find products`,
  key1001038: `Collect products`,
  key1001039: `Product Listing & Download Kit`,
  key1001040: `Place an order to ship`,
  key1001041: `Order Management`,
  key1001042: `financial management`,
  key1001043: `Register now`,
  key1001044: `Click "Become a Distributor" in the upper right corner of the mall homepage, and use your mobile phone number to register or use your Tongtool account to authorize to log in.`,
  key1001045: `Browse`,
  key1001046: `Search keywords to find the products you want to distribute.`,
  key1001047: `In the category navigation, find the product you want to distribute.`,
  key1001048: `On the homepage and core page, find the product you want to distribute.`,
  key1001049: `Go collect`,
  key1001050: `On the product list page, find the product and click the "Save" button to save it for publication.`,
  key1001051: `On the product details page, click the "Save Now" button to save the product for publication.`,
  key1001052: `4. Product Publishing&Download Kit`,
  key1001053: `Product publish`,
  key1001054: `Use Tongtool Listing to publish products on eBay, Aliexpress, Wish, Amazon and other overseas e-commerce platforms with one click.`,
  key1001055: `Download the package`,
  key1001056: `You can also download the data package for free on the "Product List", "Product Details Page" or "Product Management" page, and then go to the third-party sales platform to list the products in the backstage of the merchant.`,
  key1001057: `Basic Settings`,
  key1001058: `Enable logistics`,
  key1001059: `In Tongtool ERP2.0, third-party logistics is enabled, and the Tongtool system has built-in some logistics providers with a large number of users in the industry and a good reputation. You can select and enable it according to your needs.`,
  key1001060: `Enable shipping method`,
  key1001061: `Choose which shipping method you want to enable. After enabling it, you will be prompted "The shipping method activated successfully".`,
  key1001062: `Warehouse setup`,
  key1001063: `Select "YMS Supply Chain" in the third-party warehouse to enable it.`,
  key1001064: `Bind logistics channels`,
  key1001065: `Select the "YMS supply chain" warehouse, click "Bind logistics channel" on the right, and click the "Confirm" button after selecting the logistics method.`,
  key1001066: `Generate Tongtool sku`,
  key1001067: `In the "Product Management - Cloud Sales and Distribution Products" module of Tongtu ERP2.0, click on the favorite products, and in the product details, click "Generate Product Information".`,
  key1001068: `In Tongtool ERP2.0, select the "Shipping Management-Managed Warehouse-Waiting for allocation" module, and the warehouse selects "YMS Supply Chain", click "YMS Order". If the balance is insufficient, you can contact customer service to recharge.`,
  key1001069: `Go to view`,
  key1001070: `In the "Order Management-My Order" module, you can query the order and enter the "Order Details Page" to check the delivery status.`,
  key1001071: `In the "Financial Management" module, you can query the recharge records and view the cost details.`,
  key1001072: `Shipping method:`,
  key1001073: `Shipping mode:`,
  key1001074: `Logistics solution:`,
  key1001075: `Shipping address:`,
  key1001076: `Carrier:`,
  key1001077: `Logistics tracking number:`,
  key1001078: `YMS Logistics`,
  key1001079: `Own logistics`,
  key1001080: `express`,
  key1001081: `YMS on-site collection`,
  key1001082: `Please`,
  key1001083: `Account Management - Basic Information`,
  key1001084: `Maintaining shipping address information in`,
  key1001085: `Please make sure to confirm with YMS offline in advance whether on-site collection is possible!!`,
  key1001086: `When shipping, the goods need to be labeled with YMS product barcodes, and the outer packaging needs to be labeled with box labels in a prominent place!`,
  key1001087: `End boxing`,
  key1001088: `Successfully marked shipment, bill of lading number:`,
  key1001089: `Please select a logistics plan`,
  key1001090: `Please select a shipping address`,
  key1001091: `Please select Logistics Provider`,
  key1001092: `The logistics tracking number cannot be empty`,
  key1001093: `Logistics information`,
  key1001094: `Mark Shipment`,
  key1001095: `Modify logistics information`,
  key1001096: `China`,
  key1001097: `Your browser does not support websocket!`,
  key1001098: `Minutes ago`,
  key1001099: `Hours ago`,
  key1001100: `Days ago`,
  key1001101: `Weeks ago`,
  key1001102: `Months ago`,
  key1001103: `last year`,
  key1001104: `Sunday`,
  key1001105: `Monday`,
  key1001106: `Tuesday`,
  key1001107: `Wednesday`,
  key1001108: `Thursday`,
  key1001109: `Friday`,
  key1001110: `Saturday`,
  key1001111: `day`,
  key1001112: `hour`,
  key1001113: `minute`,
  key1001114: `1 minute`,
  key1001115: `just`,
  key1001116: `Supplier Operations`,
  key1001117: `Please download the tongtool print plugin`,
  key1001118: `Click to download`,
  key1001119: `Run the tongtool print plugin`,
  key1001120: `Current browser Not support websocket`,
  key1001121: `Failed to connect rookie print control,Please confirm that the rookie print control is installed or enabled`,
  key1001122: `Loading...`,
  key1001123: `Request timed out`,
  key1001124: `账户已被停用，若需重新启用账户，请联系`,
  key1001125: `运营人员。`,
  key1001126: `您已有`,
  key1001127: `账号，可直接使用此`,
  key1001128: `账号作为供应商进行入驻。`,
  key1001129: `账号开通分销商账户。`,
  key1001130: `No access to system`,
  key1001131: `Dear Passage User, you do not have permission to access the system!`,
  key1001132: `Please contact the system administrator!`,
  key1001133: `Reminder for pending confirmation of accounting statement`,
  key1001134: `There are unconfirmed bills, please confirm in a timely manner. If the bill is not confirmed within 14 days after generation, the system will default to the bill being correct and automatically confirm the bill.`,
  key1001135: `View bills`,
  key1001136: `Confirm later`,
  key1001137: `Account deactivation prompt`,
  key1001138: `Confirmation of becoming a supplier`,
  key1001139: `Go to settle in`,
  key1001140: `Confirmation of becoming a distributor`,
  key1001141: `Confirm activation`,
  key1001142: `You already have a Tongtu account, you can directly use this account to open a distributor account.`,
  key1001143: `Uncollected`,
  key1001144: `Collected`,
  key1001145: `Sorted`,
  key1001146: `Outbound`,
  key1001147: `提单，`,
  key1001148: `【提单号】`,
  key1001149: `，存在货品多发，已转为备货库存存放于`,
  key1001150: `中心仓。`,
  key1001151: `订单自动取消通知`,
  key1001152: `您的订单`,
  key1001153: `订单号】`,
  key1001154: `，已超时自动取消，请及时关注。`,
  key1001155: `Direct outbound`,
  key1001156: `To be picked`,
  key1001157: `Picking`,
  key1001158: `Wait for pick`,
  key1001159: `Sorting`,
  key1001160: `To be packed`,
  key1001161: `In packaging`,
  key1001162: `Package complete`,
  key1001163: `Cancelled`,
  key1001164: `complete creation`,
  key1001165: `In stock`,
  key1001166: `Picking completed`,
  key1001167: `Order has been changed`,
  key1001168: `Boxed`,
  key1001169: `Delivered`,
  key1001170: `Waiting for collection`,
  key1001171: `It has been added to`,
  key1001172: `Already enter the warehouse`,
  key1001173: `closed`,
  key1001174: `Abnormal collection and sorting`,
  key1001175: `Already placed`,
  key1001176: `Single choices can be customized`,
  key1001177: `Multiple choice can be customized value`,
  key1001178: `Matched products`,
  key1001179: `Matched logistics`,
  key1001180: `Order placed`,
  key1001181: `Checking`,
  key1001182: `To be delivered`,
  key1001183: `Partial fullfill`,
  key1001184: `Partial receipt`,
  key1001185: `Partial cancellation`,
  key1001186: `Sales management`,
  key1001187: `Own warehouse stocking`,
  key1001188: `Third-party warehouse stocking`,
  key1001189: `Multiple supplementary orders`,
  key1001190: `Editing`,
  key1001191: `Pending review`,
  key1001192: `Review rejection`,
  key1001193: `Product price`,
  key1001194: `Parcel handling fee`,
  key1001195: `Refund`,
  key1001196: `Logistics handling fee`,
  key1001197: `Subsidy amount`,
  key1001198: `Domestic express delivery fee`,
  key1001199: `Customs clearance fee`,
  key1001200: `订单取消处理费`,
  key1001201: `Normal`,
  key1001202: `Deduction completes`,
  key1001203: `Withheld amount`,
  key1001204: `Mall Registration`,
  key1001205: `Manual addition`,
  key1001206: `To be confirmed`,
  key1001207: `To be received`,
  key1001208: `Received payments`,
  key1001209: `To be confirmed by the supplier`,
  key1001210: `Pending payment`,
  key1001211: `Paid`,
  key1001212: `/yms-distributor-service/video/YMS功能介绍.mp4`,
  key1001213: `/Yms distributor service/video/store authorization. mp4`,
  key1001214: `/yms-distributor-service/video/刊登模板设置.mp4`,
  key1001215: `/yms-distributor-service/video/一键刊登（YMS).mp4`,
  key1001216: `/yms-distributor-service/video/一键刊登规则.mp4`,
  key1001217: `/Yms distributor service/video/Register for Cloud Selling Supply Chain.mp4`,
  key1001218: `Chinese`,
  key1001219: `English`,
  key1001220: `Order out of stock reminder`,
  key1001221: `When the order status is marked as out of stock`,
  key1001222: `Order No.`,
  key1001223: `You have<span class="highlight_color">[order number]</span> orders out of stock, please pay attention in time!`,
  key1001224: `Stock up`,
  key1001225: `Order stocking reminder`,
  key1001226: `You have <span class="highlight_color">[X]</span> orders. The stocking has been overtime, please stock up in time!`,
  key1001227: `Picking`,
  key1001228: `Order picking reminder`,
  key1001229: `You have <span class="highlight_color">[X]</span> orders, and the picking has timed out, please pick it in time!`,
  key1001230: `Switch bill`,
  key1001231: `Order exchange reminder`,
  key1001232: `You have <span class="highlight_color">[X]</span> orders, which switching bill has timed out. Please switch bill in time!`,
  key1001233: `Packing`,
  key1001234: `Order packing reminder`,
  key1001235: `You have <span class="highlight_color">[X]</span> orders, the boxing has expired, please box in time!`,
  key1001236: `Fullfill`,
  key1001237: `Order Delivery Reminder`,
  key1001238: `You have <span class="highlight_color">[X]</span> orders, and the shipping has timed out, please ship it in time!`,
  key1001239: `Order cancellation reminder`,
  key1001240: `When the order status is marked as cancelled`,
  key1001241: `The distributor has cancelled the order: <span class="highlight_color">[order number]</span>, please pay attention to the delivery situation in time`,
  key1001242: `Notice of Product Suspension`,
  key1001243: `When the supplier marks SPU or SKU as discontinued`,
  key1001244: `Commodity name`,
  key1001245: `Dear distributor, your collection:<span class="highlight_color">【商品名称】</span>, Product Code:<span class="highlight_color">【SPU/SKU】</span>has been discontinued. If you have already listed this product, please remove it promptly.`,
  key1001246: `When the product description or image is modified`,
  key1001247: `Commodity information modification notice`,
  key1001248: `商品<span class="highlight_color">【SPU】</span>信息修改通知`,
  key1001249: `Dear distributor, hello: The description/image of your favorite product<span class="highlight_color">【SPU】</span>has been modified. If you have already listed this product, please update it to the latest product information in a timely manner.`,
  key1001250: `When the product price is modified`,
  key1001251: `Product Price Adjustment Notice`,
  key1001252: `商品<span class="highlight_color">【SPU】</span>调价通知`,
  key1001253: `Dear distributor, due to cost changes, <span class="highlight_color">【SPU】</span> the prices of the products you have collected have been adjusted. Please keep an eye on them in a timely manner.`,
  key1001254: `When new products from suppliers are reviewed and approved`,
  key1001255: `Product New Notification`,
  key1001256: `The supplier you are following has launched new products`,
  key1001257: `The supplier you are following:<span class="highlight_color">【供应商名称】</span>There are new products available.`,
  key1001258: `When the inbound sorting is completed and there are over received items in the bill of lading`,
  key1001259: `Notification of multiple shipments of goods`,
  key1001260: `Bill of Lading Number`,
  key1001261: `When adjusting inventory or shelving tool status through automatic rules configured by distributors`,
  key1001262: `Product status automatic synchronization notification`,
  key1001263: `The product you subscribed to<span class="highlight_color">【SPU】</span>has been discontinued and relevant information has been automatically adjusted according to rules. Please stay tuned.
`,
  key1001264: `When adjusting online publication prices through automatic rules configured by distributors`,
  key1001265: `Online publication of automatic price adjustment notifications`,
  key1001266: `The product you subscribed<span class="highlight_color">【SPU】</span>. The supply price has been adjusted, and the online publication price is automatically adjusted according to the rules. Please stay tuned.`,
  key1001267: `When the order is automatically cancelled through the timeout configured by the distributor`,
  key1001268: `When automatic order placement through the platform fails due to insufficient balance`,
  key1001269: `Notification of insufficient balance and failed order placement`,
  key1001270: `Notice of insufficient balance`,
  key1001271: `Platform`,
  key1001272: `<span class="highlight_color">【平台】</span>订单<span class="highlight_color">【订单号】</span> Your balance is insufficient and automatic order placement has failed. Please recharge in a timely manner.`,
  key1001273: `商品审核被驳回时`,
  key1001274: `商品审核驳回通知`,
  key1001275: `您的商品：<span class="highlight_color">【SPU】</span>，审核未通过，驳回原因：XXXXXXXXX。`,
  key1001276: `To be boxed`,
  key1001277: `Out of stock`,
  key1001278: `Regular order`,
  key1001279: `Temu Sample Order`,
  key1001280: `Temu goods order`,
  key1001281: `AliExpress fully managed orders`,
  key1001282: `Use existing IOSS`,
  key1001283: `Logistics agent payment`,
  key1001284: `Manual entry`,
  key1001285: `等待在途库存`,
  key1001286: `Waiting for merchants to pick up goods`,
  key1001287: `Pending merchant review`,
  key1001288: `To be packed by merchants`,
  key1001289: `In express transportation`,
  key1001290: `Arriving at the central warehouse`,
  key1001291: `The central warehouse has arrived`,
  key1001292: `Waiting for logistics provider to come and pick up the package`,
  key1001293: `Delivered to logistics provider`,
  key1001294: `English US name`,
  key1001295: `English UK name`,
  key1001296: `English - AU name`,
  key1001297: `English - EU name`,
  key1001298: `Visible after login`,
  key1001299: `Original recipient name`,
  key1001300: `Original recipient email`,
  key1001301: `Original recipient phone number`,
  key1001302: `Original recipient user`,
  key1001303: `Original receiving country code`,
  key1001304: `Original recipient's mobile phone`,
  key1001305: `Original shipping address 1`,
  key1001306: `Original shipping address 2`,
  key1001307: `Original recipient city`,
  key1001308: `Original recipient state/province`,
  key1001309: `Original recipient's postal code`,
  key1001310: `Original recipient's passport code`,
  key1001311: `订单[`,
  key1001312: `]Successfully modified the address:`,
  key1001313: `Payment, actual amount paid:`,
  key1001314: `Distributor has made payment, actual amount paid:`,
  key1001315: `Price adjustment; Total original price of goods:`,
  key1001316: `; Distributor's payable amount:`,
  key1001317: `; Supplier's receivable amount:`,
  key1001318: `Arrived at warehouse`,
  key1001319: `标记为非`,
  key1001320: `订单,清空商品&物流信息`,
  key1001321: `Automatic matching of order items or logistics failed`,
  key1001322: `Original order cancellation`,
  key1001323: `No logistics matched`,
  key1001324: `Matching basic logistics failed`,
  key1001325: `Cancel the order successfully`,
  key1001326: `自动匹配物流成功：`,
  key1001327: `Order`,
  key1001328: `Successfully matched logistics:`,
  key1001329: `]Match logistics special rules:[`,
  key1001330: `]Matching scope:`,
  key1001331: `Matching scheme rules:`,
  key1001332: `Successfully matched product manually: Platform SKU:`,
  key1001333: `，匹配`,
  key1001334: `Failed to manually match product: Platform SKU:`,
  key1001335: `，未匹配`,
  key1001336: `Successfully matched product: Platform SKU:`,
  key1001337: `Automatic matching of products failed: Platform SKU:`,
  key1001338: `SKU失败，SKU已停售`,
  key1001339: `Failed to place order, distributor balance is insufficient`,
  key1001340: `Change logistics information, original logistics method:`,
  key1001341: `Original waybill number:`,
  key1001342: `Original transaction number:`,
  key1001343: `Shipment failed due to:`,
  key1001344: `Successfully shipped with tracking number:`,
  key1001345: `取消订单失败，原因：`,
  key1001346: `Failed to place the order`,
  key1001347: `Successfully ordered`,
  key1001348: `Automatic matching of logistics failed, no eligible logistics methods were found`,
  key1001349: `(No logistics that meet the basic conditions were found)`,
  key1001350: `Failed to modify address information`,
  key1001351: `]Successfully modified logistics:`,
  key1001352: `Original logistics`,
  key1001353: `Change to`,
  key1001354: `]Failed to modify logistics information`,
  key1001355: `添加授权`,
  key1001356: `更新授权`,
  key1001357: `Authorization expired`,
  key1001358: `Manual labeling of platform orders indicates successful shipment`,
  key1001359: `Edit IOSS; IOSS Name:`,
  key1001360: `; IOSS number:`,
  key1001361: `Add IOSS; IOSS Name:`,
  key1001362: `Add rules; Rule Name:`,
  key1001363: `; IOSS used:`,
  key1001364: `; Applicable platforms:`,
  key1001365: `; Applicable stores:`,
  key1001366: `Editing rules; Rule Name:`,
  key1001367: `取消交易，取消金额：`,
  key1001368: `部分取消，取消金额：`,
  key1001369: `Create a stock order`,
  key1001370: `Confirm order acceptance and accept price adjustment`,
  key1001371: `Refuse price adjustment`,
  key1001372: `未到货部分已退款，退款金额`,
  key1001373: `，币种：`,
  key1001374: `All sub orders have arrived at the warehouse and have been completed`,
  key1001375: `Place an order`,
  key1001376: `Goods out of stock, restocking in progress:; Estimated delivery time:`,
  key1001377: `; The out of stock content is as follows:;`,
  key1001378: `The goods are out of stock and have been discontinued:; The out of stock content is as follows:;`,
  key1001379: `Please provide postal code information and try again`,
  key1001380: `Add attribute`,
  key1001381: `Enable Properties`,
  key1001382: `Deactivate Properties`,
  key1001383: `Delivery successful`,
  key1001384: `Successfully issued logistics`,
  key1001385: `Change logistics information; Original logistics method:`,
  key1001386: `; Original waybill number:`,
  key1001387: `; Original item flow tracking number:`,
  key1001388: `Payment successful`,
  key1001389: `Void order successfully`,
  key1001390: `Generate bills`,
  key1001391: `Update warehouse bills`,
  key1001392: `Generate customer bills`,
  key1001393: `Logistics sorting completed`,
  key1001394: `New rate:`,
  key1001395: `Edit rate:`,
  key1001396: `Generate a supplier invoice`,
  key1001397: `Generate a distributor invoice`,
  key1001398: `Failed to change order and returned to the generated picking list`,
  key1001399: `From picking list【`,
  key1001400: `】Remove`,
  key1001401: `Reset sorting`,
  key1001402: `Mark Picked`,
  key1001403: `Picking list has been generated, picking list number:【`,
  key1001404: `Failed to change order and returned to the picking list`,
  key1001405: `The bill of lading has been collected`,
  key1001406: `Bill of lading created`,
  key1001407: `The bill of lading has been stored in the warehouse`,
  key1001408: `The bill of lading has been shelved`,
  key1001409: `Bill of lading sorted`,
  key1001410: `There are excess packages, please contact the supplier`,
  key1001411: `Reason for failure to pass the review:`,
  key1001412: `Approved by review`,
  key1001413: `Change to`,
  key1001414: `Review failed:`,
  key1001415: `Failed by review`,
  key1001416: `Failed to pass the audit`,
  key1001417: `Adjusting subsidies`,
  key1001418: `by`,
  key1001419: `adjust to`,
  key1001420: `; Adjustment instructions:`,
  key1001421: `Manually adding suppliers for operations`,
  key1001422: `Deactivate supplier`,
  key1001423: `Enable supplier`,
  key1001424: `Edit Supplier`,
  key1001425: `Registering suppliers in the mall`,
  key1001426: `Entering the mall to improve supplier and enterprise information`,
  key1001427: `Review rejection, reason for rejection:`,
  key1001428: `New configuration added`,
  key1001429: `Edit Configuration`,
  key1001430: `Allocated inventory`,
  key1001431: `Matching intelligent logistics rules:`,
  key1001432: `Create Order`,
  key1001433: `Outbound order No.:`,
  key1001434: `Successfully matched the warehouse automatically; Shipping warehouse:`,
  key1001435: `Canceled order`,
  key1001436: `取消订单，取消原因：`,
  key1001437: `物流[`,
  key1001438: `]Change to[`,
  key1001439: `; Special rules:`,
  key1001440: `Applicable scope`,
  key1001441: `Freight/Time Priority`,
  key1001442: `Abnormal package status obtained before uploading package`,
  key1001443: `Application for waybill number failed`,
  key1001444: `Failed to obtain shipping number`,
  key1001445: `Exception in obtaining AliExpress product labels`,
  key1001446: `Successfully applied for waybill number`,
  key1001447: `(Waybill number:`,
  key1001448: `(Reason for failure:`,
  key1001449: `Edits re uploading package exception`,
  key1001450: `Manual modification: Update warehouse bill 【 Document cost 】`,
  key1001451: `Manual modification: Update warehouse bill [other expenses]`,
  key1001452: `Manual modification: adding other expenses【`,
  key1001453: `Add notes`,
  key1001454: `Manual modification`,
  key1001455: `Currency modification`,
  key1001456: `Import billing updates`,
  key1001457: `Confirm settlement`,
  key1001458: `人工标记标记允许取消订单`,
  key1001459: `由于地址修改，当前物流无法送达，原订单已取消，请重新匹配物流后进行下单。`,
  key1001460: `Receiving`,
  key1001461: `由于地址修改，原订单已取消。`,
  key1001462: `作废提单，原因：`,
  key1001463: `手动标记付款成功`,
  key1001464: `Payment successful`,
  key1001465: `买家修改收货地址，更新订单收货地址`,
  key1001466: `缴费金额：`,
  key1001467: `，时长：`,
  key1001468: `个月`,
  key1001469: `备货单首次分配`,
  key1001470: `商户工商信息变更，驳回原因：`,
  key1001471: `供应商修改了:`,
  key1001472: `商户工商信息变更，审核通过`,
  key1001473: `订单新增标签：`,
  key1001474: `订单删除标签：`,
  key1001475: `存在备货单尚未到货，等待在途库存。`,
  key1001476: `订单新增备注：`,
  key1001477: `[Lowest shipping cost]`,
  key1001478: `[Fastest time]`,
  key1001479: `Unable to stock up to`,
  key1001480: `warehouse`,
  key1001481: `当前最多可下单`,
  key1001482: `individual`,
  key1001483: `There are duplicates, please modify them`,
  key1001484: `Size value language:`,
  key1001485: `Cannot be empty`,
  key1001486: `Delivery note:`,
  key1001487: `Not waiting for picking status, please remove it before completing the addition`,
  key1001488: `AliExpress appointment delivery failed`,
  key1001489: `The quantity exceeds the limit, please check the quantity`,
  key1001490: `Current container`,
  key1001491: `Being occupied`,
  key1001492: `Already exists`,
  key1001493: `运营下发物流异常：不同仓库`,
  key1001494: `订单不可同时发货`,
  key1001495: `Logistics exception during operation: store address mismatch`,
  key1001496: `Booked, Collection Number:`,
  key1001497: `Supplier system outbound exception, bill of lading number:`,
  key1001498: `Handover order number:`,
  key1001499: `Appointment delivery exception`,
  key1001500: `The goods are not required for this order. Please confirm the order to which the goods belong`,
  key1001501: `Bill of Lading:`,
  key1001502: `Not present, please enter the correct bill of lading number`,
  key1001503: `存在已下发的云卖订单`,
  key1001504: `Enable status:`,
  key1001505: `Please enter the rule name`,
  key1001506: `Add Rules`,
  key1001507: `priority`,
  key1001508: `Place to the bottom`,
  key1001509: `Place to the top`,
  key1001510: `Move`,
  key1001511: `Rule name`,
  key1001512: `Enable Status`,
  key1001513: `Founder`,
  key1001514: `Creation time`,
  key1001515: `Last update date`,
  key1001516: `See`,
  key1001517: `Modification success`,
  key1001518: `Delete prompt`,
  key1001519: `After deleting the rule, it will not be recoverable. Do you want to delete it?`,
  key1001520: `Warehouse cannot be empty`,
  key1001521: `Action type:`,
  key1001522: `Warehouse:`,
  key1001523: `指定履约方`,
  key1001524: `Set action`,
  key1001525: `Assign shipping warehouse`,
  key1001526: `When the assigned warehouse is out of stock, it supports continuing to match other allocation warehouse rules`,
  key1001527: `Disable`,
  key1001528: `阿里国际`,
  key1001529: `View the rules`,
  key1001530: `Edit rules`,
  key1001531: `Copy Rules`,
  key1001532: `Please select a rule item first!`,
  key1001533: `Copy successfully`,
  key1001534: `Add container type`,
  key1001535: `Container Type:`,
  key1001536: `Please enter the container type, e.g. pass box`,
  key1001537: `Weight (kg):`,
  key1001538: `Volume (cm³):`,
  key1001539: `Length (cm):`,
  key1001540: `Width (cm):`,
  key1001541: `Height (cm):`,
  key1001542: `Notes:`,
  key1001543: `Container type`,
  key1001544: `Weight(kg)`,
  key1001545: `Volume (cm³)`,
  key1001546: `Length (cm)`,
  key1001547: `Width(cm)`,
  key1001548: `Height(cm)`,
  key1001549: `Container type cannot be null`,
  key1001550: `Container weight cannot be empty`,
  key1001551: `Container volume cannot be empty`,
  key1001552: `Container length cannot be empty`,
  key1001553: `Container width cannot be empty`,
  key1001554: `Container height cannot be empty`,
  key1001555: `Platform Currency:`,
  key1001556: `Supplier Currency:`,
  key1001557: `Add Supporting Currency`,
  key1001558: `Distributor Currency:`,
  key1001559: `Platform Currency`,
  key1001560: `Transaction Currency`,
  key1001561: `Exchange rate configuration`,
  key1001562: `Fixed exchange rate`,
  key1001563: `Real time exchange rate fluctuations`,
  key1001564: `Floating ratio`,
  key1001565: `Please enter a fixed exchange rate`,
  key1001566: `Exchange rate display`,
  key1001567: `Real time exchange rate:`,
  key1001568: `System exchange rate:`,
  key1001569: `Fixed exchange rate cannot be empty!`,
  key1001570: `The fixed exchange rate must be greater than or equal to 0 and a maximum of 4 decimal places allowed`,
  key1001571: `Real time exchange rate fluctuations cannot be empty!`,
  key1001572: `Real time exchange rate fluctuations must be greater than or equal to 0 and a maximum of 4 decimal places allowed`,
  key1001573: `Print container barcodes`,
  key1001574: `Dielectric Material:`,
  key1001575: `Label size:`,
  key1001576: `Custom content:`,
  key1001577: `Custom content font`,
  key1001578: `Bar code font`,
  key1001579: `Each PT is about 0.35 mm wide. Make sure that the longest number of SKU characters * the left PT value * 0.35 is smaller than the medium size.`,
  key1001580: `Do not print custom content`,
  key1001581: `Print custom content`,
  key1001582: `add`,
  key1001583: `set a unified print quantity`,
  key1001584: `Note: Printed content can be dragged to change position.`,
  key1001585: `Please enter custom content`,
  key1001586: `Add Message Template`,
  key1001587: `Message name`,
  key1001588: `Message type`,
  key1001589: `Notification channel`,
  key1001590: `The way of notification`,
  key1001591: `Mail within Website`,
  key1001592: `Email->SMS`,
  key1001593: `SMS->Mailbox`,
  key1001594: `After deleting the message template, the reminder message will no longer be sent. Are you sure to delete it?`,
  key1001595: `Message name:`,
  key1001596: `Please enter the name of the message, with a maximum of 20 Chinese characters`,
  key1001597: `Message type:`,
  key1001598: `Triggering conditions:`,
  key1001599: `Notification channel:`,
  key1001600: `The way of notification：`,
  key1001601: `Whether to pop up:`,
  key1001602: `Reminder time:`,
  key1001603: `The reminder time period is: 6:00-23:00, and no reminder in other time periods`,
  key1001604: `Please select a time.`,
  key1001605: `The stay time of the status exceeded`,
  key1001606: `Remind in time`,
  key1001607: `Fixed time`,
  key1001608: `Every`,
  key1001609: `Remind once`,
  key1001610: `1) Dynamic fields can be added by dragging and dropping from the right side.`,
  key1001611: `2) The blue font text is a dynamic parameter, please do not modify it.`,
  key1001612: `3) Customized content only supports internal messages and emails, and SMS will be sent according to the default content.`,
  key1001613: `dynamic parameter `,
  key1001614: `Title cannot be empty!`,
  key1001615: `The content cannot be empty!`,
  key1001616: `The message type cannot be empty`,
  key1001617: `Content:`,
  key1001618: `Edit Message Template`,
  key1001619: `Created successfully`,
  key1001620: `Edit successfully`,
  key1001621: `When the reminder time type is fixed time and the unit is the day, the specific reminder time period cannot be empty!`,
  key1001622: `The frequency of trigger conditions cannot be empty!`,
  key1001623: `The frequency cannot be empty when the reminder type is the fixed time!`,
  key1001624: `Storage area Name:`,
  key1001625: `Storage area code:`,
  key1001626: `Storage area Status:`,
  key1001627: `Warehouse name:`,
  key1001628: `Print warehouse barcode`,
  key1001629: `Storage area code`,
  key1001630: `Storage area`,
  key1001631: `Add storage area`,
  key1001632: `Bulk print barcodes`,
  key1001633: `Name of storage area`,
  key1001634: `Warehouse name cannot be empty`,
  key1001635: `Storage area name cannot be empty`,
  key1001636: `The storage area code cannot be empty.`,
  key1001637: `Status cannot be empty`,
  key1001638: `Print quantity`,
  key1001639: `View the library area`,
  key1001640: `Edit Warehouse Area`,
  key1001641: `Please check the data to be printed first!`,
  key1001642: `Warehouse:`,
  key1001643: `Container number:`,
  key1001644: `Please enter the container number`,
  key1001645: `Container color:`,
  key1001646: `Job status:`,
  key1001647: `Number of containers:`,
  key1001648: `Please enter a color name`,
  key1001649: `Bulk delete`,
  key1001650: `Upload audio`,
  key1001651: `Container number`,
  key1001652: `Container color`,
  key1001653: `Job status`,
  key1001654: `Warehouse`,
  key1001655: `Last occupied time`,
  key1001656: `Edit Container Color`,
  key1001657: `The warehouse must not be empty.`,
  key1001658: `idle`,
  key1001659: `Goods sorting completed`,
  key1001660: `Quality inspection in progress`,
  key1001661: `To stick waybill`,
  key1001662: `In sticking waybills`,
  key1001663: `Waiting on-shelf`,
  key1001664: `In publishing`,
  key1001665: `Waiting for multiple item sorting`,
  key1001666: `Multi item sorting`,
  key1001667: `Logistics sorting`,
  key1001668: `Add container`,
  key1001669: `Please select data`,
  key1001670: `The number of containers cannot be empty`,
  key1001671: `The number of containers must be a positive integer greater than 0`,
  key1001672: `Failed to upload audio, please try again`,
  key1001673: `Template selection cannot be empty`,
  key1001674: `Warehouse code:`,
  key1001675: `Warehouse type:`,
  key1001676: `Template selection:`,
  key1001677: `Regular order processing fee:`,
  key1001678: `Regular Multi Product Order Continuation:`,
  key1001679: `Regular order consumables fee:`,
  key1001680: `Stock order processing fee:`,
  key1001681: `Warehouse rent:`,
  key1001682: `/Pieces`,
  key1001683: `/Single`,
  key1001684: `/Month`,
  key1001685: `Add to`,
  key1001686: `Warehouse processing fee`,
  key1001687: `+Add tiered pricing`,
  key1001688: `everyday`,
  key1001689: `hour`,
  key1001690: `Add a new warehouse`,
  key1001691: `Warehouse code cannot be empty`,
  key1001692: `Warehouse type cannot be empty`,
  key1001693: `Regular order processing fee cannot be empty`,
  key1001694: `Regular multi item order continuation cannot be empty`,
  key1001695: `The processing fee for stocking orders cannot be empty`,
  key1001696: `Warehouse shipping address`,
  key1001697: `Warehouse delivery address`,
  key1001698: `Warehouse return address`,
  key1001699: `Full name`,
  key1001700: `Country/Region`,
  key1001701: `Province/State`,
  key1001702: `City`,
  key1001703: `county `,
  key1001704: `Street address`,
  key1001705: `Mobile phone number`,
  key1001706: `Zip code`,
  key1001707: `Company Name`,
  key1001708: `Email`,
  key1001709: `Name:`,
  key1001710: `Edit warehouses`,
  key1001711: `View warehouse`,
  key1001712: `Name is required.`,
  key1001713: `The left interval cannot be empty!`,
  key1001714: `The right interval cannot be empty!`,
  key1001715: `Layered price cannot be empty!`,
  key1001716: `The left interval cannot be greater than or equal to the right interval`,
  key1001717: `Mark as Readed`,
  key1001718: `Total`,
  key1001719: `Messages, of which unread messages`,
  key1001720: `Click to view`,
  key1001721: `Message title`,
  key1001722: `Time`,
  key1001723: `Please tick the data you want to mark as read first!`,
  key1001724: `Destination country`,
  key1001725: `Customs clearance rate`,
  key1001726: `updater`,
  key1001727: `Update date`,
  key1001728: `Log`,
  key1001729: `Enable Confirmation`,
  key1001730: `Deactivation confirmation`,
  key1001731: `After activation, this destination country will verify the IOSS number. Please confirm whether to enable it?`,
  key1001732: `After deactivation, this destination country will no longer verify the IOSS number. Please confirm if you want to deactivate it?`,
  key1001733: `Confirm activation`,
  key1001734: `Confirm Deactivation`,
  key1001735: `Enabling Success`,
  key1001736: `Disabling Success`,
  key1001737: `Destination country:`,
  key1001738: `Customs clearance rate:`,
  key1001739: `The destination country cannot be empty`,
  key1001740: `The customs clearance rate cannot be empty`,
  key1001741: `Supports up to two decimal places, must be greater than or equal to 0`,
  key1001742: `Add tax rate settings`,
  key1001743: `Edit tax rate settings`,
  key1001744: `The customs clearance rate cannot be empty!`,
  key1001745: `Number of SKU species declared`,
  key1001746: `Number of SKU species declared per declaration`,
  key1001747: `The package contains information about goods to be declared when multiple SKUs are included`,
  key1001748: `Calculating method of declared weight`,
  key1001749: `Calculation method of declared price`,
  key1001750: `Default declaration settings`,
  key1001751: `All declarations`,
  key1001752: `Limit the maximum number of declared varieties`,
  key1001753: `Actual quantity`,
  key1001754: `Limit the maximum number of declarations`,
  key1001755: `This item is only used to determine the name and quantity of declaration, and has no effect on the weight and price of declaration.`,
  key1001756: `Use Fixed Total Weight Declaration`,
  key1001757: `The total weight of the fixed package is`,
  key1001758: `If the weight of each item needs to be declared in detail at the time of declaration, the system will automatically calculate the weighted average of the actual weight of the item declared.`,
  key1001759: `Declaration of true weight of goods used`,
  key1001760: `Set the top weight of the package to be`,
  key1001761: `Currency for declaration`,
  key1001762: `Use fixed price declaration`,
  key1001763: `Fixed declared price`,
  key1001764: `This item only includes the amount of money, and the currencies are taken from the declared currencies.`,
  key1001765: `Use price ratio declaration`,
  key1001766: `Use parcel`,
  key1001767: `Of`,
  key1001768: `Setting the lowest price`,
  key1001769: `Setting cap price`,
  key1001770: `After setting this option, the system will automatically convert the exchange rate according to the declared currency.`,
  key1001771: `Total sum`,
  key1001772: `Total amount of products`,
  key1001773: `Choose according to the order of unit price of goods cost from high to low`,
  key1001774: `Choose from high to low in the order of unit price X of the product cost`,
  key1001775: `Select according to the product volume size from large to small`,
  key1001776: `Choose according to the order of volume X from large to small`,
  key1001777: `Choose according to the order of weight from big to small`,
  key1001778: `Choose according to the order of weight X quantity from large to small`,
  key1001779: `The top price should be higher than the minimum price.`,
  key1001780: `Please enter the lowest price setting.`,
  key1001781: `Please enter Set Top Price`,
  key1001782: `Please enter the setting package capping weight`,
  key1001783: `Warehouse name`,
  key1001784: `Warehouse code`,
  key1001785: `Warehouse type`,
  key1001786: `Country`,
  key1001787: `Self-support`,
  key1001788: `请先选择所属仓库`,
  key1001789: `所属库区名称：`,
  key1001790: `Storage bin Code:`,
  key1001791: `Storage bin Status:`,
  key1001792: `Print storage bin barcode`,
  key1001793: `Storage bin code`,
  key1001794: `Storage bin`,
  key1001795: `Import storage bin`,
  key1001796: `Add storage bin`,
  key1001797: `Hint:`,
  key1001798: `1. Support extension name:`,
  key1001799: `2. When there are exceptions in the imported data, the data import fails;`,
  key1001800: `3. After the import operation, please review the import results later in the "Import/Export Tasks - Import View" section.`,
  key1001801: `所属库区名称`,
  key1001802: `所属库区代码`,
  key1001803: `Storage bin code cannot be empty.`,
  key1001804: `View location`,
  key1001805: `Edit location`,
  key1001806: `Return list`,
  key1001807: `Please enter the template name`,
  key1001808: `Deactivate Address`,
  key1001809: `Add address`,
  key1001810: `You need to select a new address to replace the deactivated address. Please select the address template you want to replace`,
  key1001811: `Template name`,
  key1001812: `cellphone number`,
  key1001813: `Enabled`,
  key1001814: `deactivated`,
  key1001815: `Deactivated successfully!`,
  key1001816: `Inbound sorting settings`,
  key1001817: `Picking container:`,
  key1001818: `Packaging container:`,
  key1001819: `Switch Warehouse`,
  key1001820: `Bill of Lading Collection`,
  key1001821: `Process status: enabled`,
  key1001822: `Bill of Lading Warehousing`,
  key1001823: `Inbound sorting`,
  key1001824: `Fast outbound mode`,
  key1001825: `Label during sorting`,
  key1001826: `Product publish`,
  key1001827: `Multi item sorting`,
  key1001828: `Skip this process for single item tasks`,
  key1001829: `Packaging operations`,
  key1001830: `Logistics sorting`,
  key1001831: `Shelving container`,
  key1001832: `Labeling+shelving containers`,
  key1001833: `Outbound container`,
  key1001834: `Packaging container`,
  key1001835: `Labeling+Packaging Container`,
  key1001836: `Picking container`,
  key1001837: `Sticking and picking containers`,
  key1001838: `Quantity limit for sorting container outbound orders`,
  key1001839: `We currently do not have warehouse data permissions. Please configure warehouse permissions and try again.`,
  key1001840: `There are ongoing inbound sorting tasks, please complete them before making any modifications.`,
  key1001841: `Name:`,
  key1001842: `Country/Region:`,
  key1001843: `Province/State:`,
  key1001844: `City:`,
  key1001845: `District:`,
  key1001846: `Street address:`,
  key1001847: `phone number:`,
  key1001848: `Zip code:`,
  key1001849: `Company Name:`,
  key1001850: `Email:`,
  key1001851: `Template name cannot be null.`,
  key1001852: `Name cannot be empty`,
  key1001853: `Country/Region cannot be empty`,
  key1001854: `Province and state cannot be empty`,
  key1001855: `The city cannot be empty`,
  key1001856: `District/County cannot be empty`,
  key1001857: `Street address cannot be empty`,
  key1001858: `Postal code cannot be empty`,
  key1001859: `Edit Address`,
  key1001860: `View address`,
  key1001861: `Special Declaration Rules`,
  key1001862: `Adding time`,
  key1001863: `Order Automation Rules - Edit`,
  key1001864: `Grade name:`,
  key1001865: `Distributors being shown:`,
  key1001866: `Add supplier level`,
  key1001867: `All (including not logged in)`,
  key1001868: `section`,
  key1001869: `Level name cannot be empty`,
  key1001870: `Grade name`,
  key1001871: `Distributor being shown`,
  key1001872: `View supplier level`,
  key1001873: `Edit Supplier Level`,
  key1001874: `Do you want to delete the current supplier level?`,
  key1001875: `Please select a distribution level!`,
  key1001876: `Mobile phone number:`,
  key1001877: `Please fill in the email address`,
  key1001878: `Business license:`,
  key1001879: `Legal representative's ID card:`,
  key1001880: `Business scale:`,
  key1001881: `Contact Name:`,
  key1001882: `Please fill in the contact name`,
  key1001883: `Contact phone number:`,
  key1001884: `Please fill in the contact person's phone number`,
  key1001885: `Fixed phone number:`,
  key1001886: `Please fill in the fixed phone number`,
  key1001887: `Store Name:`,
  key1001888: `Please fill in the store name`,
  key1001889: `Store logo:`,
  key1001890: `Business model:`,
  key1001891: `Planned operating categories:`,
  key1001892: `Expected number of products on the shelves:`,
  key1001893: `Product release frequency:`,
  key1001894: `Please fill in the specific quantity`,
  key1001895: `Business address:`,
  key1001896: `Please fill in the detailed address`,
  key1001897: `The belonging warehouse is the destination warehouse for the supplier's shipment, and at least one should be selected.`,
  key1001898: `Supplier level:`,
  key1001899: `Whether to allow the printing of logistics receipts:`,
  key1001900: `Whether to allow shipping directly:`,
  key1001901: `Application time:`,
  key1001902: `Reason for rejection:`,
  key1001903: `Account information`,
  key1001904: `Password:`,
  key1001905: `Regenerate`,
  key1001906: `Enterprise Information`,
  key1001907: `Only JPG, JPEG, and PNG formats are supported.`,
  key1001908: `Anthropomorphic portrait`,
  key1001909: `National Emblem Face`,
  key1001910: `Store Information`,
  key1001911: `Size: 300 * 300px, only supports JPG, JPEG, and PNG formats.`,
  key1001912: `paragraph`,
  key1001913: `Belonging to warehouse:`,
  key1001914: `Preferred Warehouse`,
  key1001915: `Set as preferred warehouse`,
  key1001916: `Account settlement:`,
  key1001917: `NO`,
  key1001918: `Bills after days`,
  key1001919: `Payment information`,
  key1001920: `findings of audit`,
  key1001921: `upload`,
  key1001922: `The upload file format is wrong, the file`,
  key1001923: `Wrong format,`,
  key1001924: `Please select [jpg, jpeg, png]`,
  key1001925: `Add supplier`,
  key1001926: `Please select the business scale`,
  key1001927: `Contact person cannot be empty`,
  key1001928: `Shop name cannot be null.`,
  key1001929: `Please select a business model`,
  key1001930: `Please select the planned business category`,
  key1001931: `Please select the expected number of products to be listed`,
  key1001932: `The detailed address cannot be empty.`,
  key1001933: `Supplier level cannot be empty`,
  key1001934: `Settlement per month`,
  key1001935: `Settlement every half month`,
  key1001936: `Settlement per week`,
  key1001937: `Less than 10 people`,
  key1001938: `10-30 people`,
  key1001939: `30-50 people`,
  key1001940: `50-100 people`,
  key1001941: `More than 100 people`,
  key1001942: `Own factory`,
  key1001943: `Channel Agent`,
  key1001944: `Distribution and wholesale`,
  key1001945: `Women's clothing`,
  key1001946: `Men's clothing`,
  key1001947: `Boys`,
  key1001948: `Girls`,
  key1001949: `Infants and children`,
  key1001950: `Below 10`,
  key1001951: `Above 100`,
  key1001952: `To be confirmed`,
  key1001953: `Publish daily`,
  key1001954: `Weekly release`,
  key1001955: `Monthly release`,
  key1001956: `Payment method:`,
  key1001957: `Account Holder Name:`,
  key1001958: `Account Name:`,
  key1001959: `Bank card number:`,
  key1001960: `ID number:`,
  key1001961: `Alipay account:`,
  key1001962: `Account Holder ID Number:`,
  key1001963: `company`,
  key1001964: `入驻审核状态：`,
  key1001965: `工商信息变更审核状态：`,
  key1001966: `Supplier Details`,
  key1001967: `Edit supplier information`,
  key1001968: `Audit suppliers`,
  key1001969: `审核供应商工商信息变更`,
  key1001970: `bank card`,
  key1001971: `Alipay`,
  key1001972: `Business license`,
  key1001973: `Legal Representative ID Card National Emblem Face`,
  key1001974: `Identity of the Legal Representative Witness Image`,
  key1001975: `Store logo`,
  key1001976: `Please upload your business license first`,
  key1001977: `Please upload the national emblem of the legal representative's ID card first`,
  key1001978: `Please upload the photo of the legal representative as a witness first`,
  key1001979: `Please upload the store logo first`,
  key1001980: `Please select the product release frequency type first!`,
  key1001981: `Account period settlement cannot be empty!`,
  key1001982: `The audit was successful!`,
  key1001983: `The number of product releases cannot be empty`,
  key1001984: `The number of product releases must be a positive integer greater than 0`,
  key1001985: `Business address cannot be empty`,
  key1001986: `Please select the complete business address of the province or city`,
  key1001987: `提交时间：`,
  key1001988: `Search characters:`,
  key1001989: `Supplier Name/Supplier Merchant ID/Contact Person/Phone/Email`,
  key1001990: `Add new suppliers`,
  key1001991: `Supplier code`,
  key1001992: `Please enter the supplier number, which supports multiple commas or line breaks for separation`,
  key1001993: `Please enter your phone number, which supports multiple commas or line breaks for separation`,
  key1001994: `Please enter an email address that supports multiple commas or line breaks`,
  key1001995: `已认证供应商`,
  key1001996: `黑名单供应商`,
  key1001997: `Successfully enabled!`,
  key1001998: `Contact person`,
  key1001999: `Mobile phone`,
  key1002000: `Telephone`,
  key1002001: `Total number of SKUs`,
  key1002002: `Registration time`,
  key1002003: `down time `,
  key1002004: `是否启用该供应商？启用后该供应商的账号可正常登录平台进行操作。`,
  key1002005: `Name`,
  key1002006: `Reason for discontinuation:`,
  key1002007: `Please enter the reason for deactivation`,
  key1002008: `After deactivation, suppliers will not be able to log in to the system and perform any operations such as shipping. Please operate with caution!`,
  key1002009: `供应商信息是否允许通过审核？`,
  key1002010: `Supplier Status`,
  key1002011: `初审`,
  key1002012: `信息变更`,
  key1002013: `法人代表身份证`,
  key1002014: `经营规模`,
  key1002015: `联系人姓名`,
  key1002016: `联系人手机号码`,
  key1002017: `Fixed phone number`,
  key1002018: `提交审核时间`,
  key1002019: `驳回时间`,
  key1002020: `to examine`,
  key1002021: `通过审核`,
  key1002022: `入驻审核`,
  key1002023: `工商信息变更审核`,
  key1002024: `驳回时间：`,
  key1002025: `确认通过`,
  key1002026: `Reject application`,
  key1002027: `Please enter the reason for rejection`,
  key1002028: `Rejected successfully!`,
  key1002029: `Statistics`,
  key1002030: `订单状态为已出库的累计订单总金额。`,
  key1002031: `订单状态为已出库的累计订单总数量。`,
  key1002032: `商城SKU状态为在售的总数量。`,
  key1002033: `Calculate the total number of products subscribed by all distributors, and only calculate once for multiple distributors subscribing to the same product.`,
  key1002034: `Calculate the total number of products that have been listed on the platform by all distributors, and only calculate once for different items of the same product.`,
  key1002035: `Calculate the total number of publications published by all distributors, based on the number of items.`,
  key1002036: `Sales`,
  key1002037: `Order volume`,
  key1002038: `Supplier Order Ranking`,
  key1002039: `Distributor Order Ranking`,
  key1002040: `Total sales revenue（`,
  key1002041: `Sales revenue for this month`,
  key1002042: `Yesterday's sales`,
  key1002043: `Today's sales`,
  key1002044: `Total number of orders (single)`,
  key1002045: `Number of orders for this month`,
  key1002046: `Yesterday's orders`,
  key1002047: `Today's orders`,
  key1002048: `Number of products on sale (SKU)`,
  key1002049: `New this month`,
  key1002050: `New from yesterday`,
  key1002051: `New today`,
  key1002052: `Product Subscriptions (SPU)`,
  key1002053: `Subscription this month`,
  key1002054: `Subscribed yesterday`,
  key1002055: `Subscribe Today`,
  key1002056: `Number of products listed (SPU)`,
  key1002057: `Number of listings this month`,
  key1002058: `Yesterday's listings`,
  key1002059: `Number of listings today`,
  key1002060: `Number of published items`,
  key1002061: `Number of publications this month`,
  key1002062: `Number of publications published yesterday`,
  key1002063: `Number of publications published today`,
  key1002064: `Total number of suppliers`,
  key1002065: `New additions this month`,
  key1002066: `Added yesterday`,
  key1002067: `New today`,
  key1002068: `Total number of distributors`,
  key1002069: `Order state`,
  key1002070: `Waiting for order`,
  key1002071: `Pending order`,
  key1002072: `This week`,
  key1002073: `This month`,
  key1002074: `this year`,
  key1002075: `Month`,
  key1002076: `The time span cannot exceed one year`,
  key1002077: `Ranking`,
  key1002078: `than last month`,
  key1002079: `than last week`,
  key1002080: `Distributor business number`,
  key1002081: `Distributor name`,
  key1002082: `Product list`,
  key1002083: `Stick waybill`,
  key1002084: `Inventory Balance`,
  key1002085: `Bill of Lading List`,
  key1002086: `Outbound details`,
  key1002087: `Questionnaire statistics`,
  key1002088: `Subsidy review`,
  key1002089: `Recharge record`,
  key1002090: `Supplier Total Bill`,
  key1002091: `Supplier Detailed Bill`,
  key1002092: `Supplier orders`,
  key1002093: `Item attribute pool`,
  key1002094: `Customer billing`,
  key1002095: `Logistics billing`,
  key1002096: `Warehouse bill`,
  key1002097: `Inventory details`,
  key1002098: `Item attributes`,
  key1002099: `Product information`,
  key1002100: `类目映射`,
  key1002101: `Notify to:`,
  key1002102: `Founder：`,
  key1002103: `Sent:`,
  key1002104: `Create Announcement`,
  key1002105: `draft`,
  key1002106: `To be modified`,
  key1002107: `Waiting to send`,
  key1002108: `has been sent`,
  key1002109: `Announcement Name`,
  key1002110: `send status`,
  key1002111: `Notification object`,
  key1002112: `Send time`,
  key1002113: `(Reservation)`,
  key1002114: `Reviewer`,
  key1002115: `Approval prompt`,
  key1002116: `After passing the review, the system will send an announcement at the specified time. Please confirm if the review has been approved?`,
  key1002117: `Delete announcement prompt`,
  key1002118: `The announcement cannot be restored after deletion. Are you sure you want to delete it?`,
  key1002119: `Deletion succeeded！`,
  key1002120: `Cancel sending`,
  key1002121: `Cancel sending prompt`,
  key1002122: `After cancellation, this announcement will be circulated as a draft. Are you sure you want to cancel sending?`,
  key1002123: `Create a new questionnaire`,
  key1002124: `Questionnaire Name`,
  key1002125: `Questionnaire status`,
  key1002126: `Paused`,
  key1002127: `Answering questions`,
  key1002128: `View volume`,
  key1002129: `Recovery rate`,
  key1002130: `Final modification time`,
  key1002131: `Cannot answer questions after discontinuation`,
  key1002132: `After discontinuation, questionnaire answering will be paused. Are you sure to discontinue?`,
  key1002133: `Questionnaire Title`,
  key1002134: `Questionnaire Description`,
  key1002135: `Please enter the title of the question`,
  key1002136: `Please enter an option`,
  key1002137: `Delivery channels`,
  key1002138: `Display method`,
  key1002139: `Trigger Event`,
  key1002140: `Select question type`,
  key1002141: `Optional`,
  key1002142: `Add Options`,
  key1002143: `Add Other`,
  key1002144: `Questionnaire settings`,
  key1002145: `text`,
  key1002146: `Distributor side`,
  key1002147: `pop-up notification`,
  key1002148: `Do not display the 'skip' button`,
  key1002149: `Display the 'Skip' button`,
  key1002150: `First login`,
  key1002151: `option`,
  key1002152: `Other____`,
  key1002153: `Do you want to save the questionnaire content`,
  key1002154: `Do you need to save an unsaved questionnaire?`,
  key1002155: `Do not save`,
  key1002156: `New successfully!`,
  key1002157: `The title of the question cannot be empty!`,
  key1002158: `The title of the question cannot be duplicate!`,
  key1002159: `New Guidelines`,
  key1002160: `PDF file`,
  key1002161: `Online Content`,
  key1002162: `Guidance Title`,
  key1002163: `Module`,
  key1002164: `Guidance type`,
  key1002165: `Sort`,
  key1002166: `Deactivate reminder`,
  key1002167: `After deactivation, users will not be able to view the tutorial,`,
  key1002168: `Are you sure you want to deactivate it?`,
  key1002169: `Delete Reminder`,
  key1002170: `You are about to permanently delete, and after deletion, you will not be able to recover,`,
  key1002171: `Are you sure you want to delete it?`,
  key1002172: `Directory Name`,
  key1002173: `Content title:`,
  key1002174: `Please enter a content title`,
  key1002175: `Text content:`,
  key1002176: `Sort by:`,
  key1002177: `Display status:`,
  key1002178: `Copy article title`,
  key1002179: `Up to 28 Chinese characters can be entered`,
  key1002180: `The bigger the number, the further back`,
  key1002181: `On`,
  key1002182: `Off`,
  key1002183: `add content`,
  key1002184: `Are you sure to delete [`,
  key1002185: `】Classification?`,
  key1002186: `Title`,
  key1002187: `Release date`,
  key1002188: `Last updated date`,
  key1002189: `display status`,
  key1002190: `Home page recommendation`,
  key1002191: `Add directory`,
  key1002192: `Article title cannot be empty`,
  key1002193: `catalogue`,
  key1002194: `The homepage can recommend up to 5 help documents. Please close other recommendations before opening them!`,
  key1002195: `Are you sure you want to delete the current article?`,
  key1002196: `edit directory`,
  key1002197: `The catalog classification can only have three hierarchical relationships at most, and only the same-level drag sorting is allowed!`,
  key1002198: `Content`,
  key1002199: `Open image on new tab`,
  key1002200: `1: 1 Display image`,
  key1002201: `Summary of problems`,
  key1002202: `Mouse wheel zoom image`,
  key1002203: `Guidance Title:`,
  key1002204: `Please enter the guide title`,
  key1002205: `Module:`,
  key1002206: `Display location:`,
  key1002207: `Guidance type:`,
  key1002208: `Reminder:`,
  key1002209: `1. Only files with PDF extensions are supported.`,
  key1002210: `2. The file size should not exceed 10Mb.`,
  key1002211: `The guide title cannot be empty`,
  key1002212: `The module to which it belongs cannot be empty`,
  key1002213: `Display position cannot be empty`,
  key1002214: `Bread crumbs right`,
  key1002215: `Editing Guidelines`,
  key1002216: `Successfully uploaded the file`,
  key1002217: `Please upload the PDF file first!`,
  key1002218: `The body content cannot be empty!`,
  key1002219: `Announcement Name:`,
  key1002220: `If the sending time has exceeded when the review is passed, the announcement will be sent immediately after the review is passed.`,
  key1002221: `Save as Draft`,
  key1002222: `Submit for review`,
  key1002223: `Please enter the announcement name`,
  key1002224: `Please select the notification object`,
  key1002225: `Edit Announcement`,
  key1002226: `Successfully submitted for review`,
  key1002227: `Please enter the main text content`,
  key1002228: `Please select the sending time`,
  key1002229: `Export Data`,
  key1002230: `Export by Selection`,
  key1002231: `Export according to query results`,
  key1002232: `Please check the data that needs to be exported first!`,
  key1002233: `There is currently no data available for the current filtering results, unable to export!`,
  key1002234: `Merchant ID`,
  key1002235: `Starting time for answering questions`,
  key1002236: `End Answer Time`,
  key1002237: `Customer Name:`,
  key1002238: `Contact person:`,
  key1002239: `Phone number:`,
  key1002240: `Please enter your cell phone number.`,
  key1002241: `Customer level:`,
  key1002242: `Warehouse permissions:`,
  key1002243: `客户类型：`,
  key1002244: `分销客户`,
  key1002245: `阿里国际分销`,
  key1002246: `Customer level cannot be empty`,
  key1002247: `Link Details`,
  key1002248: `Link Name:`,
  key1002249: `Source channel:`,
  key1002250: `Promoter:`,
  key1002251: `Promotion method:`,
  key1002252: `Promotion content:`,
  key1002253: `Promotion group:`,
  key1002254: `Notes`,
  key1002255: `Link Basic Information`,
  key1002256: `link data`,
  key1002257: `Total number of clicks`,
  key1002258: `Total number of registrations`,
  key1002259: `Registration conversion rate`,
  key1002260: `Please fill in the link name`,
  key1002261: `Time sharing data`,
  key1002262: `Number of clicks`,
  key1002263: `Number of registrations`,
  key1002264: `create link`,
  key1002265: `Please fill in the link address`,
  key1002266: `Created successfully!`,
  key1002267: `Customer details `,
  key1002268: `Customer Information`,
  key1002269: `Number:`,
  key1002270: `Customer Source:`,
  key1002271: `Customer Currency:`,
  key1002272: `Related merchants:`,
  key1002273: `Customer Responsible Person:`,
  key1002274: `Please fill in the remarks`,
  key1002275: `Balance:`,
  key1002276: `Withholding amount:`,
  key1002277: `Total amount:`,
  key1002278: `Used amount:`,
  key1002279: `Input Name`,
  key1002280: `职务：`,
  key1002281: `请输入职务`,
  key1002282: `Please enter email.`,
  key1002283: `Please enter a fixed phone number`,
  key1002284: `微信号：`,
  key1002285: `请输入微信号`,
  key1002286: `QQ号：`,
  key1002287: `请输入微QQ号`,
  key1002288: `Customer Journey`,
  key1002289: `主联系人`,
  key1002290: `Next contact time:`,
  key1002291: `Operator:`,
  key1002292: `Basic customer information`,
  key1002293: `Customer Financial Information`,
  key1002294: `Customer warehouse permissions`,
  key1002295: `客户API权限`,
  key1002296: `添加联系人`,
  key1002297: `设为主要联系人`,
  key1002298: `There is currently no travel data available`,
  key1002299: `Contact records`,
  key1002300: `Activate product records`,
  key1002301: `log information`,
  key1002302: `不是有效的邮箱或者邮箱格式不正确！`,
  key1002303: `信息至少要填写其中一个！`,
  key1002304: `Create Group`,
  key1002305: `Group Name`,
  key1002306: `Number of grouped customers`,
  key1002307: `Group Notes`,
  key1002308: `Delete grouping prompt`,
  key1002309: `Operation is in progress to delete the group. Are you sure you want to delete it?`,
  key1002310: `Delete Group`,
  key1002311: `Registration time:`,
  key1002312: `Registration source:`,
  key1002313: `Please select the registration source`,
  key1002314: `Customer label:`,
  key1002315: `Please select the customer label`,
  key1002316: `Please select customer level`,
  key1002317: `Customer Name/Customer Number/Contact Person/Phone/Email/Remarks`,
  key1002318: `New customers`,
  key1002319: `Mobile grouping`,
  key1002320: `Batch Configuration Label`,
  key1002321: `Bulk review`,
  key1002322: `Customer number`,
  key1002323: `Please enter the customer number, which supports multiple commas or line breaks for separation`,
  key1002324: `Are you sure to enable it?`,
  key1002325: `Are you sure to deactivate?`,
  key1002326: `Only customers awaiting review can proceed with the review. Please reselect!`,
  key1002327: `Please check the data to be reviewed first`,
  key1002328: `Ungrouped`,
  key1002329: `Customer Name`,
  key1002330: `Customer grouping`,
  key1002331: `Customer label`,
  key1002332: `Customer level`,
  key1002333: `Customer Currency`,
  key1002334: `balance`,
  key1002335: `Withholding amount`,
  key1002336: `Total credit limit`,
  key1002337: `Used quota`,
  key1002338: `Registration Source`,
  key1002339: `Customer remarks`,
  key1002340: `Please select a customer first`,
  key1002341: `Label Name:`,
  key1002342: `Please enter a label name`,
  key1002343: `Label remarks:`,
  key1002344: `Please fill in the label name`,
  key1002345: `create label`,
  key1002346: `Edit Label`,
  key1002347: `Specify grouping:`,
  key1002348: `Please select a group`,
  key1002349: `There is a deletion of the group in this operation. Please regroup the customers under the group`,
  key1002350: `Non Transit Customers`,
  key1002351: `Transit customers`,
  key1002352: `Tongtool merchant account cannot be empty`,
  key1002353: `View Customers`,
  key1002354: `Label Name`,
  key1002355: `Number of labeled customers`,
  key1002356: `Label remarks`,
  key1002357: `Delete label prompt`,
  key1002358: `After deleting the label, it will be synchronously removed from the customer. Are you sure you want to delete it?`,
  key1002359: `delete a tap`,
  key1002360: `Label Details`,
  key1002361: `Basic information of labels`,
  key1002362: `Label type:`,
  key1002363: `Label data`,
  key1002364: `Statistical time:`,
  key1002365: `Search Link Name`,
  key1002366: `Please select a label type`,
  key1002367: `Search Tag Name`,
  key1002368: `Link Name`,
  key1002369: `Link Type`,
  key1002370: `View QR code`,
  key1002371: `Copy Link`,
  key1002372: `Label:`,
  key1002373: `Please Choose Label`,
  key1002374: `Add tags`,
  key1002375: `Group name:`,
  key1002376: `Please enter a group name`,
  key1002377: `Group remarks:`,
  key1002378: `Please fill in the group name`,
  key1002379: `Enter up to 20 characters`,
  key1002380: `Edit Group`,
  key1002381: `Add customer level`,
  key1002382: `Rank`,
  key1002383: `View customer level`,
  key1002384: `Edit customer level`,
  key1002385: `Do you want to delete the current customer level?`,
  key1002386: `Default level when registering`,
  key1002387: `Default`,
  key1002388: `Set as default`,
  key1002389: `Change registration default level`,
  key1002390: `The current operation will affect the default level when registering mall customers. Are you sure to change the default level?`,
  key1002391: `Enter the outbound order no. to query`,
  key1002392: `Confirm to delete`,
  key1002393: `Picking order details`,
  key1002394: `Pick list No.:`,
  key1002395: `Picking order staus:`,
  key1002396: `Whether to print:`,
  key1002397: `Print picking list`,
  key1002398: `Created by:`,
  key1002399: `Delete the last entry？If the picking list is deleted, it will also be deleted！`,
  key1002400: `View by outbound order`,
  key1002401: `View by SKU`,
  key1002402: `Outbound order status`,
  key1002403: `Logistics distribution status`,
  key1002404: `Not issued`,
  key1002405: `Issued`,
  key1002406: `Remove from picking order`,
  key1002407: `Warehouse`,
  key1002408: `Product Image`,
  key1002409: `Picked quantity`,
  key1002410: `Volume`,
  key1002411: `Unpicked`,
  key1002412: `finished product`,
  key1002413: `原料`,
  key1002414: `Picking list:`,
  key1002415: `Material type`,
  key1002416: `Raw material SPU`,
  key1002417: `Raw material SKU`,
  key1002418: `Staff:`,
  key1002419: `(signature/date)`,
  key1002420: `Order picker`,
  key1002421: `(Signature date)`,
  key1002422: `cancel printing`,
  key1002423: `Mark printed successfully`,
  key1002424: `Order No.:`,
  key1002425: `Multiple outbound orders separated by commas`,
  key1002426: `Category:`,
  key1002427: `Sort by:`,
  key1002428: `Generate picking list`,
  key1002429: `Generate picking list based on selection`,
  key1002430: `Generate picking orders (all)`,
  key1002431: `Creation Time`,
  key1002432: `By shipping method`,
  key1002433: `Temu sample`,
  key1002434: `Temu goods`,
  key1002435: `Outbound order type`,
  key1002436: `Distributor order number`,
  key1002437: `SKU Qty`,
  key1002438: `Item Qty`,
  key1002439: `View Details`,
  key1002440: `Please check the data that needs to generate a picking list first!`,
  key1002441: `There is currently no data available for the current filtering results, unable to generate a picking list!`,
  key1002442: `No logistics selected`,
  key1002443: `Unspecified logistic channel`,
  key1002444: `Picking order no.:`,
  key1002445: `Print status：`,
  key1002446: `Bulk operation`,
  key1002447: `Mark as picked`,
  key1002448: `Not printed`,
  key1002449: `Printed`,
  key1002450: `Creation time`,
  key1002451: `By picking order type`,
  key1002452: `Query the packed picking list, the creation time cannot be empty!`,
  key1002453: `Marking Success`,
  key1002454: `Please select`,
  key1002455: `Picking order no.`,
  key1002456: `Picking list type`,
  key1002457: `Picking status`,
  key1002458: `Print status`,
  key1002459: `Number of SKUs`,
  key1002460: `Number of items`,
  key1002461: `Picking finish time`,
  key1002462: `Picking list source`,
  key1002463: `outbound order`,
  key1002464: `Sorting container`,
  key1002465: `Print assembly labels`,
  key1002466: `Failed to obtain the printing assembly label link address!`,
  key1002467: `Property name:`,
  key1002468: `Please enter the attribute name, supporting multilingual search`,
  key1002469: `Whether to customize:`,
  key1002470: `Single/Multiple Choice:`,
  key1002471: `Import item attribute`,
  key1002472: `Export Properties`,
  key1002473: `2. When there is an abnormality in the imported data, the data import fails.`,
  key1002474: `number`,
  key1002475: `Property Chinese name`,
  key1002476: `Specifications/General Properties`,
  key1002477: `Size attribute`,
  key1002478: `Fill in the requirements`,
  key1002479: `Do you want to customize it`,
  key1002480: `Single/Multiple Choice`,
  key1002481: `Specification/General Properties (Specification, General)`,
  key1002482: `Whether to customize (Yes, No)`,
  key1002483: `Attribute value single/multiple selection (multiple, single selection)`,
  key1002484: `Size attribute (Yes, No)`,
  key1002485: `Filling requirements (mandatory, non mandatory, recommended)`,
  key1002486: `Attribute information`,
  key1002487: `Chinese Simplified`,
  key1002488: `French`,
  key1002489: `German`,
  key1002490: `Spanish`,
  key1002491: `Dutch`,
  key1002492: `Polish`,
  key1002493: `Portuguese`,
  key1002494: `Italian`,
  key1002495: `Attribute Status`,
  key1002496: `Attribute value information`,
  key1002497: `Attribute Value Status`,
  key1002498: `Attribute deletion prompt`,
  key1002499: `There is a deletion of attributes in this modification. After deletion, the attribute field in the existing product information will be deleted as well. Are you sure you want to save this modification?`,
  key1002500: `After deactivating the attribute, when suppliers publish new products and modify published products, this attribute will not be available. Are you sure you want to deactivate it?`,
  key1002501: `Immediately deactivate`,
  key1002502: `Add Template`,
  key1002503: `Body part`,
  key1002504: `Unit`,
  key1002505: `Do you want to delete the current size template?`,
  key1002506: `Please enter a Chinese title, support multiple lines separated`,
  key1002507: `Supplier:`,
  key1002508: `商品类型：`,
  key1002509: `Export Product List`,
  key1002510: `Deselect`,
  key1002511: `Addr label`,
  key1002512: `Supplier Information`,
  key1002513: `Classification`,
  key1002514: `Recently discontinued`,
  key1002515: `First listing time`,
  key1002516: `First submission for review time`,
  key1002517: `View SKU`,
  key1002518: `View central warehouse inventory`,
  key1002519: `View supplier inventory`,
  key1002520: `1688 Find the same model`,
  key1002521: `Please enter the supplier SKU, which supports multiple row separations`,
  key1002522: `Product categories`,
  key1002523: `Sales status`,
  key1002524: `Last listing time`,
  key1002525: `Inventory allocation in central warehouse`,
  key1002526: `Supplier allocated inventory`,
  key1002527: `商品类型`,
  key1002528: `Export in SPU dimension`,
  key1002529: `(One row per SPU in the report)`,
  key1002530: `Export as SKU dimension`,
  key1002531: `(One row per SKU in the report)`,
  key1002532: `minimum value`,
  key1002533: `Maximum value`,
  key1002534: `Supplier warehouse inventory`,
  key1002535: `Product listing time`,
  key1002536: `The minimum inventory value cannot be greater than the maximum value. Please modify the conditions before querying.`,
  key1002537: `1. Choose a platform`,
  key1002538: `2. Select a site`,
  key1002539: `4. Select Product Type`,
  key1002540: `4. 选择Family Type`,
  key1002541: `5. Product Specification Mapping`,
  key1002542: `6. Product Attribute Mapping`,
  key1002543: `Custom values`,
  key1002544: `平台属性变更，原值：`,
  key1002545: `Select Site`,
  key1002546: `Category cannot be empty`,
  key1002547: `Product Specification Mapping`,
  key1002548: `Attribute:`,
  key1002549: `Mapped:`,
  key1002550: `To be mapped:`,
  key1002551: `Attribute value:`,
  key1002552: `Attribute value mapped:`,
  key1002553: `Attribute values to be mapped:`,
  key1002554: `Automatic matching`,
  key1002555: `Matching in progress, please wait`,
  key1002556: `Automatic matching completed, matched attributes:`,
  key1002557: `Matched attribute values:`,
  key1002558: `查看更多可选值`,
  key1002559: `No data`,
  key1002560: `Product Attribute Mapping`,
  key1002561: `Attribute value type`,
  key1002562: `Add platform`,
  key1002563: `Platform cannot be empty`,
  key1002564: `Site cannot be null`,
  key1002565: `Custom`,
  key1002566: `Platform attributes`,
  key1002567: `Platform Attribute Value Type`,
  key1002568: `Editing Platform`,
  key1002569: `View Platform`,
  key1002570: `(Mapped)`,
  key1002571: `Product Type cannot be empty`,
  key1002572: `FamiltType不能为空`,
  key1002573: `Start matching`,
  key1002574: `正在匹配中...`,
  key1002575: `Matching data acquisition completed`,
  key1002576: `Data matching`,
  key1002577: `Interface request error, which may result in incomplete data matching`,
  key1002578: `Name data matching`,
  key1002579: `Platform mapping table`,
  key1002580: `eCommerce platform:`,
  key1002581: `Site name:`,
  key1002582: `Category name:`,
  key1002583: `Custom attribute values`,
  key1002584: `Searching`,
  key1002585: `Enter text to search`,
  key1002586: `Platform label attributes`,
  key1002587: `Mapping attributes`,
  key1002588: `Rules for generating Listing keywords`,
  key1002589: `Random`,
  key1002590: `Not choose`,
  key1002591: `Switching Product Type will clear the list data`,
  key1002592: `Please select Product Type first`,
  key1002593: `Platform attributes cannot be repeated`,
  key1002594: `No platform category found`,
  key1002595: `Price range (CNY):`,
  key1002596: `Comprehensive score:`,
  key1002597: `24-hour collection rate:`,
  key1002598: `48 hour recovery rate:`,
  key1002599: `Search keywords:`,
  key1002600: `rise`,
  key1002601: `Starting batch`,
  key1002602: `minute`,
  key1002603: `Transactions in the past 30 days:`,
  key1002604: `Unlimited`,
  key1002605: `5 stars`,
  key1002606: `4.5-5.0 stars`,
  key1002607: `4-4.5 stars`,
  key1002608: `Below 4 stars`,
  key1002609: `Certified factory`,
  key1002610: `Same day shipment`,
  key1002611: `24-hour shipping`,
  key1002612: `48 hour shipping`,
  key1002613: `7 days without reason`,
  key1002614: `drop shipping`,
  key1002615: `Support free shipping`,
  key1002616: `at price`,
  key1002617: `Monthly sales volume`,
  key1002618: `The price starting range cannot be greater than the price ending range`,
  key1002619: `The Chinese name cannot be empty!`,
  key1002620: `Size chart template name`,
  key1002621: `Add body part`,
  key1002622: `New unit`,
  key1002623: `The Chinese name of the body part in the same column cannot be repeated!`,
  key1002624: `Body part 1`,
  key1002625: `English name`,
  key1002626: `Dutch name`,
  key1002627: `The multilingual names of the body part in the same list must be filled in completely or all blanks!`,
  key1002628: `The unit name cannot be empty!`,
  key1002629: `Successfully added a template!`,
  key1002630: `Successfully updated the template!`,
  key1002631: `Move upward`,
  key1002632: `Move down`,
  key1002633: `You cannot select duplicate unit values!`,
  key1002634: `Property pool management`,
  key1002635: `Please enter the Chinese name of the attribute`,
  key1002636: `Only one of the product specifications can be a size attribute!`,
  key1002637: `Please select attribute pool data first!`,
  key1002638: `Enter keyword search`,
  key1002639: `Category ID:`,
  key1002640: `父级类目已配置不展示，此类目不展示于商城。`,
  key1002641: `HS code:`,
  key1002642: `Chinese declaration name:`,
  key1002643: `English declaration name:`,
  key1002644: `Size Chart Template:`,
  key1002645: `Add subcategory`,
  key1002646: `导入映射`,
  key1002647: `Classification information`,
  key1002648: `商城展示配置：`,
  key1002649: `展示`,
  key1002650: `不展示`,
  key1002651: `Platform Information`,
  key1002652: `Customs declaration information`,
  key1002653: `Select from Property Pool`,
  key1002654: `Please confirm`,
  key1002655: `Is this platform available?`,
  key1002656: `Total number of products:`,
  key1002657: `Are you sure to delete this content?`,
  key1002658: `HS code cannot be empty`,
  key1002659: `The Chinese customs declaration name cannot be empty`,
  key1002660: `The English customs declaration name cannot be empty`,
  key1002661: `The size chart template cannot be empty`,
  key1002662: `Platform Name`,
  key1002663: `Site`,
  key1002664: `Product classification`,
  key1002665: `modify`,
  key1002666: `Whether to update the generation rules of other item attributes for Listing keywords to the current generation rules`,
  key1002667: `synchronization`,
  key1002668: `Main attribute settings`,
  key1002669: `Main attribute`,
  key1002670: `Set as primary attribute`,
  key1002671: `Can only drag at the same level`,
  key1002672: `Please set the main attribute of the product specification first!`,
  key1002673: `Do you want to delete the current category?`,
  key1002674: `clothing`,
  key1002675: `Cannot contain - > special characters`,
  key1002676: `文件格式有误, 仅支持上传xls、xlsx格式文件`,
  key1002677: `Please enter the number of prints`,
  key1002678: `Print face sheet`,
  key1002679: `Supplier Name/Supplier Merchant Number`,
  key1002680: `Bulk reject`,
  key1002681: `Supplier Item Number/SPU`,
  key1002682: `Chinese Name`,
  key1002683: `供应商商户号 / 名称`,
  key1002684: `Approval Status`,
  key1002685: `Please check the data that needs batch review!`,
  key1002686: `请先勾选需要批量驳回的数据！`,
  key1002687: `Bill payment confirmation`,
  key1002688: `Payment password:`,
  key1002689: `Please enter the payment password`,
  key1002690: `Check whether the bill is correct, and confirm that the payment will be directly transferred to the supplier's account through Alipay after the payment is made. Please operate carefully.`,
  key1002691: `Confirm payment`,
  key1002692: `Time of the bill:`,
  key1002693: `Customer Merchant Number:`,
  key1002694: `Please enter the customer's merchant number`,
  key1002695: `Export customer bills`,
  key1002696: `year`,
  key1002697: `Order amount`,
  key1002698: `Order Currency`,
  key1002699: `Bill number`,
  key1002700: `Billing cycle`,
  key1002701: `Customer merchant number`,
  key1002702: `Monthly cumulative recharge amount`,
  key1002703: `Alipay recharge amount`,
  key1002704: `Manual recharge amount`,
  key1002705: `Accumulated consumption amount`,
  key1002706: `Bill generation time`,
  key1002707: `Billing Information`,
  key1002708: `Merchant`,
  key1002709: `Bill to which it belongs`,
  key1002710: `Recharge serial number`,
  key1002711: `Third party transaction number`,
  key1002712: `Recharge amount`,
  key1002713: `Payment Mode`,
  key1002714: `Recharge success time`,
  key1002715: `Consumption records`,
  key1002716: `Third-party waybill number`,
  key1002717: `Number or item`,
  key1002718: `Logistics weight (g)`,
  key1002719: `Product amount`,
  key1002720: `Logistics fees`,
  key1002721: `Order completion time`,
  key1002722: `Billing dimension`,
  key1002723: `Detail dimension`,
  key1002724: `Logistics expenses are payable by the supplier, and the amount of goods and subsidies are receivables. Please note when calculating`,
  key1002725: `Charge Details`,
  key1002726: `Audit confirmation`,
  key1002727: `Offline payment made`,
  key1002728: `货品结算金额`,
  key1002729: `货品金额小计`,
  key1002730: `Supplier bill number:`,
  key1002731: `Supplier Name:`,
  key1002732: `Supplier Merchant Number:`,
  key1002733: `Bill generation time:`,
  key1002734: `Bill settlement status:`,
  key1002735: `Bill status update time:`,
  key1002736: `Bill amount:`,
  key1002737: `Amount of goods (payable):`,
  key1002738: `Logistics expenses (receivable):`,
  key1002739: `Subsidy amount (payable):`,
  key1002740: `Billing cycle:`,
  key1002741: `Logistics costs`,
  key1002742: `Bill review confirmation`,
  key1002743: `Has the bill been reviewed and confirmed to be correct? After confirmation, it will become available for payment.`,
  key1002744: `Confirmation of offline payment for bills`,
  key1002745: `Please confirm if offline payment has been made.`,
  key1002746: `Confirm offline payment made`,
  key1002747: `types of fee`,
  key1002748: `Quantity of goods`,
  key1002749: `Accumulated sales of SPU in the past 30 days`,
  key1002750: `Bill of lading number`,
  key1002751: `End packing time`,
  key1002752: `Collection time`,
  key1002753: `Delivery time on Yunmai Network`,
  key1002754: `Payable logistics fees:`,
  key1002755: `Payable Other Expenses:`,
  key1002756: `Logistics actual weight (g):`,
  key1002757: `Unreconciled order quantity:`,
  key1002758: `Logistics provider currency:`,
  key1002759: `Bill Details`,
  key1002760: `Only look at loss making orders`,
  key1002761: `Only view unreconciled orders`,
  key1002762: `Profit and loss (current filtering criteria):`,
  key1002763: `Bill number:`,
  key1002764: `Bill update time:`,
  key1002765: `Customer paid logistics fees:`,
  key1002766: `Other payable expenses:`,
  key1002767: `Total cost:`,
  key1002768: `Document number`,
  key1002769: `Document Status `,
  key1002770: `System calculates logistics weight (g)`,
  key1002771: `Carrier's waybill number`,
  key1002772: `Logistics actual weight (g)`,
  key1002773: `Customer paid logistics fees`,
  key1002774: `Payable logistics fees`,
  key1002775: `Unreconciled`,
  key1002776: `Payable other expenses`,
  key1002777: `Total cost`,
  key1002778: `Profit and loss`,
  key1002779: `Delivery time of package`,
  key1002780: `Reconciliation data source`,
  key1002781: `Import bills`,
  key1002782: `Payable logistics fees cannot be empty`,
  key1002783: `Notes cannot be null.`,
  key1002784: `Bill confirmation settlement`,
  key1002785: `We are currently processing bill settlement and will no longer be able to modify the bill after settlement. Please confirm whether to settle?`,
  key1002786: `Settlement successful!`,
  key1002787: `Currency modification successful!`,
  key1002788: `Distributor account number:`,
  key1002789: `Top-up time:`,
  key1002790: `Recharge status:`,
  key1002791: `Recharge serial number:`,
  key1002792: `Please enter the recharge serial number, which supports multiple separate lines`,
  key1002793: `Export recharge records`,
  key1002794: `Recharge`,
  key1002795: `In recharging`,
  key1002796: `Recharge successfully`,
  key1002797: `Recharge failed`,
  key1002798: `Third party voucher number`,
  key1002799: `Third party vouchers`,
  key1002800: `Pre recharge balance`,
  key1002801: `Balance after recharge`,
  key1002802: `Initialized quota`,
  key1002803: `Remaining balance`,
  key1002804: `Recharge status`,
  key1002805: `Order processing fee:`,
  key1002806: `Order consumables fee:`,
  key1002807: `Additional service fee:`,
  key1002808: `Return processing fee:`,
  key1002809: `Cost Name:`,
  key1002810: `Cost amount:`,
  key1002811: `+Add fees`,
  key1002812: `Number of orders processed per month:`,
  key1002813: `Total amount：`,
  key1002814: `Customer's actual payment amount:`,
  key1002815: `Other expenses:`,
  key1002816: `Cost Name`,
  key1002817: `Total amount`,
  key1002818: `Associated document number`,
  key1002819: `Quantity of goods in the order`,
  key1002820: `Order processing fee`,
  key1002821: `Order consumables fee`,
  key1002822: `Additional service fee`,
  key1002823: `Return processing fee`,
  key1002824: `total`,
  key1002825: `Customer's actual payment amount`,
  key1002826: `Cost incurred time`,
  key1002827: `Modify fees`,
  key1002828: `Document fees`,
  key1002829: `Other expenses`,
  key1002830: `Order processing fee cannot be empty`,
  key1002831: `The expense amount cannot be empty`,
  key1002832: `The expense name cannot be empty`,
  key1002833: `Add fees`,
  key1002834: `Successfully added fees`,
  key1002835: `Cost modification successful`,
  key1002836: `Successfully added notes`,
  key1002837: `Recharge currency:`,
  key1002838: `If the recharge currency is different from the distributor's currency, the system will convert it to the distributor's currency for recharge based on the exchange rate.`,
  key1002839: `Recharge denomination:`,
  key1002840: `Payment method:`,
  key1002841: `Payment voucher number:`,
  key1002842: `Payment voucher picture:`,
  key1002843: `Upload attachments`,
  key1002844: `The currency of the distributor is`,
  key1002845: `Actual receipt`,
  key1002846: `System exchange rate: 1`,
  key1002847: `Cash`,
  key1002848: `Distributor merchant account number cannot be empty`,
  key1002849: `The payment method cannot be empty`,
  key1002850: `Payment voucher number cannot be empty`,
  key1002851: `Recharge currency cannot be empty`,
  key1002852: `Custom recharge denomination cannot be empty!`,
  key1002853: `Please upload payment flow pictures!`,
  key1002854: `Successfully recharged!`,
  key1002855: `Up to five attachments can be uploaded!`,
  key1002856: `Please enter the distributor's merchant account number`,
  key1002857: `(Distributor Currency)`,
  key1002858: `账单状态：`,
  key1002859: `可搜索账单号/供应商名称`,
  key1002860: `Export supplier invoice`,
  key1002861: `Batch payment`,
  key1002862: `Batch confirmation of offline payment`,
  key1002863: `Supplier bill number`,
  key1002864: `Please enter the supplier account number, which supports multiple commas or line breaks for separation`,
  key1002865: `Bill amount`,
  key1002866: `Billing Currency`,
  key1002867: `Number of orders`,
  key1002868: `Bill settlement status`,
  key1002869: `Bill status update time`,
  key1002870: `Supplier confirmation time`,
  key1002871: `Audit time`,
  key1002872: `Payment time`,
  key1002873: `Payer`,
  key1002874: `Payment method`,
  key1002875: `Receiving account information`,
  key1002876: `Payment method`,
  key1002877: `Name of account holder`,
  key1002878: `Bank`,
  key1002879: `account`,
  key1002880: `ID number`,
  key1002881: `Bill Details`,
  key1002882: `Supply price (CNY)`,
  key1002883: `货品金额小计(CNY)`,
  key1002884: `Time of incurring expenses`,
  key1002885: `Waybill type`,
  key1002886: `Order creation time`,
  key1002887: `Central warehouse delivery time`,
  key1002888: `Whether to prepare goods in the central warehouse`,
  key1002889: `General Ledger`,
  key1002890: `Batch payment can only be made if the collection account is Alipay!`,
  key1002891: `Please select a bill first!`,
  key1002892: `Alipay order number`,
  key1002893: `Offline payment`,
  key1002894: `Subsidy rules:`,
  key1002895: `Please select subsidy rules`,
  key1002896: `Logistics`,
  key1002897: `Logistics mode`,
  key1002898: `Subsidy rules`,
  key1002899: `Bill of Lading Weight`,
  key1002900: `Quantity of goods in the bill of lading`,
  key1002901: `On time delivery rate`,
  key1002902: `Warehousing time`,
  key1002903: `Sorting time`,
  key1002904: `Last updated by`,
  key1002905: `Please enter the bill of lading number, which supports multiple commas or line breaks for separation`,
  key1002906: `Please enter the waybill number, which supports multiple commas or line breaks for separation`,
  key1002907: `Please enter the supplier merchant account, which supports multiple commas or line breaks for separation`,
  key1002908: `Only pending orders can be batch approved!`,
  key1002909: `Please check the data that needs to be reviewed first!`,
  key1002910: `Door-to-door collection`,
  key1002911: `Description`,
  key1002912: `Reviewed`,
  key1002913: `Quantity of goods in the bill of lading`,
  key1002914: `Re review`,
  key1002915: `Subsidy Description`,
  key1002916: `Total:`,
  key1002917: `物流-快递`,
  key1002918: `物流-上门揽收`,
  key1002919: `As of`,
  key1002920: `Previous`,
  key1002921: `single`,
  key1002922: `Subsidy based on the number of bills of lading`,
  key1002923: `Subsidy based on the weight of the bill of lading`,
  key1002924: `No subsidy`,
  key1002925: `Shipping method`,
  key1002926: `Order delivery rate`,
  key1002927: `Subsidy recipients`,
  key1002928: `Subsidy type`,
  key1002929: `Subsidy conditions`,
  key1002930: `apply`,
  key1002931: `Actual data`,
  key1002932: `Subsidy calculation`,
  key1002933: `1. Single bill of lading subsidy:`,
  key1002934: `1.0-1 kg subsidy:`,
  key1002935: `2. Subsidy per kilogram of continued weight:`,
  key1002936: `3. Maximum subsidy for a single bill of lading:`,
  key1002937: `Compliance sub rule:`,
  key1002938: `Compliance with rules:`,
  key1002939: `The subsidy is manually adjusted to:`,
  key1002940: `Export warehouse bills`,
  key1002941: `Update billing`,
  key1002942: `Settlement status`,
  key1002943: `In the settlement`,
  key1002944: `Paid`,
  key1002945: `Total amount (CNY)`,
  key1002946: `Order processing volume`,
  key1002947: `Warehouse rent`,
  key1002948: `Bill update time`,
  key1002949: `Monthly order processing volume (CNY)`,
  key1002950: `Order consumables cost (CNY)`,
  key1002951: `Additional fees (CNY)`,
  key1002952: `Return processing fee (CNY)`,
  key1002953: `Warehouse rent (CNY)`,
  key1002954: `Order consumables cost`,
  key1002955: `Additional service fees`,
  key1002956: `Return processing fees`,
  key1002957: `Total amount`,
  key1002958: `The storage fees are being recalculated, please refresh the list later to view!`,
  key1002959: `Please check the data that needs to be updated!`,
  key1002960: `Rule calculation subsidy:`,
  key1002961: `Adjusted subsidy:`,
  key1002962: `Please enter the adjusted subsidy amount`,
  key1002963: `Adjustment instructions:`,
  key1002964: `Please enter adjustment instructions`,
  key1002965: `The adjusted subsidy amount cannot be empty`,
  key1002966: `Adjustment description cannot be empty`,
  key1002967: `Cost status:`,
  key1002968: `types of fee:`,
  key1002969: `Cost incurred time:`,
  key1002970: `Distributor`,
  key1002971: `Document number`,
  key1002972: `Month to which the bill belongs`,
  key1002973: `Cost flow status`,
  key1002974: `Invoice has been generated`,
  key1002975: `No bill generated`,
  key1002976: `Export logistics bills`,
  key1002977: `Import logistics provider bills`,
  key1002978: `Import logistics bills`,
  key1002979: `1. Once the settled billing data is imported, it will no longer be updated and will be displayed as a failure;`,
  key1002980: `2. Support extension:`,
  key1002981: `3. When there is an abnormality in the imported data, the data import fails;`,
  key1002982: `Account number`,
  key1002983: `Number of monthly packages`,
  key1002984: `Bill matching quantity`,
  key1002985: `Please select the subsidy review result:`,
  key1002986: `Reason for failure to pass the audit:`,
  key1002987: `Audit reason cannot be empty`,
  key1002988: `Delete payment password`,
  key1002989: `Please enter the payment password:`,
  key1002990: `New payment password:`,
  key1002991: `Please enter the new password again:`,
  key1002992: `Please re-enter the payment password`,
  key1002993: `Please enter the password again:`,
  key1002994: `Billing payment password`,
  key1002995: `Remove password`,
  key1002996: `After deleting the payment password, it will no longer be necessary to enter the password for online payment confirmation. Please operate with caution.`,
  key1002997: `Not set`,
  key1002998: `Set payment password`,
  key1002999: `The payment password cannot be empty`,
  key1003000: `Replacement successful!`,
  key1003001: `Saved successfully!`,
  key1003002: `The passwords entered twice do not match`,
  key1003003: `Set`,
  key1003004: `Change payment password`,
  key1003005: `Consumption records (only calculate cancelled and shipped parts of orders)`,
  key1003006: `Monthly cumulative recharge:`,
  key1003007: `Monthly Alipay cumulative recharge:`,
  key1003008: `Monthly manual cumulative recharge:`,
  key1003009: `Monthly cumulative consumption:`,
  key1003010: `Third party transaction vouchers`,
  key1003011: `多个`,
  key1003012: `SKU请换行分隔`,
  key1003013: `Bill of Lading SKU Details`,
  key1003014: `The current SKU in the bill of lading is shown in the following figure, totaling`,
  key1003015: `Species, total`,
  key1003016: `Pieces.`,
  key1003017: `Goods on the shelves`,
  key1003018: `Quantity to be put on shelves`,
  key1003019: `On-shelf quantity`,
  key1003020: `The warehouse name cannot be empty!`,
  key1003021: `The library area name cannot be empty!`,
  key1003022: `The location name cannot be empty!`,
  key1003023: `The quantity on the shelf cannot be empty!`,
  key1003024: `The quantity on the shelf must be an integer greater than 0`,
  key1003025: `The number of shelves cannot be greater than the number of shelves!`,
  key1003026: `The quantity on the shelf must be greater than 0`,
  key1003027: `Scan or enter supplier SKU`,
  key1003028: `Bulk print`,
  key1003029: `In sticker job...`,
  key1003030: `End the sticker job`,
  key1003031: `Quantity to be sticked`,
  key1003032: `Scan successfully!`,
  key1003033: `Please enter the correct supplier SKU!`,
  key1003034: `Supplier SKU cannot be empty!`,
  key1003035: `Please tick the data to be printed first!`,
  key1003036: `The number of prints cannot be empty!`,
  key1003037: `Conventional sorting`,
  key1003038: `Stock sorting`,
  key1003039: `Scan:`,
  key1003040: `It is able to scan outbound order number, waybill number, logistics order number, container number`,
  key1003041: `Weigh:`,
  key1003042: `Please keep the weight unit of the system and the weight unit of the electronic scale consistent.`,
  key1003043: `Actual weighing (`,
  key1003044: `Weigh`,
  key1003045: `Shipping method:`,
  key1003046: `End container sorting`,
  key1003047: `Knot bag`,
  key1003048: `Print appointment delivery note prompt`,
  key1003049: `For online shipment on AliExpress, a pre delivery note is required. Please print the pre delivery note in the logistics handover note after closing the bag.`,
  key1003050: `Not yet bagged`,
  key1003051: `Booking and bagging`,
  key1003052: `Print collection waybill prompt`,
  key1003053: `AliExpress fully managed JIT orders require printing of collection waybills. If not printed, delivery to AliExpress collection will not be possible.`,
  key1003054: `Printing and bagging`,
  key1003055: `Remove`,
  key1003056: `Remove Package Reminder`,
  key1003057: `The package is being removed and can be rescanned for packaging after removal`,
  key1003058: `Still need`,
  key1003059: `Complete`,
  key1003060: `Remove success`,
  key1003061: `gram`,
  key1003062: `Kilogram`,
  key1003063: `The weighing unit is:`,
  key1003064: `Please enter the outbound order number or waybill number!`,
  key1003065: `Weighing must be an integer greater than 0!`,
  key1003066: `The weighing cannot be empty!`,
  key1003067: `Failed to obtain the waybill address!`,
  key1003068: `You still have parcels that have not been sorted. Do you want to end sorting?`,
  key1003069: `Logistic sorting exception reminder`,
  key1003070: `Forced end`,
  key1003071: `Handover person:`,
  key1003072: `Please enter the handover person`,
  key1003073: `Mark out`,
  key1003074: `Handover order number`,
  key1003075: `Parcel qty`,
  key1003076: `Delivery method`,
  key1003077: `Express delivery to pickup point`,
  key1003078: `Logistics distribution method`,
  key1003079: `System distribution`,
  key1003080: `Manual distribution`,
  key1003081: `Domestic logistics tracking number`,
  key1003082: `Handover time`,
  key1003083: `Handover person`,
  key1003084: `Continue packing`,
  key1003085: `Print Pre Delivery Form`,
  key1003086: `Print express waybill`,
  key1003087: `Failed to obtain the express delivery address`,
  key1003088: `Appointment collection`,
  key1003089: `Print collection waybill`,
  key1003090: `Please enter the handover number, which supports multiple commas or line breaks for separation`,
  key1003091: `Please enter the outbound tracking number, which supports multiple commas or line breaks for separation`,
  key1003092: `Please check the data to be marked out of the library first!`,
  key1003093: `Failed to obtain pre delivery waybill address`,
  key1003094: `Appointment successful!`,
  key1003095: `Failed to obtain the collection waybill address`,
  key1003096: `请扫描或录入提单号/快递单号`,
  key1003097: `Number of outbound orders in box/bag`,
  key1003098: `Warehousing is successful!`,
  key1003099: `Please enter the bill of lading number!`,
  key1003100: `Listing operation:`,
  key1003101: `Scan or enter the container number`,
  key1003102: `Supports multiple container numbers separated by commas or line breaks`,
  key1003103: `Export listing data`,
  key1003104: `Container status:`,
  key1003105: `Container status`,
  key1003106: `Product quantity`,
  key1003107: `Homework start time`,
  key1003108: `Continue to publish`,
  key1003109: `Actual number of listings`,
  key1003110: `Assignment End Time`,
  key1003111: `Job duration`,
  key1003112: `Export by container dimension`,
  key1003113: `Export by SKU dimension`,
  key1003114: `Number of SKUs inside the container`,
  key1003115: `Storage bin for putaway`,
  key1003116: `The bill of lading number cannot be empty!`,
  key1003117: `Product shelving job Reminder`,
  key1003118: `You have unfinished product publishing jobs. Do you want to continue the job?`,
  key1003119: `Sticking homework:`,
  key1003120: `Export sticker data`,
  key1003121: `Sticker job`,
  key1003122: `Continue to stick waybills`,
  key1003123: `Posted`,
  key1003124: `The container number cannot be empty!`,
  key1003125: `Product sticker job reminder`,
  key1003126: `You have an unfinished product sticker job. Do you want to continue the job?`,
  key1003127: `Sorting exception:`,
  key1003128: `Collecting time:`,
  key1003129: `Export Bill of Lading List`,
  key1003130: `Batch marked as collected`,
  key1003131: `Missing package`,
  key1003132: `Logistics trajectory`,
  key1003133: `Quantity of goods receivable`,
  key1003134: `Actual received quantity of accounts receivable`,
  key1003135: `Supplier's quantity of multiple shipments`,
  key1003136: `Abnormal state`,
  key1003137: `Abnormal quantity, please click to view`,
  key1003138: `Shipping Address`,
  key1003139: `Mark received`,
  key1003140: `Cancel`,
  key1003141: `Please enter SKU, supporting multiple commas or line breaks for separation`,
  key1003142: `Bill of lading number`,
  key1003143: `Supplier Merchant Name`,
  key1003144: `courier number`,
  key1003145: `Shipping method`,
  key1003146: `Export by picking order dimension`,
  key1003147: `Please tick the data to be marked as received first!`,
  key1003148: `Delivery time:`,
  key1003149: `Details`,
  key1003150: `(已取消)`,
  key1003151: `Actual weight of package (g)`,
  key1003152: `data sources `,
  key1003153: `Weigher`,
  key1003154: `移除成功！`,
  key1003155: `Shipping settings`,
  key1003156: `Unit:`,
  key1003157: `截去：`,
  key1003158: `部分物流商面单扫描的运单号会带前缀或后缀，这样系统就无法识别运单号，勾选可截去相对应的前缀或后缀。`,
  key1003159: `开启(必填)`,
  key1003160: `开启(非必填)`,
  key1003161: `不开启`,
  key1003162: `Before`,
  key1003163: `After`,
  key1003164: `Please scan the TEMU tag number`,
  key1003165: `TEMU tag number`,
  key1003166: `Has it been scanned`,
  key1003167: `Weight (g)`,
  key1003168: `Print packaging labels`,
  key1003169: `Please scan the TEMU tag number!`,
  key1003170: `Scan successful`,
  key1003171: `Please scan all TEMU bags before proceeding with the bag tying operation.`,
  key1003172: `The shipping address was not obtained!`,
  key1003173: `作废提单确认`,
  key1003174: `作废原因：`,
  key1003175: `请输入作废原因`,
  key1003176: `作废提单后，未发货订单将重新流转至供应商待发货列表中。`,
  key1003177: `确认作废`,
  key1003178: `Canceled successfully!`,
  key1003179: `Scan Record`,
  key1003180: `Revoke Action`,
  key1003181: `The container has ended and cannot be revoked`,
  key1003182: `Sorting container:`,
  key1003183: `Bill of lading number:`,
  key1003184: `Sorting time:`,
  key1003185: `Supplier SKU:`,
  key1003186: `Please scan the bill of lading number first!`,
  key1003187: `Revoke operation prompt`,
  key1003188: `After revocation, the SKU in the corresponding container will be removed. Please operate with caution.`,
  key1003189: `Revoke`,
  key1003190: `提单中存在多发货品，`,
  key1003191: `将作为备货进行处理，并已自动创建相应备货单号，类型为多发补单`,
  key1003192: `Bill of Lading Number:`,
  key1003193: `Bill of lading declared quantity and actual arrival quantity`,
  key1003194: `Sales Order:`,
  key1003195: `Stock list:`,
  key1003196: `Quantity of multiple shipments`,
  key1003197: `Actual received quantity`,
  key1003198: `Destination warehouse`,
  key1003199: `Packed`,
  key1003200: `Operation content/message`,
  key1003201: `Please enter the content of the remarks!`,
  key1003202: `Sorting operations:`,
  key1003203: `扫描或输入提单号/快递单号`,
  key1003204: `Bill of Lading Number:`,
  key1003205: `Supports multiple bill of lading numbers, separated by commas or line breaks`,
  key1003206: `Export sorting data`,
  key1003207: `Enter the homework interface`,
  key1003208: `Bill of Lading Status:`,
  key1003209: `Bill of Lading Status`,
  key1003210: `Bill of Lading Type`,
  key1003211: `Number of outbound orders`,
  key1003212: `Sorting operation`,
  key1003213: `Number of goods on the bill of lading`,
  key1003214: `Actual sorting quantity`,
  key1003215: `SKU quantity`,
  key1003216: `Warehousing operation time`,
  key1003217: `Sorting start time`,
  key1003218: `Sorting end time`,
  key1003219: `Sorting end operator`,
  key1003220: `Inbound sorting operation reminder`,
  key1003221: `You have unfinished sorting operations. Do you want to continue the operation?`,
  key1003222: `Prompt for abnormal listing`,
  key1003223: `The number of listings cannot exceed the number of listings to be placed`,
  key1003224: `The warehouse area and location are not filled in`,
  key1003225: `Partial listing`,
  key1003226: `End listing`,
  key1003227: `The number of SKU listings is inconsistent with the number of pending listings. The details are as follows. Please confirm whether to end the listing?`,
  key1003228: `Continuing homework`,
  key1003229: `Quantity to be listed`,
  key1003230: `There is an abnormal SKU, please check and try again`,
  key1003231: `Incomplete listing data`,
  key1003232: `扫描或输入`,
  key1003233: `SKU/供应商SKU/物流面单`,
  key1003234: `Scan or enter the bill of lading number`,
  key1003235: `Please scan or enter the container number`,
  key1003236: `Sorting job...`,
  key1003237: `End sorting operation`,
  key1003238: `End this order sorting`,
  key1003239: `Piece-by-piece`,
  key1003240: `Whether to prompt if the quantity to be shipped is exceeded`,
  key1003241: `Enable voice broadcasting`,
  key1003242: `To be labeled`,
  key1003243: `Please add a container`,
  key1003244: `Container sorting`,
  key1003245: `Find a variety of types of waybills, please choose the right one!`,
  key1003246: `Not to be processed temporarily`,
  key1003247: `Bind Container`,
  key1003248: `End sorting`,
  key1003249: `Already on`,
  key1003250: `Same SKU exists in container, please end`,
  key1003251: `Rescan bill of lading after container`,
  key1003252: `SKU/供应商SKU/物流面单不能不为空！`,
  key1003253: `Receivable quantity`,
  key1003254: `Sorting quantity`,
  key1003255: `Sorting still needed`,
  key1003256: `Quantity scanned`,
  key1003257: `Please scan the barcode`,
  key1003258: `Sorted quantity`,
  key1003259: `Unsorted`,
  key1003260: `The bill of lading number cannot be empty!`,
  key1003261: `There is no this product in the bill of lading, please confirm carefully!`,
  key1003262: `This package has been sorted, please do not repeat scanning`,
  key1003263: `Container picking prompt`,
  key1003264: `The picking quantity for this container has reached the maximum limit, and the system will automatically complete the picking of the container. Please bind a new container to continue the operation.`,
  key1003265: `The packaging quantity of this container has reached the maximum limit, and the system will automatically pick the container. Please bind a new container to continue the operation.`,
  key1003266: `Will end the bill of lading and container sorting at the same time, are you sure to end?`,
  key1003267: `Binding succeeded!`,
  key1003268: `Container color repetition prompt`,
  key1003269: `This color container already exists. Please select a different color container.`,
  key1003270: `Set container color prompt`,
  key1003271: `This container has no color. Please select a container with color attributes.`,
  key1003272: `Please scan the bill of lading number first!`,
  key1003273: `Container number cannot be empty!`,
  key1003274: `Please select the type of waybill!`,
  key1003275: `Reminder for SKU exceeding accounts receivable`,
  key1003276: `The SKUs in progress have exceeded the receivable quantity. If there are SKUs exceeding the receivable quantity after sorting is completed, the system will automatically make up the order and send a prompt to the supplier, without the need for manual operation.`,
  key1003277: `This SKU needs to be shelved, and there is currently no bound sticker+shelving container. Please bind sticker+shelving container:`,
  key1003278: `This SKU needs to be put on the shelves, and there is currently no bound container. Please bind the container to be put on the shelves:`,
  key1003279: `Prompt for abnormal SKU quantity`,
  key1003280: `There are differences between the following SKU quantities and the expected quantity. After sorting, the actual overcharged SKU system will automatically make up the order and send a prompt to the supplier.`,
  key1003281: `Domestic courier selection`,
  key1003282: `Ordering method:`,
  key1003283: `Domestic express delivery:`,
  key1003284: `This logistics channel requires express delivery to the pickup point. After packaging, please repackage all packages under this channel, with the outermost layer labeled with a domestic express waybill!`,
  key1003285: `System Ordering`,
  key1003286: `Placing an offline order`,
  key1003287: `Please select domestic express delivery`,
  key1003288: `请输入取消`,
  key1003289: `订单原因，此原因分销商可见。`,
  key1003290: `Cancel Order`,
  key1003291: `订单取消原因：`,
  key1003292: `Confirm cancellation`,
  key1003293: `订单原因`,
  key1003294: `Canceled successfully!`,
  key1003295: `Total amount of supplier orders:`,
  key1003296: `Total distributor order amount:`,
  key1003297: `Distributor's payable amount:`,
  key1003298: `Supplier's receivable amount:`,
  key1003299: `Save and place an order`,
  key1003300: `The total amount of supplier orders and distributor orders cannot be empty`,
  key1003301: `Save and place order successfully`,
  key1003302: `Stock order status:`,
  key1003303: `Stock order number:`,
  key1003304: `Please enter the stocking order number, which supports multiple line separations`,
  key1003305: `Order date:`,
  key1003306: `Order information`,
  key1003307: `Distributor:`,
  key1003308: `查看日志`,
  key1003309: `Price to be adjusted`,
  key1003310: `Waiting for suppliers to accept orders`,
  key1003311: `To be paid by the distributor`,
  key1003312: `Partial arrival`,
  key1003313: `Completed`,
  key1003314: `Order amount:`,
  key1003315: `Order original amount:`,
  key1003316: `Amount after price adjustment:`,
  key1003317: `已取消金额：`,
  key1003318: `Refund amount:`,
  key1003319: `订单时间`,
  key1003320: `Payment time:`,
  key1003321: `Completion time:`,
  key1003322: `供应商子单信息`,
  key1003323: `To be resent`,
  key1003324: `Completed`,
  key1003325: `Order number:`,
  key1003326: `子单金额`,
  key1003327: `金额：`,
  key1003328: `调价：`,
  key1003329: `结算：`,
  key1003330: `子单时间`,
  key1003331: `Shipment date:`,
  key1003332: `入库时间：`,
  key1003333: `Quantity received`,
  key1003334: `Cancel quantity`,
  key1003335: `退款数量`,
  key1003336: `待入库`,
  key1003337: `Please enter SPU, supporting multiple commas or line breaks for separation`,
  key1003338: `Please enter SKU, supporting multiple commas or line breaks for separation`,
  key1003339: `Please enter the distributor merchant account, which supports multiple commas or line breaks`,
  key1003340: `Please enter the supplier merchant account, which supports multiple commas or line breaks for separation`,
  key1003341: `Order confirmation`,
  key1003342: `We are currently placing an order. Please confirm if you want to place an order?`,
  key1003343: `Confirm order placement`,
  key1003344: `Order placed successfully!`,
  key1003345: `原单价：`,
  key1003346: `调价后：`,
  key1003347: `单价：`,
  key1003348: `Out of stock quantity:`,
  key1003349: `Replenishing`,
  key1003350: `Expected delivery time:`,
  key1003351: `部分停售，请展开SKU查看`,
  key1003352: `缺货，请展开SKU查看`,
  key1003353: `Main information`,
  key1003354: `Consignee information`,
  key1003355: `logistics provider information`,
  key1003356: `Order details:`,
  key1003357: `Order SKU`,
  key1003358: `packaging information `,
  key1003359: `Shipped（`,
  key1003360: `Row no.`,
  key1003361: `Supplier order number`,
  key1003362: `Out of stock status`,
  key1003363: `Out of stock, please check details`,
  key1003364: `Order status update time`,
  key1003365: `Void or not`,
  key1003366: `unclosed`,
  key1003367: `Details`,
  key1003368: `Chinese Declaration Name`,
  key1003369: `English Declaration Name`,
  key1003370: `Customs value`,
  key1003371: `Customs weight`,
  key1003372: `Declaration Amount`,
  key1003373: `HS Code`,
  key1003374: `Item link`,
  key1003375: `Customs information`,
  key1003376: `Log content description`,
  key1003377: `Waybill type:`,
  key1003378: `Distributor order number:`,
  key1003379: `Warehouse for shipment:`,
  key1003380: `Consignee name:`,
  key1003381: `Country:`,
  key1003382: `Customer ID:`,
  key1003383: `Address 1:`,
  key1003384: `Address 2:`,
  key1003385: `Logistics order no.:`,
  key1003386: `Shipping method:`,
  key1003387: `Waybill No.1:`,
  key1003388: `Waybll No.2:`,
  key1003389: `Logistics provider weighing (g):`,
  key1003390: `Freight from logistics provider:`,
  key1003391: `Temu Tag Number`,
  key1003392: `SKU * Quantity`,
  key1003393: `Package weight (g)`,
  key1003394: `There is currently no packaging label available`,
  key1003395: `Allocated inventory`,
  key1003396: `Stocked`,
  key1003397: `Please fill in the new row fully.`,
  key1003398: `Please add declaration information first!`,
  key1003399: `The current small package has not undergone logistics sorting and cannot print the box label`,
  key1003400: `SKU Information`,
  key1003401: `Outbound order type:`,
  key1003402: `Arrival time:`,
  key1003403: `All SKUs`,
  key1003404: `Abnormal quantity SKU upon arrival`,
  key1003405: `Initiate stock quantity`,
  key1003406: `Actual Quantity Received`,
  key1003407: `Change shipping method`,
  key1003408: `Select the warehouse:`,
  key1003409: `Please select the shipping method after change:`,
  key1003410: `Account:`,
  key1003411: `Modify failed data`,
  key1003412: `Logistics dynamic parameters`,
  key1003413: `Confirm`,
  key1003414: `previous logistics provider`,
  key1003415: `Original shipping method`,
  key1003416: `Destination`,
  key1003417: `Reason`,
  key1003418: `No data selected`,
  key1003419: `Please choose a logistics channel`,
  key1003420: `Account number cannot be null.`,
  key1003421: `SKU，支持多个逗号或换行分隔`,
  key1003422: `Order Status:`,
  key1003423: `订单标签：`,
  key1003424: `Distribution status:`,
  key1003425: `Shipping method:`,
  key1003426: `Order type:`,
  key1003427: `Source:`,
  key1003428: `履约方：`,
  key1003429: `Abnormal state:`,
  key1003430: `When the source is the data of our own channels, and the logistics method can be modified!`,
  key1003431: `Issue logistics`,
  key1003432: `订单号，支持多个逗号或换行分隔`,
  key1003433: `Status update time:`,
  key1003434: `Order creation time:`,
  key1003435: `Order delivery time:`,
  key1003436: `Order cancellation time:`,
  key1003437: `个供应商子单`,
  key1003438: `The number of the products:`,
  key1003439: `订单SKU信息`,
  key1003440: `Order delivery time`,
  key1003441: `Delivery time`,
  key1003442: `Please enter the distributor order number, which supports multiple commas or line breaks for separation`,
  key1003443: `Please enter the supplier order number, which supports multiple commas or line breaks for separation`,
  key1003444: `Please enter the waybill number, which supports multiple commas or line breaks for separation`,
  key1003445: `Please enter the Temu tag number, which supports multiple commas or line breaks for separation`,
  key1003446: `Logistics tracking number`,
  key1003447: `Please enter the third-party logistics tracking number, which supports multiple commas or line breaks for separation`,
  key1003448: `(To be executed for shipment)`,
  key1003449: `To be issued`,
  key1003450: `Under distribution`,
  key1003451: `Successful distribution`,
  key1003452: `Distribution failed`,
  key1003453: `Domestic express delivery fee:`,
  key1003454: `货品金额：`,
  key1003455: `Logistics fees:`,
  key1003456: `Processing fee:`,
  key1003457: `Parcels that need to be sent by courier to the collection point will be subject to an additional domestic courier fee.`,
  key1003458: `清关费：`,
  key1003459: `Update carrier`,
  key1003460: `Add Carrier`,
  key1003461: `物流三方单号：`,
  key1003462: `国内快递单号：`,
  key1003463: `Shipping Information `,
  key1003464: `客选物流：`,
  key1003465: `Partial replenishment in progress`,
  key1003466: `部分已停售`,
  key1003467: `查看子单`,
  key1003468: `Product information`,
  key1003469: `Notify distributors`,
  key1003470: `允许取消`,
  key1003471: `Own channel`,
  key1003472: `Third-party channels`,
  key1003473: `Cost of goods (CNY)`,
  key1003474: `Total cost (CNY)`,
  key1003475: `Processing fee (CNY)`,
  key1003476: `Logistics fee (CNY)`,
  key1003477: `Logistics name`,
  key1003478: `Waybill No.`,
  key1003479: `Waybill number 1`,
  key1003480: `Waybill number 2`,
  key1003481: `Supplier delivery time`,
  key1003482: `Bill of lading collection time`,
  key1003483: `Order cancellation time`,
  key1003484: `Reason for order cancellation`,
  key1003485: `Order Type`,
  key1003486: `订单标签`,
  key1003487: `Platform order information`,
  key1003488: `Platform Site`,
  key1003489: `Store Name`,
  key1003490: `Platform order number`,
  key1003491: `Platform order amount`,
  key1003492: `Platform order status`,
  key1003493: `Platform order SKU information`,
  key1003494: `Order date`,
  key1003495: `Payment date`,
  key1003496: `Order dimension`,
  key1003497: `SKU dimension`,
  key1003498: `(Column)`,
  key1003499: `(Line)`,
  key1003500: `When displaying by row, general information will be merged, please note when making statistics.`,
  key1003501: `Status update time`,
  key1003502: `By update time`,
  key1003503: `供应商仓直发`,
  key1003504: `分销集货仓发`,
  key1003505: `分销集货仓发(未贴标)`,
  key1003506: `A maximum of 1000 items can be queried at a time. Please adjust and try again.`,
  key1003507: `Update completed!`,
  key1003508: `Please tick the data to be distributed first!`,
  key1003509: `Please tick the data to be modified first!`,
  key1003510: `请先勾选要添加标签的数据！`,
  key1003511: `删除标签确认`,
  key1003512: `正在操作删除订单标签。请确认是否删除?`,
  key1003513: `confirm deletion`,
  key1003514: `已装箱状态允许取消订单确认`,
  key1003515: `取消处理费：`,
  key1003516: `此订单已装箱，请务必确认现场是否未发出，取消后需及时联系相关人员将包裹进行取出。`,
  key1003517: `允许取消订单`,
  key1003518: `取消处理费不能为空`,
  key1003519: `Supplier order number:`,
  key1003520: `Outbound order status:`,
  key1003521: `Quantity of goods:`,
  key1003522: `Out of stock information`,
  key1003523: `Please select the status of the backup order`,
  key1003524: `Supplier delivery order status:`,
  key1003525: `Please select the status of the outbound order`,
  key1003526: `Supplier delivery order type:`,
  key1003527: `Please select the type of supplier's outbound order`,
  key1003528: `Export Order`,
  key1003529: `Received`,
  key1003530: `Sku,支持多个逗号或换行分隔`,
  key1003531: `Stock order number`,
  key1003532: `Please enter the stock order number, which supports multiple commas or line breaks for separation`,
  key1003533: `Stock order status`,
  key1003534: `Supplier Delivery Order Status`,
  key1003535: `Supplier delivery order type`,
  key1003536: `Shipment date`,
  key1003537: `Cancel Time`,
  key1003538: `Please select order status.`,
  key1003539: `Settlement status:`,
  key1003540: `Please select settlement status`,
  key1003541: `Time out:`,
  key1003542: `The actual received quantity in the warehouse is insufficient. Please check the details in the shipping record`,
  key1003543: `It has been invalidated and needs to be reshipped.`,
  key1003544: `Remaining delivery time`,
  key1003545: `Timed out`,
  key1003546: `Barcode:`,
  key1003547: `订单数量：`,
  key1003548: `Received quantity:`,
  key1003549: `Timeout time`,
  key1003550: `完结时间`,
  key1003551: `完结时间指此订单流程已全部结束时间节点，订单将已此节点时间计入对应账期。`,
  key1003552: `Mark arrival`,
  key1003553: `Update tags`,
  key1003554: `Timed out but not sent`,
  key1003555: `Please enter the order number, which supports multiple commas or line breaks for separation`,
  key1003556: `Please enter the supplier SKU, which supports multiple commas or line breaks for separation`,
  key1003557: `Please enter the supplier name, supporting multiple commas or line breaks for separation`,
  key1003558: `Supplier Merchant Number`,
  key1003559: `total amount of the order`,
  key1003560: `子单类型`,
  key1003561: `Not recorded`,
  key1003562: `The start duration of the timeout cannot be greater than the end duration`,
  key1003563: `Failed to obtain marked out of stock details data!`,
  key1003564: `Timed out`,
  key1003565: `second`,
  key1003566: `添加备注：`,
  key1003567: `Modify waybill number`,
  key1003568: `Please enter the waybill number`,
  key1003569: `Please enter the logistics tracking number`,
  key1003570: `Please choose logistics mode`,
  key1003571: `Waybill No. cannot be empty`,
  key1003572: `SKU，用逗号或换行分隔`,
  key1003573: `SPU，用逗号或换行分隔`,
  key1003574: `Multiple supplier merchant accounts separated by commas or line breaks`,
  key1003575: `Storage bin:`,
  key1003576: `Please enter storage bin code.`,
  key1003577: `Shipper:`,
  key1003578: `Please enter the merchant number`,
  key1003579: `总库存：`,
  key1003580: `Export inventory surplus`,
  key1003581: `导出库存明细`,
  key1003582: `导出库存余量数据`,
  key1003583: `导出库存明细数据`,
  key1003584: `1. After importing inventory, it will overwrite the original inventory. Please be cautious when importing;`,
  key1003585: `Import products`,
  key1003586: `SKU`,
  key1003587: `Specification attribute values`,
  key1003588: `Owner role`,
  key1003589: `Merchant ID of the shipper`,
  key1003590: `By product`,
  key1003591: `Search by SKU / storage bin`,
  key1003592: `The available quantity must be a positive integer greater than or equal to 0`,
  key1003593: `可用库存开始区间不能大于可用库存结束区间`,
  key1003594: `Details`,
  key1003595: `库存详情`,
  key1003596: `Display data with inventory 0`,
  key1003597: `Conventional packaging`,
  key1003598: `Stock packaging`,
  key1003599: `Packager:`,
  key1003600: `Please enter the picking list number, which supports multiple line separation`,
  key1003601: `Export multiple item sorting`,
  key1003602: `Scan picking list number/container number:`,
  key1003603: `You have unfinished packaging tasks, picking order number:`,
  key1003604: `Do you want to continue with the assignment?`,
  key1003605: `Order number`,
  key1003606: `Picking order type`,
  key1003607: `Number of outbound orders`,
  key1003608: `Picking/sorting completion time`,
  key1003609: `Picker/Sorter`,
  key1003610: `Packager`,
  key1003611: `Start homework`,
  key1003612: `Operation operator`,
  key1003613: `Start homework time`,
  key1003614: `End homework time`,
  key1003615: `Reminder for regular packaging operations`,
  key1003616: `Reminder for stocking and packaging operations`,
  key1003617: `Processing`,
  key1003618: `Please enter the pick order number!`,
  key1003619: `The input sequence of the list of goods when the single-item picking list is printed:`,
  key1003620: `The input order of the product list when printing the multi-product pick list:`,
  key1003621: `Collection timeout:`,
  key1003622: `Print the address sheet when sorting:`,
  key1003623: `Pick order Setup`,
  key1003624: `Collection timeout setting`,
  key1003625: `Packaging job settings`,
  key1003626: `Conventional sorting rack settings (only available when the automatic sorting function for multiple items and packages is enabled)`,
  key1003627: `Enable left-side sorting rack`,
  key1003628: `Shelf matrix`,
  key1003629: `row`,
  key1003630: `column`,
  key1003631: `Enabling intermediate sorting shelves`,
  key1003632: `Enable right-hand sorting rack`,
  key1003633: `Preparation and sorting rack settings (only available when the automatic sorting function for multiple items and packages is enabled)`,
  key1003634: `Save the setting of see goods issuance`,
  key1003635: `sort by storage bin in ascending order`,
  key1003636: `Only in descending order by product location`,
  key1003637: `Please select at least one regular picking rack!`,
  key1003638: `Please select at least one stocking and picking shelf!`,
  key1003639: `Sorter:`,
  key1003640: `You have unfinished sorting tasks, picking list number:`,
  key1003641: `Sorter`,
  key1003642: `Scan/enter the picking list number to start multiple item sorting:`,
  key1003643: `Reminder for regular sorting operations`,
  key1003644: `Reminder for stocking and sorting operations`,
  key1003645: `The weight of the package cannot be empty`,
  key1003646: `Small knot bag`,
  key1003647: `Package weight (g):`,
  key1003648: `The current SKU in the small package is shown in the following figure, totaling`,
  key1003649: `After confirming the quantity, please weigh it first and then perform the bagging operation.`,
  key1003650: `Printing labels and bagging`,
  key1003651: `To be inventory`,
  key1003652: `processing`,
  key1003653: `Inventory order:`,
  key1003654: `Warehouse:`,
  key1003655: `Picking reminder:`,
  key1003656: `Inventory order status:`,
  key1003657: `Dynamic inventory:`,
  key1003658: `Random inventory:`,
  key1003659: `Inventory operator:`,
  key1003660: `Inventory`,
  key1003661: `Counting quantity`,
  key1003662: `Gains and losses`,
  key1003663: `Success`,
  key1003664: `Picking orders`,
  key1003665: `Picking order no.`,
  key1003666: `Picking order generation rules:`,
  key1003667: `Go to the list of picking order`,
  key1003668: `The maximum number of packages per stocking and picking list cannot be empty!`,
  key1003669: `The maximum number of packages per regular picking list cannot be empty!`,
  key1003670: `Picking order`,
  key1003671: `Picking order no.:{pickingGoodsNo} After sorting, click【End the sorting】End this assignment`,
  key1003672: `Products you scan{sku}，The packages in the current picking list are not required or the required quantity has been met。`,
  key1003673: `Pick list of current sorting operation:`,
  key1003674: `End this sorting work`,
  key1003675: `SKU/UPC`,
  key1003676: `All sorting completed`,
  key1003677: `Product abnormal warning`,
  key1003678: `Possible reasons:`,
  key1003679: `1. You picked the products by mistake or picked too many products.`,
  key1003680: `2. The barcode of the product is pasted incorrectly.`,
  key1003681: `Please send the item to the exception handling department。`,
  key1003682: `Continue to scan other items (Enter)`,
  key1003683: `Pick list information:`,
  key1003684: `Outbound order information:`,
  key1003685: `Reset the package to not start sorting`,
  key1003686: `Remove the package from the current picking order and wait for a new picking order`,
  key1003687: `Print Temu Middle Label`,
  key1003688: `See the manifest`,
  key1003689: `Sorting list`,
  key1003690: `Temu Shipping Order`,
  key1003691: `SKU/Name/Variation`,
  key1003692: `Total quantity of items`,
  key1003693: `Number of items sorted`,
  key1003694: `package`,
  key1003695: `All outbound orders under the picking list have been changed`,
  key1003696: `Please scan the correct SKU/UPC`,
  key1003697: `No picking basket available`,
  key1003698: `Multi item stocking sorting - end sorting`,
  key1003699: `Prompt for cancellation of picking list`,
  key1003700: `There is no outbound order in the picking list, and the picking list has been cancelled. Clicking the button will automatically end this operation.`,
  key1003701: `Failed to print`,
  key1003702: `Printing label successfully!`,
  key1003703: `Completed`,
  key1003704: `The quantity that should be shipped`,
  key1003705: `Container quantity of goods`,
  key1003706: `Quantity to be sorted`,
  key1003707: `print label`,
  key1003708: `The current product has no label address`,
  key1003709: `Successfully printed Temu middle label!`,
  key1003710: `Failed to obtain Temu intermediate label address!`,
  key1003711: `Unable to obtain the address of the Temu middle label face sheet`,
  key1003712: `Picking review-end operation`,
  key1003713: `You are about to end the picking review of the pick list {pickingGoodsNo}`,
  key1003714: `A total of {scannedCount} people participated in this picking review`,
  key1003715: `Total packaging time {scannedTime} minutes`,
  key1003716: `Total (person.time) {userScannedTime} minutes`,
  key1003717: `You are about to end the picking review of the pick list {pickingGoodsNo}, but the system detects the following exception`,
  key1003718: `The following package have been scanned but the address label is being printed`,
  key1003719: `Save as Excel`,
  key1003720: `Manually confirm whether the address label has been printed normally.`,
  key1003721: `If you mark it as printed, the parcels will be deemed to have been packed normally when the picking review is mandatorily completed, and no new pick list can be generated for the parcels.`,
  key1003722: `If you mark as unprinted, these packages will be deemed unprinted when the picking review is mandatorily ended, and the system will remove these packages from the pick list, and you can regenerate a new pick list.`,
  key1003723: `The following packages have not been printed or the package was invalidated before printing.`,
  key1003724: `Unprinted packages will be removed from the pick list when the picking review is forced to end, and a new pick list will be generated again.`,
  key1003725: `If the package is voided during the packing of the picking order, there may be a surplus of the physical item.`,
  key1003726: `Under normal circumstances, the total quantity of this item should be equal the actual quantity your picked.`,
  key1003727: `The following packages are void after printing.`,
  key1003728: `After the address label is printed, the package is voided，It will cause the package to be packed normally. It is recommended to intercept it immediately，And in the[Recycle package]For confirmation。`,
  key1003729: `End current job`,
  key1003730: `Return to continue packaging`,
  key1003731: `Scanned quantity`,
  key1003732: `Unpicked quantity`,
  key1003733: `Packaged packages number`,
  key1003734: `Packaged items number`,
  key1003735: `Statistics project`,
  key1003736: `Picking order number`,
  key1003737: `Actual completion number`,
  key1003738: `Operator`,
  key1003739: `Total number of packages`,
  key1003740: `Working time (minutes)`,
  key1003741: `Packages (per minute)`,
  key1003742: `Items (per minute)`,
  key1003743: `Addressee`,
  key1003744: `Scanning time`,
  key1003745: `Printed`,
  key1003746: `unPrinted`,
  key1003747: `Subtotal of items`,
  key1003748: `Scan SKU`,
  key1003749: `Load package information`,
  key1003750: `Checking quantity`,
  key1003751: `Send to printer`,
  key1003752: `Mark printed`,
  key1003753: `SKU error`,
  key1003754: `The SKU [ {warnSku} ] that you scanned or entered was not found in the current picking order`,
  key1003755: `未匹配到指定`,
  key1003756: `Current picking review pick list:`,
  key1003757: `Current operator:`,
  key1003758: `Exit current job`,
  key1003759: `Automatically match packages:`,
  key1003760: `Clear scanned items and rescan`,
  key1003761: `Outbound order for picking order`,
  key1003762: `You need to process the currently unprinted package first.`,
  key1003763: `As a result of the scan you submitted, the package information could not be found in the current pick list. Possible reasons:`,
  key1003764: `*There is an error in the products of the current package.`,
  key1003765: `*There are omissions or duplications in your scanning process.`,
  key1003766: `The picking order does not exist or the status has changed. Please refresh and try again.`,
  key1003767: `The outbound order scanned by the picking review does not exist`,
  key1003768: `Picking review and scanning outbound order is invalid`,
  key1003769: `SKU does not match the appropriate outbound order.`,
  key1003770: `Picking review is over`,
  key1003771: `There are delivery orders with incomplete packaging`,
  key1003772: `waiting for operation`,
  key1003773: `Carrier / shipping method`,
  key1003774: `SKU & quantity`,
  key1003775: `Print product labels`,
  key1003776: `Failed to obtain barcode for fully managed goods on AliExpress!`,
  key1003777: `Name/Specification`,
  key1003778: `Check`,
  key1003779: `The current SKU corresponding order is a AliExpress fully managed JIT order, and the corresponding product barcode needs to be printed. Please note that the paper size needs to be 60mm * 30mm.`,
  key1003780: `Print successfully`,
  key1003781: `Print Temu barcode`,
  key1003782: `Current outbound order:`,
  key1003783: `Quantity of goods loaded:`,
  key1003784: `Complete the entire order`,
  key1003785: `Sorting containers and corresponding outbound orders`,
  key1003786: `Name/Specification`,
  key1003787: `Scanned quantity cannot be empty!`,
  key1003788: `Scanned/Unscanned`,
  key1003789: `Already Screened`,
  key1003790: `Unscanned`,
  key1003791: `Print Temu labels`,
  key1003792: `(Partial packaging)`,
  key1003793: `(Packaged)`,
  key1003794: `(Completed)`,
  key1003795: `Exceeding the quantity on the picking list, please check the actual quantity of goods!`,
  key1003796: `SKU is not a required item for this order. Please confirm the order to which the item belongs!`,
  key1003797: `SKU cannot be empty!`,
  key1003798: `There are unfinished delivery orders. Please mark them as completed before ending the operation.`,
  key1003799: `All goods have been scanned, please end the assignment`,
  key1003800: `Exceeding the quantity on the picking list, please check the actual quantity of goods`,
  key1003801: `This delivery order has been marked as completed, please do not repeat the marking!`,
  key1003802: `After the entire order editing is completed, no changes can be made. Please confirm that there are no errors before marking.`,
  key1003803: `Mark completion`,
  key1003804: `Abnormal completion prompt`,
  key1003805: `At the end of the current outbound order, the packaging operation will be carried out. After the marking is completed, the status will be transferred to the picking list to be generated. Please confirm that there are no errors before marking.`,
  key1003806: `Please mark the completion of the entire order after completing the small package.`,
  key1003807: `You are about to end the picking list {{pickingGoodsNo}} But the system detected the following exception`,
  key1003808: `The following packages have not been sorted`,
  key1003809: `1.The product quantity are less than the quantity required by the pick list.`,
  key1003810: `2. In the previous sorting process, too many products were placed in a pick basket.`,
  key1003811: `3.The remaining products in the awaiting sorting area that have not been scanned.`,
  key1003812: `Under the condition that the quantity of goods to be picked is accurate, the quantity of the goods to be picked should be consistent with the quantity of the remaining goods in the waiting area。`,
  key1003813: `If you feel that there are too many mistakes to trace back，You can put all the items in the picking box back to the waiting area，`,
  key1003814: `and`,
  key1003815: `Cancel the sorting`,
  key1003816: `Picking list can start sorting operation again。`,
  key1003817: `Return to continue sorting`,
  key1003818: `Picking box`,
  key1003819: `Unassigned`,
  key1003820: `Goods (picked/to be picked)`,
  key1003821: `Error reason`,
  key1003822: `Have not started sorting yet`,
  key1003823: `Sorting not completed`,
  key1003824: `Picked`,
  key1003825: `Unpicked`,
  key1003826: `Prompt for abnormal quantity of goods`,
  key1003827: `In this assignment, the following SKUs have not been scanned yet. Please double check the quantity of goods. If there is any loss of goods, please contact the management personnel in a timely manner.`,
  key1003828: `Return to Job`,
  key1003829: `Quantity to be scanned`,
  key1003830: `Actual scanning quantity`,
  key1003831: `Print product barcode`,
  key1003832: `The quantity of items that should be shipped`,
  key1003833: `The quantity of items in Picking box`,
  key1003834: `Failed to obtain printing label address!`,
  key1003835: `Reset successful!`,
  key1003836: `Multi item conventional sorting - end sorting`,
  key1003837: `Failed to obtain product barcode!`,
  key1003838: `(Temu sample order)`,
  key1003839: `(Temu goods order)`,
  key1003840: `No picking order details exist`,
  key1003841: `Print SKU`,
  key1003842: `Delete`,
  key1003843: `Do not close automatically`,
  key1003844: `Download print control`,
  key1003845: `The barcode was not generated successfully. Please click again to print.`,
  key1003846: `Show more`,
  key1003847: `Longer than input limit`,
  key1003848: `Add notes successfully!`,
  key1003849: `Failed to add notes!`,
  key1003850: `Failed to add`,
  key1003851: `SKU，支持多个按行分隔`,
  key1003852: `Export outbound details`,
  key1003853: `订单号，支持多个按行分隔`,
  key1003854: `Please enter the distributor merchant account, which supports multiple separate lines`,
  key1003855: `Please enter the distributor order number, which supports multiple separate lines`,
  key1003856: `Please enter the waybill number, which supports multiple separate lines`,
  key1003857: `Outbound person`,
  key1003858: `Generate Picker`,
  key1003859: `Logistics Sorter`,
  key1003860: `Shipment quantity (pieces)`,
  key1003861: `Allocate inventory time`,
  key1003862: `Generate picking list time`,
  key1003863: `Completion of picking time`,
  key1003864: `Sort completion time`,
  key1003865: `Packaging completion time`,
  key1003866: `Time to complete logistics sorting`,
  key1003867: `Outbound time`,
  key1003868: `Logistics carrier waybill number`,
  key1003869: `Quantity of shipments`,
  key1003870: `Operator`,
  key1003871: `Activity status:`,
  key1003872: `Activity Name:`,
  key1003873: `Please enter the activity name keyword`,
  key1003874: `New Activity`,
  key1003875: `Not Started`,
  key1003876: `In progress`,
  key1003877: `finished`,
  key1003878: `Active picture`,
  key1003879: `Activity Name`,
  key1003880: `Activity status`,
  key1003881: `Activity start time`,
  key1003882: `Event End Time`,
  key1003883: `Long term effectiveness`,
  key1003884: `Start Now`,
  key1003885: `Start activity confirmation immediately`,
  key1003886: `After confirmation, the activity will immediately start and be displayed on the mall. Please confirm whether to start?`,
  key1003887: `End Now`,
  key1003888: `Immediately End Activity Confirmation`,
  key1003889: `After confirmation, the activity will immediately end. Please confirm if it ends?`,
  key1003890: `Please enter the entrance name`,
  key1003891: `Image:`,
  key1003892: `Click to jump to:`,
  key1003893: `replace`,
  key1003894: `Renderings`,
  key1003895: `Jingang District`,
  key1003896: `Upload images`,
  key1003897: `(Recommended size: 60 * 60)`,
  key1003898: `Not filled in`,
  key1003899: `Effect Preview`,
  key1003900: `entrance`,
  key1003901: `The image of Jingang District is empty. Please upload and try again.`,
  key1003902: `Name cannot be empty!`,
  key1003903: `Please upload first`,
  key1003904: `Picture of!`,
  key1003905: `The click to redirect address cannot be empty!`,
  key1003906: `Image successful`,
  key1003907: `Image failed`,
  key1003908: `Click to jump`,
  key1003909: `Jump Target Type:`,
  key1003910: `Jump target:`,
  key1003911: `activity`,
  key1003912: `Specify Link`,
  key1003913: `Do not jump`,
  key1003914: `Jump target type cannot be empty`,
  key1003915: `The specified link address must be a link starting with https://or http://!`,
  key1003916: `Jump target cannot be empty!`,
  key1003917: `请输入站点名称`,
  key1003918: `站点域名：`,
  key1003919: `请输入站点域名`,
  key1003920: `站点说明：`,
  key1003921: `请输入站点说明`,
  key1003922: `站点名称`,
  key1003923: `站点域名`,
  key1003924: `站点说明`,
  key1003925: `查看站点信息`,
  key1003926: `编辑站点信息`,
  key1003927: `Banner image:`,
  key1003928: `Homepage rotation chart`,
  key1003929: `Top advertising space`,
  key1003930: `Side advertising space`,
  key1003931: `Home page - advertising space below personal information`,
  key1003932: `Change Enable Status Prompt`,
  key1003933: `This operation will change whether the advertising space is visible on the mall end. Please confirm whether to continue with the change.`,
  key1003934: `(Recommended size: 70 * 228)`,
  key1003935: `The advertising image is empty. Please upload and try again.`,
  key1003936: `Clicking on the redirect address cannot be empty!`,
  key1003937: `(Recommended height: 80px)`,
  key1003938: `The banner image is empty. Please upload and try again.`,
  key1003939: `(Recommended size: 228 * 70)`,
  key1003940: `Unified pricing（`,
  key1003941: `Add as homepage tab:`,
  key1003942: `Is supplier information displayed`,
  key1003943: `Event start time:`,
  key1003944: `Select activity start time`,
  key1003945: `Event End Time:`,
  key1003946: `Select activity end time`,
  key1003947: `Is the price set uniformly`,
  key1003948: `Configure matching rules`,
  key1003949: `Matching rules:`,
  key1003950: `New Days:`,
  key1003951: `Remove Product Confirmation`,
  key1003952: `Event Information`,
  key1003953: `(Recommended size: 1200 * 160)`,
  key1003954: `Event Pricing`,
  key1003955: `Activity products`,
  key1003956: `Designated product`,
  key1003957: `Rule matching`,
  key1003958: `Configuration Rules`,
  key1003959: `Batch removal`,
  key1003960: `Rule description:`,
  key1003961: `1. The system will automatically match products that meet a certain number of new days. For example, if you fill in 7 days, the system will match products that are within 7 days of today according to the approved time and add them to the activity.`,
  key1003962: `After removal, the product will no longer participate in this event, and the price may change. The system will remind the subscribed distributors.`,
  key1003963: `Remove products`,
  key1003964: `The start time of the activity cannot be empty`,
  key1003965: `Match by new days`,
  key1003966: `Matching rule cannot be empty`,
  key1003967: `The number of new days cannot be empty`,
  key1003968: `Slogan:`,
  key1003969: `Product activities`,
  key1003970: `The end time of the activity cannot be empty`,
  key1003971: `Please check the products participating in the activity first!`,
  key1003972: `Please configure matching rules first!`,
  key1003973: `Unified pricing cannot be empty!`,
  key1003974: `The end time of the activity cannot be less than the start time of the activity!`,
  key1003975: `Cost allocation`,
  key1003976: `Shipping warehouse:`,
  key1003977: `Single piece processing fee:`,
  key1003978: `Renewal processing fee:`,
  key1003979: `Warehouse processing fee=`,
  key1003980: `(Number of pieces -1)`,
  key1003981: `The shipping warehouse cannot be empty`,
  key1003982: `Single item processing fee cannot be empty`,
  key1003983: `Renewal processing fee cannot be empty`,
  key1003984: `(Recommended size: 644 * 324)`,
  key1003985: `Delete Poll Entry`,
  key1003986: `+Add a rotation item`,
  key1003987: `Rotation item`,
  key1003988: `The rotation chart is empty. Please upload and try again.`,
  key1003989: `Rotation item 1`,
  key1003990: `Create subsidy rules`,
  key1003991: `Subsidy Rule Name`,
  key1003992: `Rule Status`,
  key1003993: `Add Subrule`,
  key1003994: `Freight subsidy`,
  key1003995: `Add fee configuration`,
  key1003996: `Order Continuation`,
  key1003997: `piece`,
  key1003998: `Shipping warehouse`,
  key1003999: `Fee rules`,
  key1004000: `Subsidy recipients:`,
  key1004001: `Subsidy type:`,
  key1004002: `Select time`,
  key1004003: `Multiple merchant accounts should be separated by commas or line breaks in English`,
  key1004004: `condition`,
  key1004005: `This name is visible to the customer, please name it reasonably`,
  key1004006: `Subsidy conditions:`,
  key1004007: `Add conditions`,
  key1004008: `Subsidy amount:`,
  key1004009: `The subrule subsidy will be superimposed with the main rule, please configure it reasonably`,
  key1004010: `Single bill of lading subsidy:`,
  key1004011: `0-1 kg subsidy:`,
  key1004012: `Subsidy per kilogram of continued weight:`,
  key1004013: `Maximum subsidy for single bill of lading:`,
  key1004014: `Subsidy object cannot be empty`,
  key1004015: `Subsidy type cannot be empty`,
  key1004016: `Create sub rules`,
  key1004017: `Edit subsidy rules`,
  key1004018: `Edit Subrules`,
  key1004019: `The subsidy rules are not complete. Please fill them out completely and try again.`,
  key1004020: `The quantity of goods in the bill of lading must be an integer greater than or equal to 0`,
  key1004021: `The order delivery rate must be greater than or equal to 0`,
  key1004022: `The subsidy amount is not complete. Please fill it out completely and try again.`,
  key1004023: `Enter Merchant ID`,
  key1004024: `Server selection`,
  key1004025: `Search:`,
  key1004026: `Yunmai Publishing`,
  key1004027: `Successfully settled`,
  key1004028: `More Roles`,
  key1004029: `Enter employee's name, mailbox, mobile phone number`,
  key1004030: `Account status:`,
  key1004031: `Manage staff roles`,
  key1004032: `Add new staff`,
  key1004033: `By Name`,
  key1004034: `By Email`,
  key1004035: `Last Login Time`,
  key1004036: `Do you want to delete the current role?`,
  key1004037: `Role Name:`,
  key1004038: `Input Role Name`,
  key1004039: `Assembly and processing-`,
  key1004040: `Picking-`,
  key1004041: `Module`,
  key1004042: `Add Roles`,
  key1004043: `Role name cannot be empty`,
  key1004044: `Edit Role`,
  key1004045: `Basic Information`,
  key1004046: `Role`,
  key1004047: `Data permission`,
  key1004048: `Warehouse authority`,
  key1004049: `All data permissions in the warehouse`,
  key1004050: `Access to customer data`,
  key1004051: `All data permissions`,
  key1004052: `New employee`,
  key1004053: `E-mail can not be empty`,
  key1004054: `View employees`,
  key1004055: `Edit Employee`,
  key1004056: `Rule name:`,
  key1004057: `Actual shipping method:`,
  key1004058: `Account:`,
  key1004059: `choose assigned logistics`,
  key1004060: `choose suitable logistics`,
  key1004061: `Deactivated, please modify and save again`,
  key1004062: `logistics setup`,
  key1004063: `time of arrival：less than`,
  key1004064: `Null`,
  key1004065: `have`,
  key1004066: `Please enter rule name.`,
  key1004067: `Please add a shipping method.`,
  key1004068: `At least choose one of delivery time or waybill number`,
  key1004069: `The real shipping method cannot be null.`,
  key1004070: `The account cannot be null.`,
  key1004071: `Add logistics`,
  key1004072: `Choose logistics:`,
  key1004073: `Please select a custom logistics type:`,
  key1004074: `Custom logistics provider code:`,
  key1004075: `Custom logistics provider name:`,
  key1004076: `API docking`,
  key1004077: `Type cannot be null.`,
  key1004078: `Custom logistics provider code cannot be empty`,
  key1004079: `The name of the custom logistics provider cannot be empty`,
  key1004080: `Data docking is not required.`,
  key1004081: `Excel docking`,
  key1004082: `Input Name Search`,
  key1004083: `Logistics channel name/code:`,
  key1004084: `Logistics mode not enabled`,
  key1004085: `Add custom logistics`,
  key1004086: `Logistics Plan Code`,
  key1004087: `Designated code of receiver:`,
  key1004088: `Logistics timeliness`,
  key1004089: `Sending address`,
  key1004090: `Collect address`,
  key1004091: `Return address`,
  key1004092: `Please check the logistics mode to be enabled.`,
  key1004093: `Linked logistics`,
  key1004094: `Logistics channel Name:`,
  key1004095: `Add association`,
  key1004096: `物流仅支持绑定一条渠道！`,
  key1004097: `(Day)`,
  key1004098: `Are you sure you want to unlink?`,
  key1004099: `Unlink`,
  key1004100: `Please add the logistics first!`,
  key1004101: `Added successfully!`,
  key1004102: `Please choose a complete logistics method first!`,
  key1004103: `Edit custom logistics`,
  key1004104: `Delivery method cannot be empty`,
  key1004105: `Collection time cannot be empty`,
  key1004106: `Delivery method:`,
  key1004107: `Domestic express delivery fee:`,
  key1004108: `Carrier:`,
  key1004109: `Shipping country:`,
  key1004110: `Collection method:`,
  key1004111: `Shipping method:`,
  key1004112: `Surface single template`,
  key1004113: `Waybill size`,
  key1004114: `Notes:`,
  key1004115: `Global accessibility (same freight)`,
  key1004116: `Global accessibility (freight difference)`,
  key1004117: `Non-global accessibility`,
  key1004118: `Freight discount:`,
  key1004119: `Passthrough:`,
  key1004120: `Basic information of channels`,
  key1004121: `type of custom logistics:`,
  key1004122: `no docking`,
  key1004123: `Logistics solution code:`,
  key1004124: `Timeliness for reference:`,
  key1004125: `Insurance service:`,
  key1004126: `Size limit(cm):`,
  key1004127: `Applicable weight`,
  key1004128: `Perimeter limit(cm):`,
  key1004129: `The limitation of the longest side (cm):`,
  key1004130: `Volume limit(cm³):`,
  key1004131: `URL of tracking information:`,
  key1004132: `Collection point address information`,
  key1004133: `Logistics provider optional settings (default settings)`,
  key1004134: `Distribution scope and freight`,
  key1004135: `Delivery scope`,
  key1004136: `Billing model`,
  key1004137: `Quote from other channels`,
  key1004138: `Bulk import`,
  key1004139: `Add partition`,
  key1004140: `Freight Currency:`,
  key1004141: `Freight discount`,
  key1004142: `Pass through`,
  key1004143: `Please note that when there is no discount, please enter 1, 20% discount, please enter 0.8.`,
  key1004144: `Applicable warehouse`,
  key1004145: `All self-owned warehouses`,
  key1004146: `Platform standard delivery settings`,
  key1004147: `No-fill-in bill number`,
  key1004148: `(default)`,
  key1004149: `Adding exceptional countries`,
  key1004150: `Side sheet printing settings`,
  key1004151: `Print distribution list`,
  key1004152: `Standard quotation`,
  key1004153: `Operating fee (yuan/ticket)`,
  key1004154: `Priority (g)`,
  key1004155: `First heavy freight`,
  key1004156: `Continuous weight unit weight (g)`,
  key1004157: `Continuous unit price (yuan)`,
  key1004158: `特例国家/地区不能为空`,
  key1004159: `物流服务商(Carrier)不能为空`,
  key1004160: `Partition`,
  key1004161: `Default partition`,
  key1004162: `Accessible country`,
  key1004163: `All countries`,
  key1004164: `Billing mode`,
  key1004165: `Weighting method`,
  key1004166: `Accounting formula`,
  key1004167: `manually synchronize`,
  key1004168: `synchronize after successfully creating logistics order`,
  key1004169: `synchronize after acquiring waybill number`,
  key1004170: `synchronize after successfully shipping out`,
  key1004171: `Discount price`,
  key1004172: `return address `,
  key1004173: `Address of recipient`,
  key1004174: `Return recipient address`,
  key1004175: `Delivered by buyer`,
  key1004176: `Domestic express delivery fee cannot be empty`,
  key1004177: `Logistics channel code cannot be null.`,
  key1004178: `The default out-of-zone billing model country cannot be empty`,
  key1004179: `Incompatible or incomplete parameters of piecewise billing table`,
  key1004180: `Edit logistics channels`,
  key1004181: `View logistics channels`,
  key1004182: `Add logistics channels`,
  key1004183: `First priority`,
  key1004184: `Yuan`,
  key1004185: `Continuous weight`,
  key1004186: `不能添加相同名称的物流商服务！`,
  key1004187: `designated order source by rule`,
  key1004188: `/The designated shipping country/region by rules include`,
  key1004189: `Designated shipping method by rule`,
  key1004190: `Scope of application`,
  key1004191: `Applicable platform:`,
  key1004192: `Applicable stores:`,
  key1004193: `Applicable package amount ($):`,
  key1004194: `Range of Shipping fee (buyer pay) ($):`,
  key1004195: `Included SKU:`,
  key1004196: `Support multiple SKUs, separated by commas or spaces`,
  key1004197: `Range of total quantity of products:`,
  key1004198: `lowest freight`,
  key1004199: `fastest delivery time`,
  key1004200: `lowest upload time`,
  key1004201: `highest probability rate`,
  key1004202: `Explanation of intelligent logistics matching rules`,
  key1004203: `the basic attribute of matching logistics channel`,
  key1004204: `Necessary process: find available logistics provider`,
  key1004205: `The order match  in the basic attribute of logistics channel`,
  key1004206: `1.size limit`,
  key1004207: `2.weight limit`,
  key1004208: `3.type limit`,
  key1004209: `4.delivery area limit`,
  key1004210: `=1，>1matching result=1, over; matching result>1, move on to the next step`,
  key1004211: `5. Logistics channels supported by the shipping warehouse`,
  key1004212: `logistics matching rules`,
  key1004213: `The result set that was successfully matched in the previous step runs logistics matching rules, matches through the rule engine, and matches from top to bottom`,
  key1004214: `Match the scope of application of logistics `,
  key1004215: `Optional process: Range of application`,
  key1004216: `，Users can customize the scope of application of logistics channels to match the scope of application of all channels`,
  key1004217: `freight/timeliness priority`,
  key1004218: `：necessary process: choose the lowest freight or fastest shipping`,
  key1004219: `Calculate the freight/timeliness of all eligible logistics channels, and select the lowest freight or the fastest logistics according to the priority that you have set`,
  key1004220: `Logistics rules`,
  key1004221: `Add logistics rules`,
  key1004222: `Selected shipping method:`,
  key1004223: `The scope of application of logistics channels`,
  key1004224: `More warehouses`,
  key1004225: `Drag`,
  key1004226: `form name`,
  key1004227: `Final Modifier`,
  key1004228: `Logistics vendor/logistics channel`,
  key1004229: `Platform/Account`,
  key1004230: `Product label`,
  key1004231: `Freight area`,
  key1004232: `sku included`,
  key1004233: `Quantity range`,
  key1004234: `All`,
  key1004235: `New rules`,
  key1004236: `物流名称：`,
  key1004237: `Associated logistics channels:`,
  key1004238: `Associated logistics channels`,
  key1004239: `Link`,
  key1004240: `Distributor Logistics`,
  key1004241: `Supplier Logistics`,
  key1004242: `Scope of application:`,
  key1004243: `Applicable weight (g):`,
  key1004244: `Volume limit (cm):`,
  key1004245: `Province/State`,
  key1004246: `Street:`,
  key1004247: `AliTM:`,
  key1004248: `Pickup time:`,
  key1004249: `Proxy`,
  key1004250: `domestic`,
  key1004251: `abroad`,
  key1004252: `Basic information of the plan`,
  key1004253: `重量单位：`,
  key1004254: `Delivery Settings on Express Line`,
  key1004255: `EDIS Logistics Settings`,
  key1004256: `Logistics Settings`,
  key1004257: `Select a shipping address:`,
  key1004258: `Please select a pickup address:`,
  key1004259: `Address:`,
  key1004260: `Select return address:`,
  key1004261: `Shipping mode and package time`,
  key1004262: `Delivery by sellers`,
  key1004263: `Customs clearance fee configuration`,
  key1004264: `Whether to charge customs clearance fees:`,
  key1004265: `collect`,
  key1004266: `No charge`,
  key1004267: `Proportional configuration: declared value in`,
  key1004268: `At a rate of 150 EUR, calculated at the following tax rates`,
  key1004269: `Parcels exceeding 150 euros will be issued with logistics failure.`,
  key1004270: `Unit price of renewal weight`,
  key1004271: `tax rate`,
  key1004272: `The tax rate must be greater than or equal to 0 and a maximum of 2 decimal places are allowed`,
  key1004273: `默认分区外计费模型国家不能为空!`,
  key1004274: `Required fields cannot be empty!`,
  key1004275: `Content cannot be empty`,
  key1004276: `Logistics company name:`,
  key1004277: `是否可用：`,
  key1004278: `Applicable platforms cannot be empty.`,
  key1004279: `Offline logistics`,
  key1004280: `Online logistics`,
  key1004281: `Carrier name`,
  key1004282: `Is the distributor available`,
  key1004283: `Logistics account`,
  key1004284: `Applicable platform`,
  key1004285: `Docking type`,
  key1004286: `Edit account`,
  key1004287: `Modify the scope of application`,
  key1004288: `Is the distributor available:`,
  key1004289: `Account name cannot be empty`,
  key1004290: `Personal name/company name cannot be empty!`,
  key1004291: `Address information cannot be empty!`,
  key1004292: `Account Name:`,
  key1004293: `Authorization status:`,
  key1004294: `Personal Name/Company Name:`,
  key1004295: `Phone:`,
  key1004296: `Address information:`,
  key1004297: `Authorize again`,
  key1004298: `Authorize`,
  key1004299: `Authorized`,
  key1004300: `Unauthorized`,
  key1004301: `EU agent`,
  key1004302: `Failed to obtain the application of authorization callback address!`,
  key1004303: `IOSS used:`,
  key1004304: `Condition type:`,
  key1004305: `Platform:`,
  key1004306: `Shop:`,
  key1004307: `This name is only used to better distinguish different IOSS`,
  key1004308: `By Store`,
  key1004309: `By platform`,
  key1004310: `Please select the IOSS to use`,
  key1004311: `Please choose the shop.`,
  key1004312: `Add Rule`,
  key1004313: `Add IOSS`,
  key1004314: `Declaration instructions:`,
  key1004315: `YMS will declare based on the sales price of the product in YMS, which corresponds to the price of the goods in the YMS order.`,
  key1004316: `View tax rates`,
  key1004317: `When there is an IOSS number in the platform order, YMS will prioritize using the IOSS number returned by the platform. If the IOSS number cannot be matched, the system will intercept this order.`,
  key1004318: `manual order`,
  key1004319: `IOSS management`,
  key1004320: `IOSS rule configuration`,
  key1004321: `IOSS name`,
  key1004322: `IOSS number`,
  key1004323: `Use IOSS name`,
  key1004324: `Use IOSS number`,
  key1004325: `Applicable platforms/stores`,
  key1004326: `Applicable stores`,
  key1004327: `Enable IOSS confirmation`,
  key1004328: `Deactivate IOSS confirmation`,
  key1004329: `After enabling IOSS, relevant rules will be able to be matched. Please confirm whether to enable IOSS?`,
  key1004330: `After deactivating IOSS, the rules using this IOSS number will also be deactivated. Please confirm whether to deactivate IOSS?`,
  key1004331: `Enable IOSS rules`,
  key1004332: `Deactivate IOSS rule`,
  key1004333: `Please confirm whether to enable the rule?`,
  key1004334: `Please confirm whether to deactivate the rule?`,
  key1004335: `Original price`,
  key1004336: `Adjusted price`,
  key1004337: `rate`,
  key1004338: `Verify identidy`,
  key1004339: `Set up a new mailbox`,
  key1004340: `Set successfully`,
  key1004341: `Verification code:`,
  key1004342: `Phone verification code:`,
  key1004343: `Please enter the phone verification code`,
  key1004344: `new mail box:`,
  key1004345: `E-mail verification code:`,
  key1004346: `Please enter the email verification code`,
  key1004347: `Next`,
  key1004348: `mention`,
  key1004349: `cross`,
  key1004350: `Image verification code cannot be empty`,
  key1004351: `Phone number format is incorrect!`,
  key1004352: `The phone number and picture verification code cannot be empty!`,
  key1004353: `E-mail format is incorrect!`,
  key1004354: `E-mail can not be empty!`,
  key1004355: `The verification code has been sent!`,
  key1004356: `Phone number cannot be blank`,
  key1004357: `E-mail format is incorrect.`,
  key1004358: `Phone verification`,
  key1004359: `E-mail verification`,
  key1004360: `login password`,
  key1004361: `Changing passwords regularly helps account security`,
  key1004362: `New date:`,
  key1004363: `New SPU:`,
  key1004364: `Mark all read`,
  key1004365: `Mark read`,
  key1004366: `Read Only Unread`,
  key1004367: `Order exception notification`,
  key1004368: `Notice of discontinuation`,
  key1004369: `Price adjustment notice`,
  key1004370: `New notifications on the store`,
  key1004371: `Automatic Rule Notification`,
  key1004372: `Please check the data to be marked first!`,
  key1004373: `Distributor Name:`,
  key1004374: `currency：`,
  key1004375: `Modify Currency`,
  key1004376: `Modify phone`,
  key1004377: `Phone number:`,
  key1004378: `Modify email`,
  key1004379: `IOSS Name:`,
  key1004380: `IOSS number:`,
  key1004381: `IOSS name cannot be empty`,
  key1004382: `Edit IOSS`,
  key1004383: `IOSS number cannot be empty`,
  key1004384: `Please enter IM+10 digits`,
  key1004385: `old password:`,
  key1004386: `New password:`,
  key1004387: `Confirm the new password again:`,
  key1004388: `The original password cannot be empty`,
  key1004389: `New password cannot be empty`,
  key1004390: `update completed!`,
  key1004391: `The new password is inconsistent with the confirmed new password`,
  key1004392: `Set up a new phone`,
  key1004393: `New phone number:`,
  key1004394: `Please enter a new phone number`,
  key1004395: `The email and picture verification code cannot be empty!`,
  key1004396: `The phone number format is incorrect!`,
  key1004397: `The phone number cannot be empty!`,
  key1004398: `Minimum inventory`,
  key1004399: `Maximum inventory`,
  key1004400: `¥ Lowest price`,
  key1004401: `¥ Maximum price`,
  key1004402: `Price:`,
  key1004403: `One click publishing`,
  key1004404: `Download materials`,
  key1004405: `Comprehensive`,
  key1004406: `sales volume`,
  key1004407: `Last New Time`,
  key1004408: `Total inventory`,
  key1004409: `Please check the items that need to be cancelled as favorites`,
  key1004410: `Cancel collection successful!`,
  key1004411: `Please check the items you want to bookmark`,
  key1004412: `Product collection successful`,
  key1004413: `Please tick the products that need to download data`,
  key1004414: `YMS order`,
  key1004415: `Platform order`,
  key1004416: `Stock list`,
  key1004417: `订单规则`,
  key1004418: `every other`,
  key1004419: `Every minute`,
  key1004420: `Available quantity=`,
  key1004421: `订单超过`,
  key1004422: `小时未装箱时自动取消`,
  key1004423: `Inventory synchronization rules`,
  key1004424: `Product status synchronization rules`,
  key1004425: `Shop`,
  key1004426: `Listings Template `,
  key1004427: `Synchronization time`,
  key1004428: `Enable`,
  key1004429: `Inventory synchronization`,
  key1004430: `Product status synchronization`,
  key1004431: `Price synchronization`,
  key1004432: `Supplier warehouse`,
  key1004433: `Automatically adjust inventory to 0 when the product is discontinued`,
  key1004434: `Automatically synchronize the status to the listing tool when the product is discontinued`,
  key1004435: `After deletion, it will not be recoverable. Are you sure you want to delete it?`,
  key1004436: `Please select the applicable platform`,
  key1004437: `This rule applies to automatic approval when there is a change in product price`,
  key1004438: `Adjusting prices`,
  key1004439: `Please select an applicable store`,
  key1004440: `Add price synchronization rule`,
  key1004441: `Modify price synchronization rules`,
  key1004442: `Price synchronization rules`,
  key1004443: `It may be because the inventory quantity base is small, causing the inventory to be zero after floating. It may also be because the inventory base is large, and there is no change in the inventory after floating compared to before floating. Please set the floating ratio carefully.`,
  key1004444: `The formula calculates the result to be rounded, for example: the calculated result is 9.5, and the actual value is 10.`,
  key1004445: `Float Down:`,
  key1004446: `Synchronization time:`,
  key1004447: `Please select warehouse.`,
  key1004448: `Add inventory rules`,
  key1004449: `Edit inventory rules`,
  key1004450: `Platforms`,
  key1004451: `订单超时不能为空`,
  key1004452: `订单同步规则`,
  key1004453: `This rule applies to the automatic adjustment of inventory to zero and synchronization with the status of the listed tools when the product is discontinued.`,
  key1004454: `When this option is checked in any rule, the product's suspension status will be synchronized to the listing tool.`,
  key1004455: `Add product status synchronization rule`,
  key1004456: `Modify product status synchronization rules`,
  key1004457: `Change type`,
  key1004458: `Purchase arrival`,
  key1004459: `Allocate inventory`,
  key1004460: `Stock taking`,
  key1004461: `Change time`,
  key1004462: `Temu Store:`,
  key1004463: `Upload Status`,
  key1004464: `Uploaded`,
  key1004465: `Not uploaded`,
  key1004466: `Re upload`,
  key1004467: `The upload file format is incorrect. Please upload a barcode PDF file or PNG image`,
  key1004468: `Publish Store`,
  key1004469: `Listing status:`,
  key1004470: `Publication platform:`,
  key1004471: `Published`,
  key1004472: `Not published`,
  key1004473: `Number of published items`,
  key1004474: `Publication status`,
  key1004475: `Favorite status:`,
  key1004476: `Collection time:`,
  key1004477: `Sales Information:`,
  key1004478: `Inventory:`,
  key1004479: `please enter an integer`,
  key1004480: `Export product information`,
  key1004481: `Generate sales information prompt`,
  key1004482: `Batch generation of sales materials`,
  key1004483: `Batch export of product information`,
  key1004484: `Ignore generated`,
  key1004485: `Generate`,
  key1004486: `SKUs waiting to be uploaded  n`,
  key1004487: `Checked`,
  key1004488: `There are items in the list`,
  key1004489: `Sales records have been generated. Please choose whether to regenerate them?`,
  key1004490: `Inventory (piece)`,
  key1004491: `Enjoy`,
  key1004492: `fracture`,
  key1004493: `reduce`,
  key1004494: `More than pieces`,
  key1004495: `Discount price:`,
  key1004496: `Discount:`,
  key1004497: `Next stage discounts:`,
  key1004498: `Sales within the past 30 days`,
  key1004499: `On-shelf time`,
  key1004500: `Collection time`,
  key1004501: `Sales Information`,
  key1004502: `Generate sales time`,
  key1004503: `Generate sales information`,
  key1004504: `Cancel Favorite`,
  key1004505: `Republish`,
  key1004506: `Product SPU`,
  key1004507: `Please enter the product SPU, which supports multiple line separations`,
  key1004508: `Product SKU`,
  key1004509: `Please enter the product SKU, which supports multiple line separations`,
  key1004510: `Please enter the product title, which supports multiple line separations`,
  key1004511: `Please enter the supplier name, supporting multiple line separated names`,
  key1004512: `Please enter the supplier number, which supports multiple separate lines`,
  key1004513: `Basic information of the product`,
  key1004514: `Basic Product Information Table`,
  key1004515: `Product Image`,
  key1004516: `Rotation chart`,
  key1004517: `Attribute Graph`,
  key1004518: `Not generated`,
  key1004519: `Already generated`,
  key1004520: `Exceeding the number of single downloads`,
  key1004521: `You can download up to 10 products at a time. Please reselect and download.`,
  key1004522: `Temu label`,
  key1004523: `Sample barcode:`,
  key1004524: `Product barcode:`,
  key1004525: `Store: not uploaded  n`,
  key1004526: `Store: Uploaded\n`,
  key1004527: `Please enter the product SPU, which supports multiple line separations`,
  key1004528: `Product title:`,
  key1004529: `Initiated generation time:`,
  key1004530: `Originator:`,
  key1004531: `surplus`,
  key1004532: `Expected`,
  key1004533: `Generate completed in minutes`,
  key1004534: `Batch stop generating sales information`,
  key1004535: `Generating`,
  key1004536: `Generated successfully`,
  key1004537: `Generation failed`,
  key1004538: `Generate Status`,
  key1004539: `To be generated`,
  key1004540: `Initiate generation of sales time`,
  key1004541: `Failure reason`,
  key1004542: `Initiator`,
  key1004543: `Stop generating`,
  key1004544: `Only the status to be generated can stop generating sales data`,
  key1004545: `Please check the data you want to stop generating first!`,
  key1004546: `Stop generating sales information confirmation`,
  key1004547: `We are currently working to stop generating sales data. Once confirmed, all tasks that have not been generated will be cancelled. If you need to regenerate them, you will need to redo the operation in your favorite products. Please confirm if you want to stop generating them?`,
  key1004548: `Inventory priority configuration`,
  key1004549: `Use inventory priority`,
  key1004550: `Whether to wait for in transit inventory`,
  key1004551: `Prioritize the use of my own inventory with ownership rights`,
  key1004552: `Prioritize the use of other inventory in the YMS warehouse`,
  key1004553: `wait for`,
  key1004554: `Don't wait`,
  key1004555: `SKU inventory range:`,
  key1004556: `Export inventory`,
  key1004557: `On the 7th:`,
  key1004558: `On the 14th:`,
  key1004559: `Recent sales`,
  key1004560: `My available inventory`,
  key1004561: `My in transit inventory`,
  key1004562: `Average cost of goods`,
  key1004563: `The starting range of SKU inventory cannot be greater than the ending range of SKU inventory`,
  key1004564: `选择时长`,
  key1004565: `店铺时长`,
  key1004566: `价格CNY`,
  key1004567: `1 month`,
  key1004568: `3 months`,
  key1004569: `6 months`,
  key1004570: `12个月`,
  key1004571: `开通店铺`,
  key1004572: `续费确认`,
  key1004573: `确认续费`,
  key1004574: `正在充值中...`,
  key1004575: `开通成功`,
  key1004576: `续费成功`,
  key1004577: `开通失败`,
  key1004578: `续费失败`,
  key1004579: `Please enter the code and account name`,
  key1004580: `If you need eDIS to ship without adding a store, please bind the eBay ID to eDIS before adding a store.`,
  key1004581: `When a store has been added, if eDIS shipment is required, please bind eDIS to eBay ID before re authorizing the store.`,
  key1004582: `Authorization status`,
  key1004583: `Go to Authorization`,
  key1004584: `First authorization time`,
  key1004585: `Authorization update time`,
  key1004586: `Thoroughfare`,
  key1004587: `Expiration time`,
  key1004588: `永久有效`,
  key1004589: `未开通`,
  key1004590: `已过期`,
  key1004591: `开通`,
  key1004592: `续费`,
  key1004593: `Authorization successful!`,
  key1004594: `Successfully added account!`,
  key1004595: `Role description:`,
  key1004596: `Please enter a role description`,
  key1004597: `Permission allocation`,
  key1004598: `New Role`,
  key1004599: `View Role`,
  key1004600: `Add Role`,
  key1004601: `Role Name`,
  key1004602: `Role Description`,
  key1004603: `Number of users (enabled)`,
  key1004604: `When a user is already associated with a role, the role cannot be deleted.`,
  key1004605: `Are you sure you want to delete this role?`,
  key1004606: `After deleting this role, it will not be recoverable. Are you sure you want to delete it?`,
  key1004607: `Successfully deleted the role!`,
  key1004608: `View Users`,
  key1004609: `account type`,
  key1004610: `Main account`,
  key1004611: `Sub account`,
  key1004612: `Account Role`,
  key1004613: `Please input the email address.`,
  key1004614: `Generate password`,
  key1004615: `Shop permissions`,
  key1004616: `Add User`,
  key1004617: `Edit User`,
  key1004618: `Successfully added, failed to update user role authorization for the shelved tool!`,
  key1004619: `Please enter your phone number, email, or name`,
  key1004620: `Change password`,
  key1004621: `After changing the password, the original password will no longer be usable. Please modify it with caution!`,
  key1004622: `Please enter the supplier name`,
  key1004623: `Number of fans:`,
  key1004624: `enter a shop`,
  key1004625: `Are you sure to unfollow?`,
  key1004626: `Refund of goods cost`,
  key1004627: `Refund for package handling`,
  key1004628: `Logistics refund`,
  key1004629: `Domestic express refund`,
  key1004630: `Cost of goods`,
  key1004631: `Event description`,
  key1004632: `Your current available balance is`,
  key1004633: `Continue payment`,
  key1004634: `The payment is successful and the account is being credited`,
  key1004635: `Third party voucher number:`,
  key1004636: `Export expense details`,
  key1004637: `Deduction`,
  key1004638: `refund`,
  key1004639: `Platform order currency`,
  key1004640: `Product quantity (pieces)`,
  key1004641: `Logistics weight`,
  key1004642: `SKU sales price`,
  key1004643: `SKU discounted price`,
  key1004644: `Please enter the platform order number`,
  key1004645: `Recharge your balance`,
  key1004646: `Recharge amount:`,
  key1004647: `Payment method:`,
  key1004648: `Other amounts`,
  key1004649: `Current exchange rate: 1`,
  key1004650: `The account currency is`,
  key1004651: `The recharge amount cannot exceed 100,000,000`,
  key1004652: `The recharge amount cannot be less than or equal to 0`,
  key1004653: `（已取消）`,
  key1004654: `Stock up to`,
  key1004655: `取消金额：`,
  key1004656: `Refund amount:`,
  key1004657: `Order Item`,
  key1004658: `item pricing `,
  key1004659: `Actual paid unit price`,
  key1004660: `Order quantity`,
  key1004661: `Received quantity`,
  key1004662: `部分货品未到仓，未到仓部分已退款。`,
  key1004663: `用户取消货品数量`,
  key1004664: `Total weight of goods (g)`,
  key1004665: `货品总值`,
  key1004666: `Paid`,
  key1004667: `Supplier has shipped`,
  key1004668: `Awaiting payment`,
  key1004669: `To be prepared`,
  key1004670: `未完结`,
  key1004671: `Please select a Temu store`,
  key1004672: `Please fill in the Temu order number`,
  key1004673: `You haven't added any products yet`,
  key1004674: `Processing fee=3+(quantity of goods -1) * 0.5`,
  key1004675: `The total amount payable will be withheld when submitting the order, please ensure that the account has sufficient balance.`,
  key1004676: `Collapse Address`,
  key1004677: `More addresses`,
  key1004678: `Shipping Order`,
  key1004679: `Sample order`,
  key1004680: `Delete product`,
  key1004681: `Please note:`,
  key1004682: `1. For sample orders, if multiple SKUs need to be shipped under one SPU, please ensure that the SKUs are placed within one order for order placement.`,
  key1004683: `2. For shipping orders, please make sure to place the order according to the TEMU backend stock order. Multiple stock orders cannot be placed under the same YMS order.`,
  key1004684: `Product quantity:`,
  key1004685: `Total weight of goods (g):`,
  key1004686: `Total amount of goods:`,
  key1004687: `shipping address`,
  key1004688: `Set as default address`,
  key1004689: `Add shipping address`,
  key1004690: `Amount payable:`,
  key1004691: `Quantity (piece)`,
  key1004692: `Subtotal`,
  key1004693: `Temu store cannot be empty`,
  key1004694: `Temu order number cannot be empty`,
  key1004695: `Please check the items to be removed first!`,
  key1004696: `Edit shipping address`,
  key1004697: `Do you want to delete the current shipping address?`,
  key1004698: `Cancel prompt`,
  key1004699: `After canceling, the already filled content will not be saved. Are you sure you want to cancel?`,
  key1004700: `Continue filling in`,
  key1004701: `The order information is incomplete. Please complete the order information before submitting!`,
  key1004702: `Insufficient account balance`,
  key1004703: `Your account balance is insufficient. Please recharge enough amount before submitting the order!`,
  key1004704: `To recharge`,
  key1004705: `Delivery method`,
  key1004706: `Freight:`,
  key1004707: `Modify delivery method`,
  key1004708: `Choose delivery method`,
  key1004709: `Using IOSS:`,
  key1004710: `Go to configuration`,
  key1004711: `Additional fees will be incurred during logistics payment, and YMS will collect them on behalf of you.`,
  key1004712: `This order will be subject to additional charges`,
  key1004713: `Please select logistics first`,
  key1004714: `仓库存`,
  key1004715: `The current shipping address does not support delivery!`,
  key1004716: `Please select the IOSS to use!`,
  key1004717: `Please enter the IOSS number!`,
  key1004718: `Please enter IM+10 digits!`,
  key1004719: `Insufficient distributor balance`,
  key1004720: `Enter IOSS`,
  key1004721: `Matching logistics:`,
  key1004722: `After changing the logistics, the customs clearance fee will be recalculated, and the originally charged fee will be refunded to this order`,
  key1004723: `Customs clearance fees`,
  key1004724: `Match logistics`,
  key1004725: `assign warehouse and logistics`,
  key1004726: `修改物流地址`,
  key1004727: `买家修改地址，修改前后信息如下，请确认是否修改阿里国际订单地址？`,
  key1004728: `原地址：`,
  key1004729: `receiver's name`,
  key1004730: `Contact information`,
  key1004731: `新地址：`,
  key1004732: `Reason for failure:`,
  key1004733: `地址更新失败提示`,
  key1004734: `标记已处理`,
  key1004735: `重试`,
  key1004736: `Logistics channel name`,
  key1004737: `Please check the data for the delivery method first!`,
  key1004738: `Not within the weight range!`,
  key1004739: `订单原因，此原因仅`,
  key1004740: `可见。`,
  key1004741: `Cancel scope:`,
  key1004742: `Cancel product:`,
  key1004743: `请注意，部分取消后此订单将被作废，将会生成新的YMS订单!`,
  key1004744: `当前`,
  key1004745: `订单中暂无商品`,
  key1004746: `取消订单后，订单不再发货，已装箱订单取消需收取手续费，取消后本单将收取`,
  key1004747: `费用，确定取消订单?`,
  key1004748: `Please choose to cancel the scope`,
  key1004749: `Whole order cancellation`,
  key1004750: `Cancel some products`,
  key1004751: `When canceling some products, the cancelled quantity cannot be all empty!`,
  key1004752: `When canceling some products, the total quantity of cancellations cannot be greater than or equal to the total order quantity!`,
  key1004753: `确认取消订单`,
  key1004754: `Order trajectory`,
  key1004755: `揽收中，正送往ymsWarehouseName仓`,
  key1004756: `Order trajectory`,
  key1004757: `Order confirmed, waiting for merchant to pick up`,
  key1004758: `The order has been picked and is awaiting merchant review`,
  key1004759: `The order has been reviewed and completed, waiting for the merchant to package it`,
  key1004760: `Express delivery in transit, currently being sent to ymsWarehouseName, tracking number: expressageNo`,
  key1004761: `YmsWarehouseName has been received and is awaiting warehouse processing`,
  key1004762: `YmsWarehouseName is being stocked`,
  key1004763: `Goods picking completed, waiting for warehouse packaging`,
  key1004764: `The goods are packaged and waiting for the warehouse to pack them`,
  key1004765: `The warehouse has shipped and ShippingMethodName has been notified for logistics pickup`,
  key1004766: `The order has been shipped out and the final logistics trajectory is required. Please check the official logistics website or third-party platform by yourself`,
  key1004767: `Order cancelled`,
  key1004768: `Current delivery to:`,
  key1004769: `Please enter the province/state`,
  key1004770: `Please enter the city`,
  key1004771: `Please enter the district/county`,
  key1004772: `detailed address:`,
  key1004773: `Please enter detailed address information, such as road, house number, community, building number, unit, etc`,
  key1004774: `Please enter your zip code.`,
  key1004775: `Name of consignee:`,
  key1004776: `Not exceeding 25 characters in length`,
  key1004777: `Please enter a fixed phone number`,
  key1004778: `Email address:`,
  key1004779: `Set as default shipping address`,
  key1004780: `Province/State cannot be empty`,
  key1004781: `The recipient name cannot be empty`,
  key1004782: `Successfully added!`,
  key1004783: `Reason for cancellation:`,
  key1004784: `Cancellation type:`,
  key1004785: `Please select the reason for platform order cancellation, which is visible to buyers.`,
  key1004786: `When canceling a platform order, the system will automatically call the platform interface to cancel the order and refund the buyer at the same time!`,
  key1004787: `已装箱订单取消需收取手续费，取消后本单将收取`,
  key1004788: `cost`,
  key1004789: `Please select the cancellation type`,
  key1004790: `Please select the reason for cancellation`,
  key1004791: `Cancel platform order`,
  key1004792: `Platform order transaction number`,
  key1004793: `When canceling some products, canceling all products cannot be checked!`,
  key1004794: `Please check the data that needs to be cancelled first!`,
  key1004795: `There are currently no products in the current platform order`,
  key1004796: `There is an issue with the buyer providing the address`,
  key1004797: `Cancel the order at the buyer's request`,
  key1004798: `Buyer unpaid`,
  key1004799: `Product out of stock or other performance issues`,
  key1004800: `The buyer no longer wants the product`,
  key1004801: `The buyer bought the wrong product`,
  key1004802: `There is a dispute between the buyer and seller regarding this product`,
  key1004803: `Product out of stock`,
  key1004804: `Only defective products remain`,
  key1004805: `Arbitration cancellation`,
  key1004806: `Other reasons`,
  key1004807: `The buyer did not collect the order`,
  key1004808: `Unable to deliver to the region`,
  key1004809: `The order was lost during transportation`,
  key1004810: `Customer requests cancellation`,
  key1004811: `Pricing error`,
  key1004812: `Fraudulent transactions, cancellation of orders`,
  key1004813: `Buyer address not available`,
  key1004814: `Unable to deliver`,
  key1004815: `The item is damaged`,
  key1004816: `Item returned to sender`,
  key1004817: `Payment`,
  key1004818: `由于此订单由阿里国际多商家履约，已自动拆分为不同订单，需按订单进行支付。`,
  key1004819: `payment status`,
  key1004820: `去付款`,
  key1004821: `更新状态`,
  key1004822: `付款确认`,
  key1004823: `是否付款成功？`,
  key1004824: `状态未更新提示`,
  key1004825: `获取到阿里国际订单信息状态仍为未付款，请稍后重试或手动标记为已成功。`,
  key1004826: `Manual marking`,
  key1004827: `Replace order item`,
  key1004828: `Confirm replacement`,
  key1004829: `Please select the product and quantity to be replaced`,
  key1004830: `请注意，部分取消后此订单将被作废，将会生成新的YMS订单！`,
  key1004831: `Quantity to be replaced`,
  key1004832: `Must be less than the order quantity`,
  key1004833: `SKU information in the original order`,
  key1004834: `Replace SKU information`,
  key1004835: `Select product`,
  key1004836: `Replace the product with the current one`,
  key1004837: `The quantity of replacement products must be less than the order quantity`,
  key1004838: `No replacement order item selected`,
  key1004839: `No replacement quantity entered for the selected product`,
  key1004840: `Please select a replacement product`,
  key1004841: `提交中`,
  key1004842: `Modify receipient information`,
  key1004843: `Buyer's name:`,
  key1004844: `Address 1:`,
  key1004845: `Address 2:`,
  key1004846: `Province / State:`,
  key1004847: `Telephone number:`,
  key1004848: `The currently selected destination country needs to enter IOSS related information`,
  key1004849: `请注意，修改地址信息后此订单将作废并生成新的订单!`,
  key1004850: `校验中...`,
  key1004851: `地址变更提示`,
  key1004852: `当前物流方式不支持此地区，更换地址后物流方式将被置空，请在待处理状态中重新匹配物流。请确认是否变更地址?`,
  key1004853: `确定变更`,
  key1004854: `Modifying`,
  key1004855: `order details`,
  key1004856: `计费重量(`,
  key1004857: `Processing Completed`,
  key1004858: `Expected`,
  key1004859: `Arrival`,
  key1004860: `This time is the estimated arrival time of the supplier's warehouse, and it will take 2-3 days until the final package is sent out.`,
  key1004861: `Outbound order no.`,
  key1004862: `Name of consignee`,
  key1004863: `Buyer identification number`,
  key1004864: `Detailed Address 1`,
  key1004865: `Detailed Address 2`,
  key1004866: `Shipping method`,
  key1004867: `Logistics provider weighing (g)`,
  key1004868: `Freight for logistics providers`,
  key1004869: `Waiting for logistics collection`,
  key1004870: `Supplier stocking`,
  key1004871: `Logistics collected`,
  key1004872: `平台订单自动创建`,
  key1004873: `Cancellation quantity cannot be empty`,
  key1004874: `Due to price adjustment, payment cannot be made at the moment when the supplier accepts the order`,
  key1004875: `The product has been discontinued`,
  key1004876: `Partial cancellation successful!`,
  key1004877: `Add Order`,
  key1004878: `订单商品信息`,
  key1004879: `订单号,支持多个按行分隔`,
  key1004880: `Order number:`,
  key1004881: `Third party tracking number:`,
  key1004882: `Domestic express delivery fee tracking number:`,
  key1004883: `Parcels that need to be sent by courier to the collection point will be subject to an additional domestic courier fee. The specific fees can be viewed by clicking on the corresponding channel in the self owned logistics module.`,
  key1004884: `Destination and buyer information`,
  key1004885: `Destination:`,
  key1004886: `Buyer ID:`,
  key1004887: `Product fee:`,
  key1004888: `Customs clearance fees:`,
  key1004889: `Partial shortage, product has been discontinued`,
  key1004890: `All`,
  key1004891: `Failed to issue logistics`,
  key1004892: `Please enter a third-party tracking number, which supports multiple separate lines`,
  key1004893: `Please enter the waybill number, which supports multiple separate lines`,
  key1004894: `Pending`,
  key1004895: `Platform:`,
  key1004896: `分销订单状态：`,
  key1004897: `Store:`,
  key1004898: `Is there a refund:`,
  key1004899: `Platform order abnormal status:`,
  key1004900: `分销订单异常状态：`,
  key1004901: `Execution Shipment Status:`,
  key1004902: `Platform order number:`,
  key1004903: `Please enter the platform order number, which supports multiple commas or line breaks for separation`,
  key1004904: `可输入平台订单号、分销订单号、包裹号、买家ID、 买家姓名、SKU、平台产品编码`,
  key1004905: `Export platform orders`,
  key1004906: `批量标记非`,
  key1004907: `Execute shipment`,
  key1004908: `Batch order acceptance`,
  key1004909: `Mark as shipped`,
  key1004910: `Standard shipping settings`,
  key1004911: `非`,
  key1004912: `执行发货后，`,
  key1004913: `将自动流转Temu订单状态，勾选的订单将作为一个物流包裹发出。`,
  key1004914: `正在确认通过`,
  key1004915: `履约速卖通全托管JIT订单，由于JIT订单限制较多，若出现平台处罚，`,
  key1004916: `将按照售后条款进行处理，无额外赔付，请确认是否接单？`,
  key1004917: `Platform order information`,
  key1004918: `Platform order product information`,
  key1004919: `Whether to label shipment:`,
  key1004920: `Order quantity:`,
  key1004921: `Buyer's note:`,
  key1004922: `平台订单异常`,
  key1004923: `分销订单信息`,
  key1004924: `阿里国际站订单`,
  key1004925: `Shipping logistics:`,
  key1004926: `分销订单商品信息`,
  key1004927: `分销订单状态`,
  key1004928: `分销订单金额`,
  key1004929: `分销订单异常`,
  key1004930: `Out of stock, product has been discontinued`,
  key1004931: `Match products`,
  key1004932: `Process orders`,
  key1004933: `Confirm waiting`,
  key1004934: `Taking orders`,
  key1004935: `修改物流`,
  key1004936: `Collecting goods`,
  key1004937: `Pending shipment`,
  key1004938: `Shipment executed`,
  key1004939: `Marked for shipment`,
  key1004940: `Platform order has been cancelled`,
  key1004941: `Unmatched goods`,
  key1004942: `Failed to match logistics`,
  key1004943: `Affiliated platform`,
  key1004944: `Shop Name`,
  key1004945: `Platform order label shipping status`,
  key1004946: `Order Original SKU * Quantity`,
  key1004947: `Is there a refund for platform orders`,
  key1004948: `Platform order abnormal status`,
  key1004949: `Customer ID`,
  key1004950: `Customer Name`,
  key1004951: `Buyer Notes`,
  key1004952: `分销订单号`,
  key1004953: `分销订单SKU信息`,
  key1004954: `分销订单创建时间`,
  key1004955: `分销订单发货时间`,
  key1004956: `分销订单发货时效`,
  key1004957: `Pending orders`,
  key1004958: `Matching Logistics`,
  key1004959: `Delivery failed`,
  key1004960: `To be recorded in IOSS`,
  key1004961: `客户更换地址`,
  key1004962: `客户标记取消`,
  key1004963: `Please tick the data to be marked as shipped first!`,
  key1004964: `Marked for shipment, please refresh the page later to view.`,
  key1004965: `Please tick the data that you want to place an order for first!`,
  key1004966: `Partially failed to place orders`,
  key1004967: `Orders from different stores cannot be shipped simultaneously!`,
  key1004968: `Only orders awaiting shipment can be shipped. Please reselect!`,
  key1004969: `Successfully executed shipment!`,
  key1004970: `Manual orders and platform orders cannot be shipped simultaneously. Please proceed in batches!`,
  key1004971: `Sample orders and shipment orders cannot be shipped simultaneously. Please proceed in batches!`,
  key1004972: `Please check the data to be shipped first!`,
  key1004973: `Confirm waiting order`,
  key1004974: `We are currently confirming and waiting for the order. If the order does not arrive on time, a second reminder will still be sent. Please confirm whether to wait for the order to arrive?`,
  key1004975: `Manual marking of shipments`,
  key1004976: `The system failed to mark the shipment to the platform. Please go to the platform backend to mark the shipment. After marking the shipment in the platform backend, you can click the confirm marked shipment button here to eliminate the abnormal status of this order.`,
  key1004977: `Confirm marked shipment`,
  key1004978: `Only pending orders can be accepted!`,
  key1004979: `Please check the data that needs to be accepted first!`,
  key1004980: `Confirm order acceptance`,
  key1004981: `Order accepted successfully!`,
  key1004982: `部分接单异常`,
  key1004983: `Sample order`,
  key1004984: `Goods order`,
  key1004985: `Regular orders`,
  key1004986: `平台订单已取消，请自行前往阿里国际中取消订单，取消后请手动标记已处理。`,
  key1004987: `可搜索商品名称、`,
  key1004988: `Keyword search:`,
  key1004989: `导出备货单`,
  key1004990: `Stock order information`,
  key1004991: `Procurement status:`,
  key1004992: `Stock up warehouse:`,
  key1004993: `Purchasing Product Information`,
  key1004994: `上架数量：`,
  key1004995: `取消数量：`,
  key1004996: `退款数量：`,
  key1004997: `Total value of goods:`,
  key1004998: `Time information`,
  key1004999: `Cancellation time:`,
  key1005000: `部分停售`,
  key1005001: `Cancel transaction`,
  key1005002: `Out of stock - restocking in progress`,
  key1005003: `Out of stock - discontinued`,
  key1005004: `Procurement status`,
  key1005005: `备货仓`,
  key1005006: `已取消金额`,
  key1005007: `已退款金额`,
  key1005008: `SKU单价`,
  key1005009: `SKU实付单价`,
  key1005010: `Purchase Order Payment Confirmation`,
  key1005011: `Processing purchase order payment. Please confirm if payment has been made?`,
  key1005012: `confirm the payment`,
  key1005013: `Cancel transaction prompt`,
  key1005014: `Canceling the stock order, please confirm if you want to cancel it?`,
  key1005015: `Cancel transaction successful!`,
  key1005016: `Missing barcode prompt`,
  key1005017: `None of the following products`,
  key1005018: `Information, please enter`,
  key1005019: `My product`,
  key1005020: `Try again after uploading in.`,
  key1005021: `SKU barcode`,
  key1005022: `Sample barcode`,
  key1005023: `Warehouse arrival details`,
  key1005024: `Successfully matched SKU`,
  key1005025: `Successfully matched SKU (manually matched)`,
  key1005026: `不匹配`,
  key1005027: `阿里国际单号：`,
  key1005028: `分销单号：`,
  key1005029: `launch`,
  key1005030: `Original SKU:`,
  key1005031: `Logistics service:`,
  key1005032: `Price and quantity`,
  key1005033: `Decoded SKU:`,
  key1005034: `Matching SKU failed`,
  key1005035: `Manual matching`,
  key1005036: `Rematch`,
  key1005037: `SKU has been discontinued, please cancel or replace the product`,
  key1005038: `The product is null, which cannot be associated.`,
  key1005039: `Total cost = Product + Handling fee + Logistics fee`,
  key1005040: `Handling fee = 3 + (Quantity of products - 1) * 0.5`,
  key1005041: `Total cost（`,
  key1005042: `Commodity fee（`,
  key1005043: `Processing fee（`,
  key1005044: `Logistics fees（`,
  key1005045: `Domestic express delivery fee（`,
  key1005046: `Customs clearance fees（`,
  key1005047: `阿里国际订单信息`,
  key1005048: `Shipping address information`,
  key1005049: `Platform order address information`,
  key1005050: `No more data available at the moment`,
  key1005051: `Domestic express tracking number`,
  key1005052: `Download Order`,
  key1005053: `Marked for shipment`,
  key1005054: `To be marked for shipment`,
  key1005055: `The entire order has been cancelled`,
  key1005056: `The order has been partially cancelled`,
  key1005057: `阿里国际商家已发货`,
  key1005058: `Expected delivery time:`,
  key1005059: `, discontinued`,
  key1005060: `Stock warehouse`,
  key1005061: `YMS Warehouse:`,
  key1005062: `Automatic payment after placing an order`,
  key1005063: `Please select a stocking warehouse!`,
  key1005064: `Configuration method:`,
  key1005065: `Global unified configuration`,
  key1005066: `Configure by platform`,
  key1005067: `You can choose any of the following YMS order nodes as the standard shipping nodes:`,
  key1005068: `Logistics:`,
  key1005069: `This information needs to be confirmed with the buyer. If not filled in, no additional shipping information will be pasted on the package`,
  key1005070: `Consignee:`,
  key1005071: `Please fill in the recipient`,
  key1005072: `Receiving Group:`,
  key1005073: `Please fill in the receiving group`,
  key1005074: `The shipping cost will be attached to the first order that executes the shipment`,
  key1005075: `Step 1: Choose logistics`,
  key1005076: `Step 2: Fill in the receiving information`,
  key1005077: `Logistics cannot be empty`,
  key1005078: `Please fill in the logistics tracking number`,
  key1005079: `Step 1: Upload packaging labels`,
  key1005080: `Step 2: Fill in the logistics tracking number`,
  key1005081: `Package number`,
  key1005082: `Package weight (g)`,
  key1005083: `Total number of package items`,
  key1005084: `Upload packaging labels`,
  key1005085: `No need to upload tags`,
  key1005086: `Please upload the packaging label first!`,
  key1005087: `The logistics tracking number cannot be empty!`,
  key1005088: `Delivery scope:`,
  key1005089: `Account name cannot be empty`,
  key1005090: `Account Authorization`,
  key1005091: `Account name:`,
  key1005092: `Associated stores`,
  key1005093: `Platform Store:`,
  key1005094: `Not Enabled`,
  key1005095: `Enabling`,
  key1005096: `Account, please confirm before proceeding.`,
  key1005097: `Deactivate this`,
  key1005098: `After the account is completed, the associated stores will not be able to distribute logistics. Please confirm whether to deactivate the account?`,
  key1005099: `Logistics Channel Code`,
  key1005100: `Logistics type`,
  key1005101: `Disable logistics reminder`,
  key1005102: `After disabling this logistics, it will not be applicable for distribution. Please confirm whether to disable it?`,
  key1005103: `Enable logistics reminder`,
  key1005104: `Enabling logistics, please confirm before proceeding.`,
  key1005105: `Enable channel prompts`,
  key1005106: `Enabling logistics channel, please confirm before proceeding.`,
  key1005107: `Enable account prompt`,
  key1005108: `Deactivate channel prompt`,
  key1005109: `After disabling this logistics channel, it will not be applicable for distribution. Please confirm whether to disable it?`,
  key1005110: `Deactivate account prompt`,
  key1005111: `Account`,
  key1005112: `Please upload your business license according to the following requirements. If you do not upload it as required, your application may be rejected`,
  key1005113: `Please ensure that the font of the business license image is clear, the edges and corners are complete, and there are no irrelevant watermarks. Do not intentionally cover up the QR code or information. If the shooting is blurry, it is recommended to upload a scanned copy.`,
  key1005114: `Please check the Unified Social Credit Identifier/registration number you filled in by yourself in the national enterprise credit information publicity system to ensure that your subject information can be found, and the registration status is "existing (in operation, opening, registered)".`,
  key1005115: `If there has been a change in the company name or legal representative information, please upload a business license image with the latest approval date.`,
  key1005116: `Example image`,
  key1005117: `Please upload according to the following requirements. Failure to meet the following specifications will result in review rejection`,
  key1005118: `Please ensure that the name on your uploaded legal representative's ID card matches the name of the legal representative on your business license.`,
  key1005119: `Please confirm that the font of the legal representative's ID card is clear, without reflection, with complete edges and corners, and without any irrelevant watermarks or intentional covers.`,
  key1005120: `YMS platform privacy policy`,
  key1005121: `Please fill in the full name of the company on the business license`,
  key1005122: `Please select [jpg, jpeg, png]`,
  key1005123: `Company name cannot be empty`,
  key1005124: `Corporate Identity Card National Emblem Face`,
  key1005125: `Face of legal representative witness`,
  key1005126: `Please upload the national emblem of the legal person's ID card first`,
  key1005127: `Please upload the profile of the legal representative witness first`,
  key1005128: `Specification for Identity Card of Legal Representative`,
  key1005129: `Business License Specification`,
  key1005130: `Graphic verification code:`,
  key1005131: `Please enter the SMS verification code`,
  key1005132: `Set password:`,
  key1005133: `8-26 digits (it is recommended to use two or more character combinations)`,
  key1005134: `Confirm password:`,
  key1005135: `Please enter the password again`,
  key1005136: `This phone number has already been registered as a distributor. If you continue, it will be considered as using this account to join the supplier`,
  key1005137: `I have read and agree`,
  key1005138: `The password length must be 8-26 digits`,
  key1005139: `Please fill in your phone number and graphic verification code first!`,
  key1005140: `Account registration successful`,
  key1005141: `Your account has been successfully registered. Please continue to improve the registration information and complete the registration!`,
  key1005142: `Please check first that I have read and agree`,
  key1005143: `Setting password cannot be empty`,
  key1005144: `The set password and confirmed password are inconsistent`,
  key1005145: `Fill in account information`,
  key1005146: `Fill in enterprise information`,
  key1005147: `Review of entry materials`,
  key1005148: `login was successful`,
  key1005149: `Existing account?`,
  key1005150: `log in immediately`,
  key1005151: `Submitted successfully`,
  key1005152: `Your application materials have been submitted, and we usually take 3 working days`,
  key1005153: `Please be patient and wait for the internal audit to be completed. If you have any questions, please`,
  key1005154: `Contact online customer service!`,
  key1005155: `Entry information review in progress, please be patient and wait~`,
  key1005156: `After approval, the supplier backend can be used normally. If you have`,
  key1005157: `If you have any questions, please contact online customer service!`,
  key1005158: `Your application has not been approved`,
  key1005159: `Please verify and modify the following information before resubmitting.`,
  key1005160: `Revise`,
  key1005161: `Everything is ready`,
  key1005162: `Your application has been approved!`,
  key1005163: `Go and publish the product`,
  key1005164: `Exiting`,
  key1005165: `Account login`,
  key1005166: `Mobile phone number/email/travel account`,
  key1005167: `Password`,
  key1005168: `Verification code`,
  key1005169: `SMS login`,
  key1005170: `SMS verification code`,
  key1005171: `Authorize now`,
  key1005172: `Retrieve password`,
  key1005173: `Mobile phone number/email/travel account cannot be empty`,
  key1005174: `SMS verification code cannot be empty`,
  key1005175: `Incorrect phone number/email format`,
  key1005176: `Mobile number registration`,
  key1005177: `Email registration`,
  key1005178: `Email (optional)`,
  key1005179: `Thoroughfare login`,
  key1005180: `Welcome to register`,
  key1005181: `register`,
  key1005182: `Already have an account, please log in`,
  key1005183: `Login Tongtool account`,
  key1005184: `If you already have a Tongtool account, you can log in with your Tongtool account directly.`,
  key1005185: `Login will activate YMS Mall distribution permissions for you.`,
  key1005186: `registration success!`,
  key1005187: `set password`,
  key1005188: `Please enter email / verified phone number`,
  key1005189: `Go to log in`,
  key1005190: `Mobile phone number/email cannot be empty`,
  key1005191: `confirm password can not be blank`,
  key1005192: `Submit`,
  key1005193: `Home page`,
  key1005194: `Supplier Entry Guidelines`,
  key1005195: `Immediate entry`,
  key1005196: `Welcome to join the YMS distribution platform`,
  key1005197: `YMS is a leading cross-border clothing one-stop distribution platform in China. The founding team members have been deeply involved in the cross-border industry for more than ten years and have rich and high-quality cross-border e-commerce supply chain resources,`,
  key1005198: `We can provide integrated services such as cargo collection, warehousing, logistics, distribution, etc. We can help source factories, brand agents, industry and trade integrated enterprises and other types of suppliers expand their overseas sales,`,
  key1005199: `Adhering to the business philosophy of "win-win cooperation and common development", we sincerely invite high-quality cross-border e-commerce suppliers to join us and assist you in your overseas market.`,
  key1005200: `Our Advantages`,
  key1005201: `Investment category`,
  key1005202: `Supplier Qualification Requirements`,
  key1005203: `Enterprise Information`,
  key1005204: `Entry materials`,
  key1005205: `Material requirements`,
  key1005206: `Enterprise business license with three certificates in one;`,
  key1005207: `Ensure that the enterprise applying to settle in is not listed in the "Abnormal Business List" and that the sales`,
  key1005208: `The goods are within its business scope;`,
  key1005209: `The deadline for expiration should be greater than 3 months.`,
  key1005210: `ID card`,
  key1005211: `Corporate ID card;`,
  key1005212: `The deadline for expiration should be greater than 1 month.`,
  key1005213: `Support bank card or Alipay.`,
  key1005214: `Product requirements`,
  key1005215: `Product Qualification`,
  key1005216: `Legal and compliant products`,
  key1005217: `The promised goods are legal and compliant, and comply with the laws, regulations, and quality of the sales destination`,
  key1005218: `Quantity standards and YMS shelving specifications;`,
  key1005219: `For patented products, a sales area patent certificate is required;`,
  key1005220: `Suppliers with a brand need to provide a brand authorization letter;`,
  key1005221: `Having stable supply channels and willing to follow platform rules.`,
  key1005222: `Sincere long-term cooperation`,
  key1005223: `Ensure the lowest commodity prices on the entire network;`,
  key1005224: `Agree with YMS development philosophy and have a sincere and long-term cooperation attitude.`,
  key1005225: `order fulfillment `,
  key1005226: `Ensure that the goods arrive at the YMS warehouse within the delivery period.`,
  key1005227: `Entry process`,
  key1005228: `Instantly enter YMS suppliers and enjoy a new mode of rapid distribution`,
  key1005229: `YMS sincerely invites you to join us and become our high-quality partner. Click to join now!`,
  key1005230: `special`,
  key1005231: `Focus on cross-border e-commerce`,
  key1005232: `Over 10 years of professional cross-border e-commerce clothing product team`,
  key1005233: `wide`,
  key1005234: `Covering mainstream e-commerce platforms`,
  key1005235: `Distribution stores cover mainstream e-commerce platforms such as Amazon and eBay`,
  key1005236: `collection`,
  key1005237: `Centralized processing of orders`,
  key1005238: `Suppliers only need to ship to the YMS consolidation warehouse`,
  key1005239: `Exemption`,
  key1005240: `Free storage service fee`,
  key1005241: `Receive free warehousing services upon entry`,
  key1005242: `Quick delivery`,
  key1005243: `One stop delivery system hosting, saving storage energy and controlling labor costs`,
  key1005244: `Women's clothing`,
  key1005245: `Men's clothing`,
  key1005246: `Girls`,
  key1005247: `Boys`,
  key1005248: `Infants and children`,
  key1005249: `Product research`,
  key1005250: `YMS assists in conducting product research to determine that the product meets market demand and has a competitive price advantage.`,
  key1005251: `Register an account`,
  key1005252: `Fill in account information as required and register a corporate account.`,
  key1005253: `Fill in the entry information`,
  key1005254: `Submit business license, legal representative ID card, and fill in relevant store information.`,
  key1005255: `After submitting the entry materials, YMS will complete the review within 3 working days.`,
  key1005256: `Completion of settlement`,
  key1005257: `After the review is approved, the supplier logs in to the backend and publishes the product for sale.`,
  key1005258: `Please describe your problem, eg: Logistics`,
  key1005259: `Video tutorial`,
  key1005260: `Yunmai Supply Chain`,
  key1005261: `Function Introduction`,
  key1005262: `Introduction to Cloud Selling Supply Chain Functions`,
  key1005263: `Register for Cloud Selling Supply Chain`,
  key1005264: `Store authorization`,
  key1005265: `self service`,
  key1005266: `Changing your password regularly helps keep your account secure`,
  key1005267: `Modify mobile number`,
  key1005268: `The mobile phone number is involved in key businesses such as login and authentication to ensure account security`,
  key1005269: `The mailbox involves key businesses such as login and message notification, and ensures account security`,
  key1005270: `search"`,
  key1005271: `” related results, a total of`,
  key1005272: `No matching search results`,
  key1005273: `Help Home`,
  key1005274: `Log in`,
  key1005275: `Login to view prices`,
  key1005276: `Daily update`,
  key1005277: `Trendy women's clothing`,
  key1005278: `Fashion men's clothing`,
  key1005279: `Boutique children's clothing`,
  key1005280: `Hot selling products`,
  key1005281: `Good Product Recommendation`,
  key1005282: `YMS Mall (www.yms. com) is a supply chain service platform established by Shenzhen Yunmai Supply Chain Management Co., Ltd. that focuses on cross-border e-commerce clothing categories. The platform integrates the source factory clothing mall, the Tongtu Listing publishing platform, the Tongtu ERP platform, and the Tongtu logistics platform, providing an efficient order fulfillment link between cross-border e-commerce sellers and the source factory. Customer orders from foreign sales terminals are directly shipped by the source factory one by one.`,
  key1005283: `Just 3 steps to make cross-border business easier`,
  key1005284: `You just need to focus on operations, and we'll help you with the rest`,
  key1005285: `Selection`,
  key1005286: `Register a free account and subscribe to products at YMS Mall`,
  key1005287: `Publication`,
  key1005288: `Directly connected to the listing and publishing system, quick listing to multi platform stores such as eBay and Amazon with just one click`,
  key1005289: `After the store issues an order, you can place an order in the YMS mall, supporting one piece shipping`,
  key1005290: `YMS Mall cross-border e-commerce, focusing on clothing distribution`,
  key1005291: `Now you can publish thousands of clothing products from YMS Mall to major overseas platforms, thereby winning more sales opportunities`,
  key1005292: `Massive product selection`,
  key1005293: `40000+clothing SKUs, 1000+monthly updates`,
  key1005294: `0 inventory risk`,
  key1005295: `No need for domestic stock preparation, zero inventory risk`,
  key1005296: `No financial pressure`,
  key1005297: `First issue documents and then pay to improve Asset turnover`,
  key1005298: `You just need to focus on product sales, and even if you only sell one product, we will kindly help you ship it`,
  key1005299: `Quick listing to multiple platforms`,
  key1005300: `Private label logo makes customers more reliable`,
  key1005301: `Private label can be identified through packages and packages to make customers more reliable`,
  key1005302: `Automatic tracking of orders`,
  key1005303: `The sales order system automatically tracks customer order details without the need to copy and paste them`,
  key1005304: `Fast delivery with guaranteed delivery time`,
  key1005305: `Open API interface`,
  key1005306: `YMS Mall provides an open interface that enables various functions such as product management, order management, and warehouse management`,
  key1005307: `Distributor Business`,
  key1005308: `1) Service content: Provide one-stop shipping services for cross-border e-commerce clothing sellers' orders on platforms such as eBay, Walmart, AliExpress, Wish, Shopify, etc.`,
  key1005309: `2) Service process:`,
  key1005310: `Register as a distributor of YMS Mall`,
  key1005311: `Option 1: Through an ERP account (http:`,
  key1005312: `Option 2: Register and activate directly with your phone number.`,
  key1005313: `Publish sales`,
  key1005314: `Select and collect products in the YMS mall, and quickly publish them on platforms such as eBay, Walmart, and AliExpress through the Tongtu Listing system for sale.`,
  key1005315: `Order shipment`,
  key1005316: `Tongtu ERP captures platform orders, matches international logistics methods set by distributors, and distributes them to YMS Mall. The order can be fulfilled and sent to foreign customers on a commission basis.`,
  key1005317: `Supplier Business`,
  key1005318: `1) Service content: Factories in the cross-border e-commerce clothing category will settle in YMS Mall to open direct stores.`,
  key1005319: `The clothing factory contacted the supplier's investment manager to register and settle in.`,
  key1005320: `Manage stores, standardize product listings, manage inventory, and operate order shipments.`,
  key1005321: `Central warehouse warehousing business`,
  key1005322: `1) Service content: Provide warehousing services for YMS Mall suppliers.`,
  key1005323: `YMS Mall supplier opens cloud warehouse.`,
  key1005324: `The central warehouse prepares and ships goods, the supplier creates a stocking plan, affixes the YMS mall product barcode into the warehouse, and the central warehouse automatically matches the order for shipment.`,
  key1005325: `Introduction to Platform Business`,
  key1005326: `Contact address: 6th floor, Building C1, Nanshan Zhiyuan, Xueyuan Avenue, Nanshan District, Shenzhen`,
  key1005327: `Contact number: 400-0600-405`,
  key1005328: `Distributor Online Service QQ: 2853913746`,
  key1005329: `View after reaching the previous sales volume`,
  key1005330: `Address:`,
  key1005331: `current location：`,
  key1005332: `Publishing time:`,
  key1005333: `Discount`,
  key1005334: `Accumulated distribution within 30 days`,
  key1005335: `collection`,
  key1005336: `One click publishing supports the following platforms`,
  key1005337: `High quality supplier`,
  key1005338: `Price: Visible after login`,
  key1005339: `Product details`,
  key1005340: `Item parameters`,
  key1005341: `Supplier recommendations`,
  key1005342: `I currently do not have permission to view. Please contact customer service to activate permissions.`,
  key1005343: `Follow the shop`,
  key1005344: `Packing size (cm)`,
  key1005345: `US West Warehouse Inventory`,
  key1005346: `Cancel Collection Successfully`,
  key1005347: `★ Followed`,
  key1005348: `Getting Started Video`,
  key1005349: `New User Introduction Operations`,
  key1005350: `New User Registration`,
  key1005351: `Authorized Store`,
  key1005352: `One click publishing process`,
  key1005353: `One click publishing success prerequisite: Successfully authorized store`,
  key1005354: `Set store publishing template`,
  key1005355: `One click publishing rules`,
  key1005356: `Step 1: Publish Template Settings`,
  key1005357: `Publication template settings`,
  key1005358: `Step 2: Setting Publication Rules`,
  key1005359: `Publishing Rule Settings`,
  key1005360: `Step 3: One click publishing`,
  key1005361: `Company Profile`,
  key1005362: `Image Type`,
  key1005363: `One click publishing support platform:`,
  key1005364: `Favorite or not:`,
  key1005365: `Contact name cannot be empty`,
  key1005366: `Settlement Currency:`,
  key1005367: `Province/City/District:`,
  key1005368: `Warehouse configuration`,
  key1005369: `Enable warehouse management`,
  key1005370: `Shipping address information`,
  key1005371: `+Add shipping address`,
  key1005372: `您更改提交的商户工商信息，平台正在审核中......`,
  key1005373: `您更改提交的商户工商信息已被平台驳回！请查看驳回原因后重新提交审核。`,
  key1005374: `查看驳回原因>>>`,
  key1005375: `您更改提交的商户工商信息平台已审核通过！`,
  key1005376: `Warehouse management not enabled`,
  key1005377: `工商信息已经在审核中，暂不支持修改！`,
  key1005378: `确认后不保存已编辑信息`,
  key1005379: `是否关闭仓储功能？`,
  key1005380: `当前仓储功能关闭后，暂不支持重新开启，是否确认关闭？`,
  key1005381: `Warehouse management has been enabled`,
  key1005382: `Please enter the name of the account holder`,
  key1005383: `Please enter the account opening bank`,
  key1005384: `Please enter your bank card number`,
  key1005385: `Please enter ID number number`,
  key1005386: `Please enter your Alipay login number, which supports email and mobile number formats`,
  key1005387: `Please enter the name of the account holder of this Alipay account`,
  key1005388: `Please enter the ID number of the account holder of this Alipay account`,
  key1005389: `binding`,
  key1005390: `Bind Collection Account`,
  key1005391: `(Recommended)`,
  key1005392: `Replacement successful!`,
  key1005393: `Please enter your Alipay login number`,
  key1005394: `(Recommended)`,
  key1005395: `Change Log`,
  key1005396: `Bind Account`,
  key1005397: `Change account`,
  key1005398: `Replace binding`,
  key1005399: `Original record`,
  key1005400: `Record after change`,
  key1005401: `Change time`,
  key1005402: `Not added yet`,
  key1005403: `Add Collection Account`,
  key1005404: `Not currently bound`,
  key1005405: `Replacing the receiving account`,
  key1005406: `Modification failed`,
  key1005407: `Operation failed`,
  key1005408: `Role`,
  key1005409: `Please enter the bill of lading number`,
  key1005410: `审核中,审核后将展示补贴金额。`,
  key1005411: `accord with`,
  key1005412: `不满足现有补贴规则、如有疑问请联系您的`,
  key1005413: `对接人员`,
  key1005414: `Receiving time`,
  key1005415: `Actual quantity to be shipped`,
  key1005416: `Multiple quantity`,
  key1005417: `Sent to warehouse`,
  key1005418: `Additional goods`,
  key1005419: `Voided bill of lading`,
  key1005420: `Invalid bill of lading reminder`,
  key1005421: `After the bill of lading is invalidated, all orders in the bill of lading will be transferred to the status of waiting for resend, and the outbound order will be transferred to the status of waiting for picking. Please confirm whether to invalidate the bill of lading?`,
  key1005422: `Boxing`,
  key1005423: `Logistics tracking number`,
  key1005424: `Voided successfully!`,
  key1005425: `Modify stock quantity`,
  key1005426: `Modified quantity`,
  key1005427: `Treat as not modified when not entered`,
  key1005428: `Please enter the quantity of products to be modified`,
  key1005429: `Successfully modified the stock quantity!`,
  key1005430: `Initiate stocking`,
  key1005431: `Batch shipment`,
  key1005432: `Selected orders`,
  key1005433: `Filter result orders`,
  key1005434: `仅出库单类型为自有仓备货的备货单可打印`,
  key1005435: `条码！`,
  key1005436: `Scan SKU printing`,
  key1005437: `Print the selected order barcode`,
  key1005438: `Please check the data that needs to be marked for shipment first!`,
  key1005439: `There is currently no data in the current filtering results, unable to mark shipment!`,
  key1005440: `Only orders with an outbound status of pending shipment in the current order can be shipped!`,
  key1005441: `Only orders with a stock status of pending shipment in the current order can be shipped!`,
  key1005442: `There are currently no products in the shipping desk, and the distribution list cannot be printed.`,
  key1005443: `Please check the order barcode data that needs to be printed first!`,
  key1005444: `There is currently no data available for the current filtering results! Unable to print order barcode`,
  key1005445: `Cancel stocking`,
  key1005446: `Please confirm whether to cancel stocking?`,
  key1005447: `Distribution List Format`,
  key1005448: `List format:`,
  key1005449: `Paper checklist`,
  key1005450: `Table file`,
  key1005451: `Scan and exchange orders`,
  key1005452: `Please scan/input SKU`,
  key1005453: `Please scan/input SKU:`,
  key1005454: `Batch printing:`,
  key1005455: `Close Popup`,
  key1005456: `SKU cannot be empty!`,
  key1005457: `Print quantity cannot be empty!`,
  key1005458: `获取打印地址失败！`,
  key1005459: `Destination warehouse:`,
  key1005460: `Settlement amount:`,
  key1005461: `Reject`,
  key1005462: `To be paid by the customer`,
  key1005463: `Please enter the order number, which supports multiple commas or line breaks for separation`,
  key1005464: `Please enter the supplier SKU, which supports multiple commas or line breaks for separation`,
  key1005465: `Out of stock orders need to be marked as received before shipping!`,
  key1005466: `Only orders with a pending shipment status in the current order can be shipped!`,
  key1005467: `There is currently no data available for the current filtering results, unable to operate!`,
  key1005468: `Please check the data that needs to be marked first!`,
  key1005469: `Mark arrival reminder`,
  key1005470: `After marking the arrival, the order will be transferred to pending shipment. Please confirm whether to mark the arrival?`,
  key1005471: `Only out of stock orders can be marked with arrival, please reselect!`,
  key1005472: `Only non out of stock orders can be marked as out of stock. Please reselect!`,
  key1005473: `Order confirmation`,
  key1005474: `Refusal to accept order confirmation`,
  key1005475: `We are in the process of accepting the order. After confirming the acceptance, the settlement will be based on the amount of this order. We need to wait for the customer to make payment. Please confirm if you want to accept the order?`,
  key1005476: `After rejecting the order, the order may be cancelled. Please confirm if you want to reject the order?`,
  key1005477: `Refuse to accept orders`,
  key1005478: `Successful order acceptance`,
  key1005479: `Rejected order successfully`,
  key1005480: `Shipment reminder`,
  key1005481: `The outbound order type cannot be empty!`,
  key1005482: `The destination warehouse cannot be empty!`,
  key1005483: `The product quantity cannot be empty or 0`,
  key1005484: `The quantity must be an integer greater than 0`,
  key1005485: `Please add products first!`,
  key1005486: `Screen`,
  key1005487: `请扫描或输入扫描`,
  key1005488: `Please enter the delivery note number`,
  key1005489: `Complete addition`,
  key1005490: `The outbound order has been scanned`,
  key1005491: `Encoding Information`,
  key1005492: `Packing reminder`,
  key1005493: `The outbound tracking number you scanned is different from the warehouse where the current outbound tracking has been packed. Please scan the outbound tracking number of the same warehouse for packing.`,
  key1005494: `Please enter sku`,
  key1005495: `打印数量不能为空`,
  key1005496: `Commodity management`,
  key1005497: `Publish new products`,
  key1005498: `Sales Run chart`,
  key1005499: `Hot selling product ranking list`,
  key1005500: `统计本月（自然月）店铺已完成`,
  key1005501: `仓已发出`,
  key1005502: `的订单总金额。`,
  key1005503: `统计本月（自然月）已完成`,
  key1005504: `的订单总数。`,
  key1005505: `Last month's sales revenue`,
  key1005506: `Order quantity this month (orders)`,
  key1005507: `Last month's order quantity`,
  key1005508: `Yesterday's order volume`,
  key1005509: `Today's order volume`,
  key1005510: `Total number of SPUs on sale`,
  key1005511: `Calculate the total number of SPUs on sale, excluding SPUs in suspended or temporary storage status.`,
  key1005512: `Total number of SKUs on sale`,
  key1005513: `Calculate the total number of SKUs on sale, excluding SKUs in suspended or temporary storage status.`,
  key1005514: `Number of subscribed products (SPU)`,
  key1005515: `Count the total number of products subscribed by distributors, and only calculate once for multiple distributors subscribing to the same product.`,
  key1005516: `Number of subscriptions this month`,
  key1005517: `Number of subscriptions yesterday`,
  key1005518: `Number of subscriptions today`,
  key1005519: `Calculate the total number of SPUs that have been listed on the platform by distributors, and calculate the same SPU with different items only once.`,
  key1005520: `Calculate the total number of SPUs published by distributors, based on the number of items.`,
  key1005521: `Delivery time in the past 30 days (hours)`,
  key1005522: `Calculate the average time taken for all orders in the past 30 days from waiting to be picked to being shipped (courier pickup)`,
  key1005523: `Delivery time in the past 7 days`,
  key1005524: `Delivery time in the past 3 days`,
  key1005525: `On sale`,
  key1005526: `Sold out`,
  key1005527: `Rejected`,
  key1005528: `Last 7 days`,
  key1005529: `In the past 30 days`,
  key1005530: `Sales amount (CNY): ¥`,
  key1005531: `Order quantity (order):`,
  key1005532: `中心仓`,
  key1005533: `Inventory Details Log`,
  key1005534: `bill`,
  key1005535: `Bill of Lading`,
  key1005536: `Listing/Discontinuation`,
  key1005537: `Please enter Chinese title`,
  key1005538: `Approval Status:`,
  key1005539: `Batch price change`,
  key1005540: `Batch listing/discontinuation`,
  key1005541: `Export Product`,
  key1005542: `Obtain 1688 items`,
  key1005543: `Collect 1688 items`,
  key1005544: `Print barcode`,
  key1005545: `1. After importing the sales price, the original price will be overwritten, please import carefully;`,
  key1005546: `4. After the import operation, please check the import result in "Import/Export Task - Import View" later.`,
  key1005547: `1. According to the SKU dimension for listing/discontinuation, after discontinuation, distributors will not be able to continue placing orders;`,
  key1005548: `sku,支持多个按行分隔`,
  key1005549: `Updated on:`,
  key1005550: `Please enter the supplier sku, support multiple lines separated`,
  key1005551: `Modify the price`,
  key1005552: `Import Product`,
  key1005553: `Name of central warehouse`,
  key1005554: `Allocated quantity of central warehouse`,
  key1005555: `Available quantity of central warehouse`,
  key1005556: `Allocated quantity of self owned warehouses`,
  key1005557: `Available quantity of self owned warehouses`,
  key1005558: `Supply price (yuan)`,
  key1005559: `This product is currently being used by a mapped product and cannot be deleted`,
  key1005560: `SKU does not exist`,
  key1005561: `This SKU has inventory and cannot be deleted`,
  key1005562: `The system is busy, please try again`,
  key1005563: `Select classification:`,
  key1005564: `template:`,
  key1005565: `Step 1: Download Template`,
  key1005566: `Step 2: Upload materials`,
  key1005567: `When importing data and discovering the same SPU:`,
  key1005568: `1. Please follow the import instructions in the template to upload product information.`,
  key1005569: `2. Only support extension:. zip.`,
  key1005570: `3. When there are exceptions in the imported data, the data import fails.`,
  key1005571: `5. It is currently not possible to import or overwrite assembled products.`,
  key1005572: `The file size exceeds the limit, with a maximum of 500M`,
  key1005573: `Please select a category first!`,
  key1005574: `Successfully downloaded the template!`,
  key1005575: `Acquisition type:`,
  key1005576: `Select a store:`,
  key1005577: `Product link:`,
  key1005578: `Please enter the 1688 product link, such as: https://detail.1688.com/offer/673838787879.html Support for multiple row separations`,
  key1005579: `Please select 1688 product creation time`,
  key1005580: `obtain`,
  key1005581: `将尽快补充映射。`,
  key1005582: `匹配映射失败，`,
  key1005583: `The time range cannot be empty`,
  key1005584: `Store cannot be empty`,
  key1005585: `1688 store not bound`,
  key1005586: `1688 store is not currently bound. Please bind and try again.`,
  key1005587: `Failed to obtain product`,
  key1005588: `Failed to obtain the product section`,
  key1005589: `Copy failed SPU`,
  key1005590: `Matching mapping failed, you can click`,
  key1005591: `Successfully obtained product`,
  key1005592: `View products`,
  key1005593: `Successfully obtained the product, which can be found on the product page`,
  key1005594: `Product acquisition in progress, please wait!`,
  key1005595: `Product link cannot be empty!`,
  key1005596: `The specified link address must be a link starting with https://or http://!`,
  key1005597: `Class 1688:`,
  key1005598: `Batch collection`,
  key1005599: `Transactions in the past 90 days:`,
  key1005600: `SKU重复`,
  key1005601: `Product collection in progress, please wait!`,
  key1005602: `Brand name cannot be empty`,
  key1005603: `brand name:`,
  key1005604: `Please enter brand name.`,
  key1005605: `Brand description:`,
  key1005606: `Please enter brand description.`,
  key1005607: `Brand status:`,
  key1005608: `Brand URL:`,
  key1005609: `Please enter URL of the brand.`,
  key1005610: `Brand logo:`,
  key1005611: `Add brand`,
  key1005612: `Click or drag here to upload files`,
  key1005613: `By brand status`,
  key1005614: `By brand name`,
  key1005615: `Brand Logo`,
  key1005616: `Brand name`,
  key1005617: `Brand description`,
  key1005618: `Brand website`,
  key1005619: `Brand status`,
  key1005620: `Do you want to delete the current brand?`,
  key1005621: `Editorial brand`,
  key1005622: `Price cannot be 0 or empty!`,
  key1005623: `Waiting for payment`,
  key1005624: `Original price of goods`,
  key1005625: `Goods amount (receivable):`,
  key1005626: `Subsidy amount (receivable):`,
  key1005627: `Logistics expenses (payable):`,
  key1005628: `Please enter the billing number, which supports multiple commas or line breaks for separation`,
  key1005629: `Export Bill`,
  key1005630: `确认账单即表示已对账单内容、金额核对无误，请确认账单后操作，确认账单后`,
  key1005631: `后台将发起打款流程，资金将转入所绑定的收款账户。`,
  key1005632: `货品结算金额(CNY)`,
  key1005633: `Confirm billing`,
  key1005634: `Collection account not bound`,
  key1005635: `The current account is not bound to a receiving account and cannot complete the receiving process. Please bind and try again.`,
  key1005636: `Unbind`,
  key1005637: `Bill number`,
  key1005638: `Chinese description:`,
  key1005639: `Export inventory (self owned warehouse)`,
  key1005640: `(Deleted)`,
  key1005641: `SKU, Product name`,
  key1005642: `fuzzy search`,
  key1005643: `SKU attributes`,
  key1005644: `Inventory`,
  key1005645: `Frozen quantity`,
  key1005646: `Quantity of defective products`,
  key1005647: `Please select the storage area.`,
  key1005648: `导出库存(`,
  key1005649: `中心仓)`,
  key1005650: `Storage area:`,
  key1005651: `Own warehouse`,
  key1005652: `Failed to open picking review`,
  key1005653: `The picking list you scanned or entered【 {pickList} 】Completed`,
  key1005654: `The picking list you scanned or entered【 {pickList} 】Does not exist or the state has changed,Please refresh and try again`,
  key1005655: `The picking list you scanned or entered【 {pickList} 】There is no delivery order under`,
  key1005656: `The picking list you scanned or entered【 {pickList} 】There is no valid delivery order under`,
  key1005657: `The picking list you scanned or entered【 {pickList} 】There are common print delivery orders under`,
  key1005658: `The picking list you scanned or entered【 {pickList} 】No multi product sorting`,
  key1005659: `The picking list you scanned or entered【 {pickList} 】The delivery order has not been issued to the logistics provider successfully`,
  key1005660: `The picking list you scanned or entered【 {pickList} 】Picking not completed`,
  key1005661: `The picklist you scanned or entered was not found in the system. Please make sure that the picklist has been deleted.`,
  key1005662: `Scan/enter the pick list number to start picking review`,
  key1005663: `Picking review in progress`,
  key1005664: `Mix`,
  key1005665: `Duration`,
  key1005666: `Package progress`,
  key1005667: `Progress of products`,
  key1005668: `Team members`,
  key1005669: `Single item single item`,
  key1005670: `Single product and multiple parts`,
  key1005671: `There is an ongoing picking review, whether to enter immediately`,
  key1005672: `There is an ongoing picking review, whether to jump immediately`,
  key1005673: `Uploaded logistics`,
  key1005674: `Please choose a logistics provider.`,
  key1005675: `Outbound order details:`,
  key1005676: `Outbound order no.:`,
  key1005677: `Reference no.:`,
  key1005678: `Picking status:`,
  key1005679: `Boxing status:`,
  key1005680: `Last modification time:`,
  key1005681: `Last modifier:`,
  key1005682: `Estimated weight of the thoroughfare:`,
  key1005683: `Weighing weight in Tongtool:`,
  key1005684: `Estimated freight for the entire route:`,
  key1005685: `Shipping cost after weighing:`,
  key1005686: `Logistics provider weighs:`,
  key1005687: `Allocation list`,
  key1005688: `Other outlets`,
  key1005689: `Manufacturing`,
  key1005690: `Row state`,
  key1005691: `Reason for abnormal distribution`,
  key1005692: `SKU`,
  key1005693: `Fill in the planned transfer quantity`,
  key1005694: `Quantity shipped`,
  key1005695: `ID code`,
  key1005696: `Allocated quantity`,
  key1005697: `Unallocated quantity`,
  key1005698: `Assign storage bin`,
  key1005699: `Allocate batches`,
  key1005700: `Allocate finish time`,
  key1005701: `New order`,
  key1005702: `Partial allocation`,
  key1005703: `Allocation complete`,
  key1005704: `Full shipped`,
  key1005705: `Order completed`,
  key1005706: `unboxed `,
  key1005707: `Details failed to load`,
  key1005708: `中心仓备货时进行包装作业：`,
  key1005709: `Automatic sorting function for multi-product package:`,
  key1005710: `Conduct packing during picking review:`,
  key1005711: `This setting is invalid for the supplier stocking process`,
  key1005712: `Do I need to weigh the package during picking review?`,
  key1005713: `Mark the packaged package in the packaging operation as shipped?`,
  key1005714: `open`,
  key1005715: `Print waybill while sorting`,
  key1005716: `Not weighing`,
  key1005717: `Sorting rack settings (available only when multi-item multi-package automatic sorting function is turned on)`,
  key1005718: `sort by SKU character in ascending order`,
  key1005719: `Please choose at least one picking rack.`,
  key1005720: `outbound order no. or tracking no.`,
  key1005721: `The waybill number scanned by some logistics companies will have an extension or suffix, so that the system cannot recognize the waybill number, and the replacement can cut off the corresponding replacement or suffix.`,
  key1005722: `Size:`,
  key1005723: `Warning of abnormal weighing`,
  key1005724: `Local shipments`,
  key1005725: `Outbound after scanning`,
  key1005726: `Switch to bulk outbound`,
  key1005727: `Volume`,
  key1005728: `The weighing unit is:`,
  key1005729: `Automatic mark shipped`,
  key1005730: `Actual weight:`,
  key1005731: `Cut off`,
  key1005732: `Already issued`,
  key1005733: `Bulk mark shipped`,
  key1005734: `Clear`,
  key1005735: `Need to weigh`,
  key1005736: `Required size`,
  key1005737: `Weighing exception reminder settings`,
  key1005738: `When the products in the package are:`,
  key1005739: `And the error between package weight and commodity information weight is greater than or equal to`,
  key1005740: `You will be prompted`,
  key1005741: `Shipping freight exception settings`,
  key1005742: `Scan shipment order by order to calculate freight greater than`,
  key1005743: `Product weight subtotal (g):`,
  key1005744: `Total weight(g):`,
  key1005745: `Continue to ship`,
  key1005746: `Cancel mark shipped`,
  key1005747: `Parcel(s)`,
  key1005748: `Total Weight`,
  key1005749: `Derive Logistics and Confirm Excel`,
  key1005750: `Product weight`,
  key1005751: `Scanned`,
  key1005752: `Mark shipped success`,
  key1005753: `This package already exists`,
  key1005754: `Measuring Size`,
  key1005755: `The package number cannot be empty`,
  key1005756: `No authority`,
  key1005757: `Package summary information for your current filter:`,
  key1005758: `Common labels：`,
  key1005759: `Order qty`,
  key1005760: `Number of SKU`,
  key1005761: `Opening abnormal multi-item picking operation`,
  key1005762: `The picking list you scanned or entered【 {pickList} 】It is not found in the system. Please confirm whether the picking list has been deleted or the picking order number is entered incorrectly。`,
  key1005763: `The picklist you scan or enter is not a multi-item multi-item picklist and cannot open the picking operation.`,
  key1005764: `Each operator of the system is allowed to open only one picking operation at the same time, sorry that you can't open the picking operation on the picklist.`,
  key1005765: `The pick list ({picklist}) you scan or enter is picking. Each picklist allows only one operator to do picking at the same time and cannot be reopened.`,
  key1005766: `The pick list ({picklist}) you scan or enter has been sorted, and you can reopen the picking operation on the picklist.`,
  key1005767: `The pick list [{pickList}] you scanned or entered is undergoing picking review, and the sorting operation cannot be started.`,
  key1005768: `The picking list [{pickList}] you scanned or entered has completed the picking review, and the sorting operation cannot be started.`,
  key1005769: `There are common print delivery orders under the pick list 【 {pickList} 】 you scanned or entered.`,
  key1005770: `Scanning/Inputting Order Number to Start Multi-item Picking`,
  key1005771: `On-going picking operations`,
  key1005772: `The pick basket cannot find the only pick list that is not packaged.`,
  key1005773: `There is no pick list to be completed in the pick basket.`,
  key1005774: `There are unfinished picking job opened by you.`,
  key1005775: `The start time of picking operation:`,
  key1005776: `Misoperation.I don't need to re-sort.`,
  key1005777: `Yes, I need to re-sort this order.`,
  key1005778: `Picking state`,
  key1005779: `Picking`,
  key1005780: `Picking completed`,
  key1005781: `Operator`,
  key1005782: `No permission`,
  key1005783: `Partial picking`,
  key1005784: `Waiting for sorting`,
  key1005785: `Waiting for review`,
  key1005786: `Reviewing`,
  key1005787: `Reviewed`,
  key1005788: `Normal printing`,
  key1005789: `See delivery order`,
  key1005790: `Picking reminder`,
  key1005791: `Please confirm whether to cancel`,
  key1005792: `Successfully exported!`,
  key1005793: `Picking list generation time:`,
  key1005794: `Single-product rules saving`,
  key1005795: `Multi-product rules saving`,
  key1005796: `Single-product rules`,
  key1005797: `Multi-product rules`,
  key1005798: `Merge rules`,
  key1005799: `time setting`,
  key1005800: `Fixed cycle`,
  key1005801: `Every time:`,
  key1005802: `Hour generate picking order`,
  key1005803: `Daily time`,
  key1005804: `every day:`,
  key1005805: `Whether to turn on`,
  key1005806: `Please fill in the time`,
  key1005807: `Normal picking order`,
  key1005808: `Automatic picking waves`,
  key1005809: `Add outbound orders in batches`,
  key1005810: `Please separate multiple entries with commas or newlines`,
  key1005811: `Export bill of lading data`,
  key1005812: `Add new box/bag`,
  key1005813: `Add Parcel`,
  key1005814: `Do you want to delete the current bill of lading number?`,
  key1005815: `Please enter the outbound order!`,
  key1005816: `The package does not exist.`,
  key1005817: `The package is not out of the warehouse`,
  key1005818: `The package has been billed`,
  key1005819: `Unpicked/printed outbound order details`,
  key1005820: `At least one of the unpicked quantities in the same outbound order must be greater than 0.`,
  key1005821: `printing time:`,
  key1005822: `Please enter the picking order no.`,
  key1005823: `Please enter the waybill number. Multiple waybill numbers can be separated by commas in English.`,
  key1005824: `Please enter the outbound order no.`,
  key1005825: `Please enter the order No.`,
  key1005826: `Please enter the customer's name.`,
  key1005827: `Please enter the customer ID.`,
  key1005828: `You can enter Order No., Outbound order no., Customer ID, Customer name or SKU.`,
  key1005829: `Multiple storage areas, separated by commas or newlines`,
  key1005830: `Is there a waybill number:`,
  key1005831: `Is there a logistics company:`,
  key1005832: `Is there a logistics provider weight:`,
  key1005833: `Is there a logistics provider freight:`,
  key1005834: `previous`,
  key1005835: `The following SKU list is a sum of the SKUs contained in the selected package. Please select the out-of-stock SKU. After marking as out-of-stock, the system will automatically restore the package to the out-of-stock state and restore the inventory of the selected SKU. In the meantime, the stock check form of selected SKU will be created to help check and correct inventory quantity.`,
  key1005836: `by Print Time`,
  key1005837: `Payment date`,
  key1005838: `By outbound order no.`,
  key1005839: `Customer ID/Name`,
  key1005840: `Mark as shipped`,
  key1005841: `Please select package`,
  key1005842: `mark as outbound`,
  key1005843: `Mark as outbound (The system will mark all the selected packages as outbound)`,
  key1005844: `The system will mark the package that matches your selection as shipped.`,
  key1005845: `Mark as outbound(All)`,
  key1005846: `When you mark packages as outbound(All), system will mark all the packages which meet current query conditions as outbound.`,
  key1005847: `Please select marked orders.`,
  key1005848: `pick baskets`,
  key1005849: `Print address label (selected)`,
  key1005850: `Packaging material`,
  key1005851: `Labels reprint`,
  key1005852: `Picking review`,
  key1005853: `没有正在扫描的拣货单`,
  key1005854: `The currently selected data has been printed!`,
  key1005855: `Box/bag management`,
  key1005856: `Scan and Pack`,
  key1005857: `Freeze distribution`,
  key1005858: `Unfreeze and distribute`,
  key1005859: `Inbound order`,
  key1005860: `Freeze order`,
  key1005861: `Product ID`,
  key1005862: `Storage bin for use`,
  key1005863: `Storage bin for receipt`,
  key1005864: `Storage bin for picking`,
  key1005865: `Please input SKU`,
  key1005866: `Source merchant:`,
  key1005867: `Identity code:`,
  key1005868: `Export outbound order data`,
  key1005869: `Failed to void outbound order`,
  key1005870: `Add outbound order`,
  key1005871: `Please enter the tracking no., multiple tracking no. can be separated by commas`,
  key1005872: `Please enter the logistics order no., multiple logistics order no. can be separated by commas`,
  key1005873: `Outbound time`,
  key1005874: `This delivery order is a supplementary order generated by the actual shipment quantity exceeding the required quantity in other delivery orders.`,
  key1005875: `Source channel`,
  key1005876: `Source merchants`,
  key1005877: `Tracking no./logistics no.`,
  key1005878: `Logistics weight/logistics freight`,
  key1005879: `Download the menu`,
  key1005880: `Associated order number`,
  key1005881: `Customer Name`,
  key1005882: `Address`,
  key1005883: `Zip code`,
  key1005884: `Print Time`,
  key1005885: `Printed by`,
  key1005886: `Actual weight`,
  key1005887: `Weight of logistics provider (g)`,
  key1005888: `organization`,
  key1005889: `Item SKU`,
  key1005890: `Item ID`,
  key1005891: `Multi-product sorting - end sorting`,
  key1005892: `Outbound quantity`,
  key1005893: `items(Picked/Unpicked)`,
  key1005894: `The new order is successful!`,
  key1005895: `扫描拣货单号/出库单号/运单号/`,
  key1005896: `面单：`,
  key1005897: `The outbound order is empty and cannot be operated to end the packing!`,
  key1005898: `Please scan the bill of lading number first!`,
  key1005899: `Please enter the bill of lading number!`,
  key1005900: `Please enter the picking list number/outbound order number/waybill number!`,
  key1005901: `Do you want to delete the current outbound order number?`,
  key1005902: `(Invalid)`,
  key1005903: `Storage area group`,
  key1005904: `Quantity of picks`,
  key1005905: `ID:`,
  key1005906: `Scan SKU barcode or UPC`,
  key1005907: `Scannable SKU`,
  key1005908: `Scan the ID code:`,
  key1005909: `Scannable ID`,
  key1005910: `Mark exception`,
  key1005911: `Packaging settings`,
  key1005912: `abnormal`,
  key1005913: `Check SKU`,
  key1005914: `Package scanned`,
  key1005915: `Unpack`,
  key1005916: `Continue packaging`,
  key1005917: `Name of package`,
  key1005918: `Quantity to be packed`,
  key1005919: `Grams (g)`,
  key1005920: `Kilogram (Kg)`,
  key1005921: `Quantity to be packed`,
  key1005922: `Product weight (g)`,
  key1005923: `The current package has a SKU which has not been scanned yet.`,
  key1005924: `There is still an ID code in the current package that has not been scanned!`,
  key1005925: `The currently scanned outbound order number or waybill number or logistics business order number has been out of the warehouse!`,
  key1005926: `The ID number cannot be empty!`,
  key1005927: `The current scanned quantity cannot be greater than the quantity to be packaged!`,
  key1005928: `The sku does not exist!`,
  key1005929: `SKU cannot be empty!`,
  key1005930: `The weighing cannot be empty and must be greater than 0!`,
  key1005931: `Please scan the ID number first!`,
  key1005932: `The scanned quantity cannot be all equal to the quantity to be packaged, otherwise the exception cannot be marked!`,
  key1005933: `The exception has been successfully marked.`,
  key1005934: `The package has been scanned successfully!`,
  key1005935: `Number of reprints`,
  key1005936: `Quantity printed`,
  key1005937: `Scan completed`,
  key1005938: `Match failed`,
  key1005939: `Multiple orders separated by commas`,
  key1005940: `SKU/quantity:`,
  key1005941: `Brand:`,
  key1005942: `Delivery reminder:`,
  key1005943: `Picking list generation settings`,
  key1005944: `Category`,
  key1005945: `Picking storage area group`,
  key1005946: `Storage area`,
  key1005947: `Label name cannot be empty`,
  key1005948: `Select files to import:`,
  key1005949: `Select file`,
  key1005950: `Download template`,
  key1005951: `There is an empty Tongtool sku.`,
  key1005952: `There is an emptyskuCode`,
  key1005953: `TongdaoskuThe system does not exist`,
  key1005954: `skuCode The system does not exist`,
  key1005955: `The first journey cost must be a number`,
  key1005956: `Cancel package`,
  key1005957: `Check reset delivery order to generate an issue document again`,
  key1005958: `Reset delivery order`,
  key1005959: `This is the record after the delivery order has been reset，Will return to【Waiting for allocation】state,At the same time, the delivery order will perform the following operations：`,
  key1005960: `1.If the delivery order has been successfully distributed, it will try to call the logistics provider interface to cancel the order`,
  key1005961: `2.If the inventory has been allocated successfully in the issue document, the allocated inventory will be returned`,
  key1005962: `Code:`,
  key1005963: `Input Code Name`,
  key1005964: `Account Name:`,
  key1005965: `Please enter an authorized platform account`,
  key1005966: `Expiration time:`,
  key1005967: `Account name`,
  key1005968: `Authorization time`,
  key1005969: `Data synchronization`,
  key1005970: `Select a warehouse`,
  key1005971: `Selecting multiple warehouses will synchronize the total inventory of all warehouses.`,
  key1005972: `Synchronize warehouse`,
  key1005973: `Authorization`,
  key1005974: `New products`,
  key1005975: `Please check the warehouse first!`,
  key1005976: `库存 =`,
  key1005977: `Main warehouse actual inventory`,
  key1005978: `Order occupancy`,
  key1005979: `Warehouse waiting quantity`,
  key1005980: `Virtual Inventory`,
  key1005981: `Procurement in transit quantity`,
  key1005982: `Sales return inventory`,
  key1005983: `Incoming warehouse inventory`,
  key1005984: `Defective inventory`,
  key1005985: `库存 = `,
  key1005986: `Rule item cannot be empty!`,
  key1005987: `You do not have permission to access this resource, please contact the administrator`,
  key1005988: `The system is busy, please try again (the interface reports an error, please check the interface error on the console)`,
  key1005989: `The account has been disabled.`,
  key1005990: `供应商结算状态需为等待结算且`,
  key1005991: `出库状态需为已出库`,
  key1005992: `客户结算状态需为等待结算且`,
  key1005993: `Failed to confirm payment of the bill, the bill has been voided`,
  key1005994: `Category ID already exists`,
  key1005995: `Parent node does not exist`,
  key1005996: `Category name cannot contain ->`,
  key1005997: `The name cannot contain spaces.`,
  key1005998: `Maximum classification level reached`,
  key1005999: `Category name at the same level already exists`,
  key1006000: `The email is already occupied`,
  key1006001: `The phone number is already used`,
  key1006002: `The package is not collected`,
  key1006003: `No relevant outbound order`,
  key1006004: `The bill of lading is not sorted`,
  key1006005: `There are multiple packages`,
  key1006006: `The status of the bill of lading must be collected or sorted`,
  key1006007: `Existing non-sorting status`,
  key1006008: `The outbound order is not in the picked state`,
  key1006009: `The handover order is not in the packed state`,
  key1006010: `Already a customer`,
  key1006011: `Graphic verification code error`,
  key1006012: `Verification code error`,
  key1006013: `Email not registered`,
  key1006014: `Repeat page submission`,
  key1006015: `SMS failed to be sent`,
  key1006016: `Failed to query user`,
  key1006017: `Mobile phone number is empty`,
  key1006018: `wrong password`,
  key1006019: `SMS verification code verification error`,
  key1006020: `Graphic verification code is invalid`,
  key1006021: `The number of errors exceeded, please enter the graphic verification code and try again`,
  key1006022: `repeated submit`,
  key1006023: `Non-current user mailbox`,
  key1006024: `Non-current user phone`,
  key1006025: `Login failed, user does not exist`,
  key1006026: `Please log in to register a distribution account with the admin account`,
  key1006027: `Successfully created a customer account by logging in to the Tongtu account. Once approved, access the account again`,
  key1006028: `The current user, please log in after passing the review`,
  key1006029: `Product does not exist`,
  key1006030: `Customer level is already bound to supplier level`,
  key1006031: `The supplier has set the supplier level`,
  key1006032: `The queried picking order number does not exist`,
  key1006033: `Brand name already exists`,
  key1006034: `Non-draft status cannot be deleted`,
  key1006035: `The Chinese name, English name, title, picture cannot be modified pending review`,
  key1006036: `不能包含逗号`,
  key1006037: `SKU with the same attributes exists.`,
  key1006038: `Duplicate SPU or SKU`,
  key1006039: `Variable parameter commodity SKU cannot repeat with SPU`,
  key1006040: `The product is being used by the mapped product and cannot be deleted.`,
  key1006041: `The package has a replenishment order to be completed.`,
  key1006042: `The SKU does not exist.`,
  key1006043: `There is a normal picking package in the abnormal package.`,
  key1006044: `The package has been cancelled.`,
  key1006045: `Failed to mark as shipped, this package has been cancelled for recycling`,
  key1006046: `Failed to complete the packaging, the packaging status has been changed!`,
  key1006047: `It is not a picking list for multi-products one package, and the sorting operation cannot be started.`,
  key1006048: `Each operator of the system is only allowed to start one sorting operation at the same time`,
  key1006049: `The picking list is currently being sorted by someone else`,
  key1006050: `The sorting operation has been completed, and you can restart the sorting operation for the pick list.`,
  key1006051: `There are common print delivery orders.`,
  key1006052: `The pick list is not printed and the picking review cannot be started`,
  key1006053: `Pick list completed.`,
  key1006054: `There is no outbound order under the pick list.`,
  key1006055: `There is no valid outbound order under the pick order.`,
  key1006056: `There is a product note under the pick list.`,
  key1006057: `There is an ordinary-print outbound list under the pick list.`,
  key1006058: `The pick list has not been multi-product sorting.`,
  key1006059: `There is an outbound order which is not issued to the logistics provider successfully under the pick list.`,
  key1006060: `The pick list is not completed.`,
  key1006061: `Sorting operation only supports pick lists for sales.`,
  key1006062: `sku and upc cannot be repeated`,
  key1006063: `upcupc has existed`,
  key1006064: `UPCDuplicate UPC`,
  key1006065: `Duplicate SKUs`,
  key1006066: `The pick list of non-sale outbound order does not support picking review.`,
  key1006067: `The package has been intercepted.`,
  key1006068: `The user is packing other pick lists.`,
  key1006069: `No inventory can be allocated, unable to allocate`,
  key1006070: `The package has been shipped out.`,
  key1006071: `Package information does not exist`,
  key1006072: `Box No. cannot be empty.`,
  key1006073: `SKU cannot be empty`,
  key1006074: `No corresponding outbound order found`,
  key1006075: `Outbound order No. error`,
  key1006076: `The SKU does not exist.`,
  key1006077: `Pick list does not exist.`,
  key1006078: `Failed to clear scan. The package has been scanned.`,
  key1006079: `Wrong boxing quantity`,
  key1006080: `There is only one package under the picking list, which cannot be removed`,
  key1006081: `The pick list has completed the picking review and cannot be re-sorted`,
  key1006082: `The warehouse is in inventory allocation, please try again later.`,
  key1006083: `The package has no items awaiting re-picking.`,
  key1006084: `The inbound order has been closed and cannot be received!`,
  key1006085: `The current package is not printed`,
  key1006086: `The number of scanned SKU does not match the number of packages`,
  key1006087: `Did not match a suitable outbound order`,
  key1006088: `Invalid key`,
  key1006089: `The number of scanned sku is inconsistent with the number of ID codes.`,
  key1006090: `The current bill of lading status is not in packing and cannot continue packing`,
  key1006091: `The sorting status of the picking list that ends the sorting is not sorting, and the sorting cannot be ended`,
  key1006092: `Cannot be repeatedly marked as picked`,
  key1006093: `Package status has changed and operation is abnormal`,
  key1006094: `Bill of lading number does not exist`,
  key1006095: `提单非等待揽收/正在装箱状态,`,
  key1006096: `不能删除`,
  key1006097: `The current attribute is being referenced and cannot be deleted`,
  key1006098: `Property with the same name already exists`,
  key1006099: `Please stop selling the deleted SKU and try again`,
  key1006100: `The SKU with inventory cannot be deleted.`,
  key1006101: `SKU already exists.`,
  key1006102: `订单已作废`,
  key1006103: `There is a non-waiting collection state`,
  key1006104: `The number of packages received by the bill of lading is not uniform`,
  key1006105: `Illegal attributes`,
  key1006106: `The Chinese name of the color already exists`,
  key1006107: `Duplicate library code`,
  key1006108: `Duplicate library area name`,
  key1006109: `Duplicate warehouse name`,
  key1006110: `Duplicate warehouse code`,
  key1006111: `该`,
  key1006112: `订单已换单无法取消`,
  key1006113: `The operator has completed the picking, please do not submit repeatedly`,
  key1006114: `It has been sorted and is in a packed state, please do not submit repeatedly`,
  key1006115: `Has been withdrawn successfully, please do not submit repeatedly`,
  key1006116: `Duplicate location number`,
  key1006117: `The pick list is being sorted`,
  key1006118: `没有匹配到待包装`,
  key1006119: `The current pick order status is not waiting for picking/picking status`,
  key1006120: `Picking list is not to be packaged or in package`,
  key1006121: `The status has changed, please refresh and try again!`,
  key1006122: `Only packages that have been changed can be scanned`,
  key1006123: `There are commodities under the category`,
  key1006124: `There are sub-categories under category`,
  key1006125: `Weight cannot be empty.`,
  key1006126: `剩下一个sku时`,
  key1006127: `不可以删除`,
  key1006128: `获取分类信息异常`,
  key1006129: `Non-logistics face order types are not allowed to be marked out of the warehouse`,
  key1006130: `面单异常`,
  key1006131: `It cannot map deleted attributes`,
  key1006132: `There is a mapping attribute, so it is not allowed to delete`,
  key1006133: `Bill of lading has been deleted`,
  key1006134: `There are mapping attributes under the changed category, please delete the mapping attributes first`,
  key1006135: `The customer number currently being recharged does not exist`,
  key1006136: `Under the same platform, the same site cannot exist/without a site, the same classification cannot exist`,
  key1006137: `There is no picture of the product, please upload in time`,
  key1006138: `The product has no color picture information, please upload it in time`,
  key1006139: `The logistics provider did not exist.`,
  key1006140: `The logistics provider name already exists.`,
  key1006141: `Platform account has been shut down.`,
  key1006142: `服务名称或者编号重复`,
  key1006143: `The logistics provider code has existed.`,
  key1006144: `The name already exists.`,
  key1006145: `Duplicate association of freight forwarding`,
  key1006146: `Store number already exists`,
  key1006147: `The store name already exists`,
  key1006148: `The store name and number already exist`,
  key1006149: `Authorized stores cannot switch stores`,
  key1006150: `Abnormal order status`,
  key1006151: `没有满足`,
  key1006152: `物流特殊规则的`,
  key1006153: `物流范围规则的`,
  key1006154: `Cancel order exception`,
  key1006155: `Failed to update user information`,
  key1006156: `Update logistics information abnormal`,
  key1006157: `Failed to create order`,
  key1006158: `Order has been placed`,
  key1006159: `The package has been out of the warehouse`,
  key1006160: `Processing the operation of placing logistics orders`,
  key1006161: `failed to cancel logistics`,
  key1006162: `Failed to void the sub-order in the supplier system`,
  key1006163: `The specified product information to be queried is empty`,
  key1006164: `Manual order failed`,
  key1006165: `Failed to generate shipping information`,
  key1006166: `物流渠道停用失败，已关联`,
  key1006167: `物流无法停用`,
  key1006168: `The logistics channel code cannot be repeated`,
  key1006169: `The package is not waiting for outbound`,
  key1006170: `The specified list of pick lists is empty`,
  key1006171: `中心仓备货的出库单已经生成拣货单，不允许作废`,
  key1006172: `面单正在拣货复核中`,
  key1006173: `出库单不是`,
  key1006174: `中心仓备货的出库单`,
  key1006175: `There is a supplier sub-order that has been outbound`,
  key1006176: `No product information corresponding to sku was found`,
  key1006177: `中心仓备货拣货单正在被扫描`,
  key1006178: `A notification message template of the same type already exists`,
  key1006179: `The merchant has not set a Listing address`,
  key1006180: `Role name already exists`,
  key1006181: `The role is being used.`,
  key1006182: `Default customer level not set`,
  key1006183: `The outbound order has been marked as "Out of stock"`,
  key1006184: `The supplier has not set up shipping directly, and shipping directly is not allowed`,
  key1006185: `The warehousing status of the current container is not "To switch bill"`,
  key1006186: `A container type with the same name already exists`,
  key1006187: `The current container type is already bound`,
  key1006188: `The container is not in the same warehouse as the bill of lading`,
  key1006189: `分拣容器中不存在该`,
  key1006190: `The container does not exist`,
  key1006191: `The container is bound`,
  key1006192: `The container status is abnormal or changed`,
  key1006193: `The container is empty and the sorting cannot be ended`,
  key1006194: `The number of bills of lading is abnormal, and there are SKU or waybills that have not been sorted`,
  key1006195: `The current container warehousing status is not "In switching bill".`,
  key1006196: `订单已被分拣`,
  key1006197: `The bill of lading is in sorting`,
  key1006198: `Container status does not exist`,
  key1006199: `The bill of lading status needs to be either received or sorted`,
  key1006200: `There is a bill of lading in sorting`,
  key1006201: `This bill of lading is in sorting by other  users`,
  key1006202: `A container of the same type has been bound`,
  key1006203: `Please complete the sticker job of the current container`,
  key1006204: `The container is in sticking waybill`,
  key1006205: `Please complete the publishing operation of the current container`,
  key1006206: `Containers are in publishing`,
  key1006207: `There is a container in the processing`,
  key1006208: `分拣交接单中已存在该`,
  key1006209: `订单需要先走商品分拣`,
  key1006210: `订单已发货，不可被移除`,
  key1006211: `The logistics is being processed, please try again later`,
  key1006212: `The current size type is being used`,
  key1006213: `The template is already in use, so deletion is not allowed`,
  key1006214: `The Chinese name of the body part has been used, and modification is not allowed`,
  key1006215: `The size value already exists`,
  key1006216: `The specification attribute is already in use, and the deletion is not allowed`,
  key1006217: `The specification attribute is already in use, modification is not allowed`,
  key1006218: `The current size value is in use and cannot be deleted`,
  key1006219: `The specification of the main attribute is already used, and modification is not allowed`,
  key1006220: `Export template exception`,
  key1006221: `Unauthorized Jushuitan Merchant`,
  key1006222: `Jushuitan Merchant not added`,
  key1006223: `No Jushuitan merchants with synchronized inventory checked and enabled were found`,
  key1006224: `Sku值为空`,
  key1006225: `SPUs that are not for sale cannot be discontinued`,
  key1006226: `The number of shelves and picking quantity are inconsistent`,
  key1006227: `The exported bill information is empty`,
  key1006228: `Phone is not registered`,
  key1006229: `SPU cannot contain Chinese`,
  key1006230: `The current size attribute template is currently in use`,
  key1006231: `An attribute with the same name already exists. Please modify the attribute name before submitting`,
  key1006232: `No warehouse has been created. Please create a warehouse first`,
  key1006233: `This order has been cancelled. Please replenish the goods offline to the central warehouse`,
  key1006234: `The packaged delivery note cannot be voided`,
  key1006235: `Multiple primary attributes present`,
  key1006236: `Failure to meet the conditions for duplicate publication`,
  key1006237: `The settled merchant does not exist`,
  key1006238: `Password verification error`,
  key1006239: `No recipient information found`,
  key1006240: `The picking list is not pending or in sorting`,
  key1006241: `The picking list has been cancelled`,
  key1006242: `The picking list is not in the packaging`,
  key1006243: `The delivery note being packed cannot be voided`,
  key1006244: `The end time of the activity cannot be less than the start time of the activity`,
  key1006245: `This product already exists in another activity`,
  key1006246: `Could not find a container of the corresponding type for the product. Please bind and rescan.`,
  key1006247: `WishPost is currently not authorized. Please authorize and associate the store in your own logistics management and try again`,
  key1006248: `The store corresponding to the order is not currently associated with a WishPost account. Please associate it in your own logistics management and try again`,
  key1006249: `WishPost authorization is invalid. Please re authorize in your own logistics management and try again`,
  key1006250: `The store is already associated with another account`,
  key1006251: `Associated store not enabled`,
  key1006252: `A questionnaire with the same name already exists`,
  key1006253: `A question with the same name already exists in the current questionnaire`,
  key1006254: `The warehouse has been associated with a supplier`,
  key1006255: `The scanned package and delivery note are shipped from different warehouses`,
  key1006256: `1688 platform unauthorized store`,
  key1006257: `Link does not exist`,
  key1006258: `Currency configuration already exists`,
  key1006259: `This picking list is a regular order, please go to the regular packaging module for operation`,
  key1006260: `This picking list is a preparation picking list. Please proceed to the preparation sorting for operation`,
  key1006261: `The customer has not yet executed the shipment. Please try again in the next round of operations`,
  key1006262: `No relevant package information found`,
  key1006263: `The current small package has been sorted and scanned`,
  key1006264: `There are small packages that have not been packaged yet`,
  key1006265: `There are small packages that have not been sorted by logistics. Please complete the sorting before closing the bags`,
  key1006266: `Failed to modify the order synchronization status to 'synchronizing'`,
  key1006267: `The warehouse where the logistics handover order is located is not authorized. Please apply for the corresponding warehouse permission`,
  key1006268: `The handover form does not exist`,
  key1006269: `Please activate Temu virtual logistics first`,
  key1006270: `Platform order already exists`,
  key1006271: `The logistics tracking number already exists`,
  key1006272: `Recharged currency cannot be modified`,
  key1006273: `Currency cannot be modified after product creation`,
  key1006274: `The ongoing operation is not in sorting or packaging`,
  key1006275: `The number of SKUs scanned exceeds the expected picking quantity on the picking list`,
  key1006276: `This attribute is already used as a specification attribute by an existing product and cannot be deleted`,
  key1006277: `Failed to restore inventory`,
  key1006278: `The bill has been generated, but the subsidy review failed`,
  key1006279: `物流仅支持绑定一条渠道`,
  key1006280: `Failed to upload package`,
  key1006281: `Failed to acquire waybills`,
  key1006282: `仓库地址不存在`,
  key1006283: `物流未关联基础物流`,
  key1006284: `Logistics provider failed to obtain token`,
  key1006285: `Scanning temu outbound orders is not allowed`,
  key1006286: `AliExpress OAID does not exist`,
  key1006287: `Decryption failed`,
  key1006288: `The bill has been generated, but the subsidy re review failed`,
  key1006289: `Abnormal barcode printing for the selected order`,
  key1006290: `Abnormal printing of SKU barcode`,
  key1006291: `存在非缺货中的货品无法标记到货`,
  key1006292: `No order for this product, please verify the order`,
  key1006293: `The bill of lading is not in a waiting for collection status`,
  key1006294: `The delivery order has been issued and no other operations are allowed`,
  key1006295: `There are orders in the current order with an outbound order that is not in a pending shipment status`,
  key1006296: `There is only one order in this picking list, and there is no need to sort it. Please proceed with the preparation and packaging operation`,
  key1006297: `This picking list is a regular order, please proceed to the regular sorting for operation`,
  key1006298: `This picking list is a backup list. Please proceed to the backup sorting for operation`,
  key1006299: `Failed to add goods and synchronize bill of lading operation`,
  key1006300: `There are orders with out of stock status in the current order`,
  key1006301: `The middle label code cannot be empty`,
  key1006302: `No shipping orders`,
  key1006303: `该拣货单存在在未整单完结的`,
  key1006304: `Manual orders and platform orders cannot be shipped simultaneously`,
  key1006305: `Goods orders and sample orders cannot be shipped simultaneously`,
  key1006306: `Orders from different stores cannot be shipped simultaneously`,
  key1006307: `Orders with different shipping addresses cannot be shipped simultaneously`,
  key1006308: `There are small bags with open bags in this order`,
  key1006309: `订单已经完结,不可以再次扫描`,
  key1006310: `The number of manually modified scans must be a positive integer greater than 0`,
  key1006311: `The selected batch of outbound orders exceeds the maximum limit. Please adjust the maximum number of packages`,
  key1006312: `The maximum number of outbound orders per picking list cannot be empty or less than 1`,
  key1006313: `The picking list is currently being processed by another user and cannot be processed again`,
  key1006314: `temu_s拣货单存在未打印`,
  key1006315: `The bill of lading and container belong to different warehouses. Please check and try again`,
  key1006316: `Failed to create order, insufficient customer balance`,
  key1006317: `非待匹配商品或匹配物流失败或下单失败状态，无法标记为非`,
  key1006318: `订单操作`,
  key1006319: `The title guide for this title already exists in the same system and module. Please redefine the title`,
  key1006320: `物流/基础物流不能同时传`,
  key1006321: `物流/基础物流必传其一`,
  key1006322: `Basic logistics trackingNumber, labelPath must be passed`,
  key1006323: `SKU has been discontinued`,
  key1006324: `Multiple warehouse matches for product shipment. Please split the platform order before placing the order`,
  key1006325: `Failed to match warehouse`,
  key1006326: `The available inventory of the product is empty or illegal`,
  key1006327: `Account not bound to store, or account has expired`,
  key1006328: `Customer label with the same name already exists`,
  key1006329: `The customer group name already exists`,
  key1006330: `The user has already started other picking operations`,
  key1006331: `The mobile phone is bound, not support updating mobile phone.`,
  key1006332: `Product category not mapped to platform`,
  key1006333: `Failed to obtain supplier currency interface`,
  key1006334: `Duplicate SPU, please modify`,
  key1006335: `Failed to query third-party platform orders`,
  key1006336: `Alipay payment order failed`,
  key1006337: `Failed to add recharge record`,
  key1006338: `Failed to obtain distributor currency`,
  key1006339: `未查询到可操作订单`,
  key1006340: `Failed to return shipment status`,
  key1006341: `Store address is empty`,
  key1006342: `The order in the logistics handover form is empty`,
  key1006343: `Multiple item sorting is not allowed to remove the picking list after the order has been changed`,
  key1006344: `Not applying for an appointment form`,
  key1006345: `The container type has already been used. Please delete the container that has already used this type before editing this container type`,
  key1006346: `No logistics tracking records found`,
  key1006347: `No valid logistics channels were matched`,
  key1006348: `存在下单失败的`,
  key1006349: `订单,请完成后再更换商品`,
  key1006350: `The customer level name already exists, please replace it and try again`,
  key1006351: `It is not allowed to modify the buyer's shipping address after being packed`,
  key1006352: `Email registration method: Email cannot be empty`,
  key1006353: `At least one email and mobile phone need to be filled out`,
  key1006354: `This logistics channel is currently unavailable. For more information, please consult customer service`,
  key1006355: `The selected product is currently generating data`,
  key1006356: `Failed to generate sales information`,
  key1006357: `已存在供应商账号`,
  key1006358: `请重新登录`,
  key1006359: `Distributor account already exists, please log in again`,
  key1006360: `The supplier account permission has not been opened yet. Please contact the main account to open it. If you have any questions, please contact online customer service for consultation!`,
  key1006361: `HTML type waybills only allow individual printing`,
  key1006362: `Sales price less than or equal to 0`,
  key1006363: `When marking out of stock, the out of stock quantity must be greater than 1`,
  key1006364: `This account is an operator system account. Please change the account and try again`,
  key1006365: `This shipping warehouse already has this type of expense configuration. Please check and try again`,
  key1006366: `No warehouse processing fee set`,
  key1006367: `Fixed billing does not set regular or multi item order processing fees`,
  key1006368: `Layered billing is not set with tiered pricing or multi frequency order processing fees`,
  key1006369: `The currently selected bill has already been confirmed`,
  key1006370: `This specification attribute has been used and cannot be modified as a regular attribute`,
  key1006371: `Address book with the same template name exists`,
  key1006372: `The note content is too long, adding failed`,
  key1006373: `Failed to update permission authorization for shelving tools`,
  key1006374: `The bill of lading where the backup note is located has been packed`,
  key1006375: `Same platform configuration already exists`,
  key1006376: `The specified platform is not configured`,
  key1006377: `No specified store configured`,
  key1006378: `No publishing template configured`,
  key1006379: `Store token invalid`,
  key1006380: `No timeout time set`,
  key1006381: `Please activate AliExpress fully managed logistics first`,
  key1006382: `Incorrect IOSS encoding format`,
  key1006383: `Target country tax rate setting already exists`,
  key1006384: `IOSS configuration with the same name already exists`,
  key1006385: `Some of the raw materials are being used in assembled products and cannot be deleted.`,
  key1006386: `Platform order IOSS has been entered`,
  key1006387: `Logistics agent payment, customs clearance fee cannot be empty`,
  key1006388: `This logistics channel has been deactivated. Please select a new logistics channel`,
  key1006389: `There is data that has already generated sales information`,
  key1006390: `The distributor's stock order is not in a pending payment status`,
  key1006391: `Abnormal verification of the quantity of goods in the distributor's cancellation of the stock order`,
  key1006392: `The distributor's stock order is not in the status of awaiting price adjustment`,
  key1006393: `The distributor's stock order is not in the supplier's pending price status`,
  key1006394: `Failed to list inventory on the allocation order`,
  key1006395: `Set assembly items to include deleted items`,
  key1006396: `Set the original materials of the assembled goods to include the assembled goods`,
  key1006397: `The raw materials for assembling the product cannot be consistent with the current product`,
  key1006398: `There is an export template with the same name`,
  key1006399: `Unconfigured virtual storage location for assembled products`,
  key1006400: `Failed to assemble raw materials and finished products into and out of the warehouse`,
  key1006401: `Logistics handover form not in sorting status`,
  key1006402: `可操作单数量与选择不一致`,
  key1006403: `没有匹配买家邮政编码的`,
  key1006404: `可操作单数量于选择不一致`,
  key1006405: `Failed to save declaration information`,
  key1006406: `Unsupported inventory operations`,
  key1006407: `Inventory operation failed`,
  key1006408: `Failed to retrieve supplier data through interface call`,
  key1006409: `The current bill of lading is not owned by the logistics company and is in a waiting for collection status. The logistics cannot be modified`,
  key1006410: `货品分配数失败`,
  key1006411: `撤回`,
  key1006412: `Speed regulating AliExpress failed to create collection interface`,
  key1006413: `Printing collection waybill image access failed`,
  key1006414: `Speed regulating AliExpress fully managed printing collection waybill interface failed`,
  key1006415: `AliExpress reservation delivery interface call API exception`,
  key1006416: `Exceeded the logistics size limit. Please change the logistics and try again`,
  key1006417: `Exceeded the weight limit of this logistics. Please change the logistics and try again`,
  key1006418: `Beyond the delivery range of this logistics, please change the logistics and try again`,
  key1006419: `The pending order status cannot match the product`,
  key1006420: `不同目的仓订单请分开发货`,
  key1006421: `由于货品数量过多，请在详情中进行单个打印`,
  key1006422: `商品属性未填写属性占比`,
  key1006423: `当前交接单内存在已取消的包裹，请移除已取消包裹后重试`,
  key1006424: `存在非已装箱的订单`,
  key1006425: `此商品已停售，`,
  key1006426: `仓库存数量小于订单货品数量，暂无法下单`,
  key1006427: `生成拣货单失败`,
  key1006428: `存在分销商未取消订单，请稍后再试`,
  key1006429: `当前物流不可达，请取消订单后重新下单`,
  key1006430: `暂未开通分销商账号权限，请联系主账号开通，如有疑问，请联系在线客服咨询！`,
  key1006431: `更换收货地址取消`,
  key1006432: `order anomaly`,
  key1006433: `更换收货地址失败，订单已取消`,
  key1006434: `存在重复快递单号，此包裹请通过提单号进行扫描`,
  key1006435: `非等待揽收状态不可作废`,
  key1006436: `作废供应商提单失败`,
  key1006437: `站点名称已经被使用，请更改其他站点名`,
  key1006438: `仅支持已创建、备货中订单进行取消，请刷新数据查看`,
  key1006439: `未设置云卖物流规则`,
  key1006440: `回传样品发货信息失败`,
  key1006441: `获取大仓收货地址失败`,
  key1006442: `商品条码查询失败`,
  key1006443: `查询寄样单列表`,
  key1006444: `未匹配到发货方式`,
  key1006445: `物流代缴，清关费不可为空`,
  key1006446: `无有效的发货信息`,
  key1006447: `平台单不存在交易信息`,
  key1006448: `订单已发货`,
  key1006449: `出库单非已出库状态`,
  key1006450: `商品非待审核状态`,
  key1006451: `已关注该店铺`,
  key1006452: `商品分类信息不存在`,
  key1006453: `已取消收藏`,
  key1006454: `当前待上架提单不是已入库状态`,
  key1006455: `重置包裹状态失败`,
  key1006456: `未匹配到仓库，请联系客服处理`,
  key1006457: `容器非货品分拣完成状态`,
  key1006458: `已经处于完成上架状态，请勿重复操作`,
  key1006459: `该货品已经分拣完成`,
  key1006460: `未设置刊登规则`,
  key1006461: `未匹配到库区，请联系客服处理`,
  key1006462: `SKU与货位号不匹配，请检查后重试`,
  key1006463: `SKU已完拣`,
  key1006464: `账单不是付款状态`,
  key1006465: `必传其一`,
  key1006466: `供应商未设置归属仓库`,
  key1006467: `未完全分配ymsWarehouseLocation`,
  key1006468: `速卖通线上发货UserNick必传`,
  key1006469: `包裹所在仓未授权`,
  key1006470: `物流交接单仓库与包裹仓库不匹配`,
  key1006471: `物流交接单仓库与包裹物流不一致`,
  key1006472: `缺少包裹`,
  key1006473: `物流商编号已存在`,
  key1006474: `三方平台不存在`,
  key1006475: `获取店铺的发货,退货地址失败`,
  key1006476: `当前云卖订单未发货`,
  key1006477: `当前订单物流方式不是云卖物流`,
  key1006478: `速卖通打印面单接口失败`,
  key1006479: `单次最多可处理200个包裹，请移出后再结袋`,
  key1006480: `分销商备货单已经支付，不可再次支付`,
  key1006481: `Category does not exist`,
  key1006482: `订单编号查询订单Id集合异常`,
  key1006483: `包裹信息保存失败`,
  key1006484: `获取物流商异常`,
  key1006485: `打印面单异常`,
  key1006486: `订单已经存在包裹`,
  key1006487: `物流商帐号不存在`,
  key1006488: `存在未回收包裹`,
  key1006489: `包裹订单数据异常`,
  key1006490: `获取订单异常`,
  key1006491: `更新订单打印时间失败`,
  key1006492: `发货系统包裹数据与订单系统数据不一致`,
  key1006493: `出库单下发物流未成功`,
  key1006494: `出库单标记打印失败,部分包裹已经进行其他打印方式`,
  key1006495: `包裹未交运`,
  key1006496: `无效的可用库存数请输入大于0可用库存数`,
  key1006497: `发货台内当前无商品，无法打印配货清单`,
  key1006498: `修改备货单货品数量失败`,
  key1006499: `当前提单非等待揽收状态，不可修改物流`,
  key1006500: `当前提单非自有物流，不可修改物流`,
  key1006501: `获取仓库异常`,
  key1006502: `更新包裹包装状态异常`,
  key1006503: `用户没有开启分拣时打印地址面单`,
  key1006504: `There are products using this brand and cannot be deleted!`,
  key1006505: `提交存在相同属性`,
  key1006506: `变参商品空属性`,
  key1006507: `属性key不一致`,
  key1006508: `删除SKU异常`,
  key1006509: `The upload path is empty.`,
  key1006510: `普通商品spu与sku必须一致`,
  key1006511: `变参商品属性不能为空`,
  key1006512: `特性标签包含逗号`,
  key1006513: `非变参不能添加格外sku`,
  key1006514: `spu不能为空`,
  key1006515: `cnName不能为空`,
  key1006516: `Classification ID cannot be empty`,
  key1006517: `productGoodsList不能为空`,
  key1006518: `sku未匹配`,
  key1006519: `请求参数为空`,
  key1006520: `取消分配失败`,
  key1006521: `取消分配失败出库单不存在`,
  key1006522: `取消分配失败已发货、已取消、已生成拣货单或已拣货的不可取消`,
  key1006523: `取消分配失败删除拣货单失败`,
  key1006524: `取消分配失败还原原库存数据`,
  key1006525: `(分配之前`,
  key1006526: `)失败`,
  key1006527: `取消分配失败修改库存产品明细数据失败`,
  key1006528: `(已分配数量`,
  key1006529: `取消分配失败产品分配记录删除失败`,
  key1006530: `操作出库单失败`,
  key1006531: `明细数据为空`,
  key1006532: `未找到出库单`,
  key1006533: `操作货品失败`,
  key1006534: `拣货状态不正确`,
  key1006535: `发货状态状态不正确`,
  key1006536: `物流地址为空，请填写物流地址`,
  key1006537: `地址不匹配，请联系在线客服咨询`,
  key1006538: `调用支付宝转账异常`,
  key1006539: `同步平台地址失败`,
  key1006540: `Only pending customers can proceed with the audit, please reselect`,
  key1006541: `速卖通接口异常`,
  key1006542: `pms物流账号token为空`,
  key1006543: `包裹号为空`,
  key1006544: `商品链接为空`,
  key1006545: `物流取消异常`,
  key1006546: `获取ioss税率配置失败`,
  key1006547: `当前订单不是手工单`,
  key1006548: `未查询到客户编号所对应的联系人`,
  key1006549: `未查询到库存统计表中要更新的数据YmsInventoryWarehouse`,
  key1006550: `修改alibabagj收货地址失败`,
  key1006551: `确认订单付款报错，接口异常`,
  key1006552: `阿里国际平台单非手工取消`,
  key1006553: `发货重试`,
  key1006554: `未找到已匹配商品的分销商平台订单`,
  key1006555: `wms发货失败`,
  key1006556: `未找到符合订单条件的云卖物流`,
  key1006557: `wms取消失败`,
  key1006558: `仓库为空`,
  key1006559: `当前拣货单中包含状态为非`,
  key1006560: `待拣货/拣货中`,
  key1006561: `包裹下的ordershipingid已经存在`,
  key1006562: `获取发货设置异常`,
  key1006563: `出库单列表数量无效`,
  key1006564: `分配库存异常`,
  key1006565: `未出库的包裹`,
  key1006566: `更新账单和出库单状态失败`,
  key1006567: `The same site cannot exist under the same platform`,
  key1006568: `请确认商户号是否正确`,
  key1006569: `拣货单正在包装中`,
  key1006570: `拣货单不是待拣货或拣货中`,
  key1006571: `The current YMS order is being reviewed during picking`,
  key1006572: `商品状态错误`,
  key1006573: `拣货单状态异常`,
  key1006574: `获取包装设置异常`,
  key1006575: `Does not match the specified YMS order`,
  key1006576: `未获取面单异常`,
  key1006577: `库区名称name重复`,
  key1006578: `库区库位不能都为null`,
  key1006579: `可用库存不能小于0`,
  key1006580: `Exception of YMS order`,
  key1006581: `无效的仓储库存信息ID`,
  key1006582: `仅支持对待审核状态账单进行批量审核，请检查后重试`,
  key1006583: `仅支持对待打款状态账单进行批量审核，请检查后重试`,
  key1006584: `无此API权限，请联系客服开通`,
  key1006585: `备货单拆单失败`,
  key1006586: `订单同名标签已存在`,
  key1006587: `Edit Product`,
  key1006588: `Size Chart Template`,
  key1006589: `Add/Edit Size Chart Template`,
  key1006590: `Pending list`,
  key1006591: `Review/Edit products`,
  key1006592: `Supplier order details`,
  key1006593: `Distributor Stock List`,
  key1006594: `Supplier Stock List`,
  key1006595: `Supplier Stock Order Details`,
  key1006596: `Warehousing management`,
  key1006597: `Bill of Lading Management`,
  key1006598: `Bill of lading management details`,
  key1006599: `View the details of the outbound order`,
  key1006600: `Picking list`,
  key1006601: `Setting of consignment order`,
  key1006602: `Logistics provider handover order management`,
  key1006603: `Logistics provider handover order management details`,
  key1006604: `Supplier Management`,
  key1006605: `Supplier list`,
  key1006606: `供应商审核`,
  key1006607: `Supplier level management`,
  key1006608: `customer management`,
  key1006609: `Customer List`,
  key1006610: `Tuoke Link`,
  key1006611: `Supplier bill`,
  key1006612: `Supplier billing details`,
  key1006613: `Customer billing details`,
  key1006614: `Logistics billing details`,
  key1006615: `Warehouse billing details`,
  key1006616: `Password management`,
  key1006617: `Logistics Management`,
  key1006618: `Basic logistics`,
  key1006619: `Intelligent Logistics Rules`,
  key1006620: `Warehouse management`,
  key1006621: `Warehouse management`,
  key1006622: `Storage bin management`,
  key1006623: `Message template`,
  key1006624: `Container management`,
  key1006625: `Container type settings`,
  key1006626: `Address library management`,
  key1006627: `Configuration of inbound and outbound processes`,
  key1006628: `IOSS tax rate setting`,
  key1006629: `Declaration rules`,
  key1006630: `Automatic warehousing rules`,
  key1006631: `Content management`,
  key1006632: `questionnaire survey`,
  key1006633: `Edit questionnaire`,
  key1006634: `Operating instructions`,
  key1006635: `Data Center`,
  key1006636: `marketing management `,
  key1006637: `站点管理`,
  key1006638: `Event Management`,
  key1006639: `Add/Edit Activity`,
  key1006640: `Advertising management`,
  key1006641: `Distributor fee allocation`,
  key1006642: `View messages and Email`,
  key1006643: `Employee Management`,
  key1006644: `Task Center`,
  key1006645: `Import Task`,
  key1006646: `Export task`,
  key1006647: `Order Center`,
  key1006648: `Order list`,
  key1006649: `Stock Order Details`,
  key1006650: `Shipping Record`,
  key1006651: `Shipment Record Details`,
  key1006652: `Product Center`,
  key1006653: `Add/Edit products`,
  key1006654: `Brand management`,
  key1006655: `Third party platforms`,
  key1006656: `Platform Authorization`,
  key1006657: `Reconciliation management`,
  key1006658: `Account management`,
  key1006659: `Collection account`,
  key1006660: `Outbound order details`,
  key1006661: `Bill of lading`,
  key1006662: `order picking `,
  key1006663: `Outbound operation`,
  key1006664: `Outbound Job Settings`,
  key1006665: `商城`,
  key1006666: `分销市场`,
  key1006667: `Favorite Items`,
  key1006668: `Generate sales records`,
  key1006669: `Automatic rules`,
  key1006670: `Store management`,
  key1006671: `Favorite suppliers`,
  key1006672: `Platform order details`,
  key1006673: `Add Temu Order`,
  key1006674: `Right Management`,
  key1006675: `Role Management`,
  key1006676: `User Management`,
  key1006677: `Details of price changes`,
  key1006678: `Account management`,
  key1006679: `Security Information`,
  key1006680: `List of products and stores`
}
