
import { alias1bddc4d174174d47a6dfb0437dace856 } from '@/customFolder/customVueAlias.js';
/**
 * @Description: 处理对应code码对应的动态错误信息
 * @author jing
 * @date 2023/7/13 15:52
 * @params:
 */
import {isJSON, isValueEmpty, setConfigCharacters} from "@/utils/common";

// 处理数组数据问题
function handleArrayData(data) {
  if (Array.isArray(data)) {
    return data.join('、');
  } else {
    return data;
  }
}

export const dynamicErrorCodeFun = (data, value) => {
  if (data) {
    let codeObj = {};
    let code = data.code;
    let message = data.others ? data.others.errorMessage : '';
    let jsonTalg = isJSON(message);
    let text = '';
    if (jsonTalg) {
      let obj = JSON.parse(message);
      let ymsWarehouseName = '';
      let originalYmsWarehouseData = localStorage.getItem('originalYmsWarehouseData') ? JSON.parse(localStorage.getItem('originalYmsWarehouseData')) : [];
      if (originalYmsWarehouseData.length > 0) {
        if (obj.ymsWarehouseId) {
          ymsWarehouseName = originalYmsWarehouseData.find(item => item.ymsWarehouseId === obj.ymsWarehouseId).name;
        }
      }
      if (obj.ymsSpus) {
        codeObj['112812'] = `${obj.ymsSpus.join('、')}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001479')}${ymsWarehouseName}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001480')}`
      }
      if (!isValueEmpty(obj.message)) {
        codeObj['1112810'] = `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001481')}${obj.message}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001482')}`
      }
      for (let key in codeObj) {
        if (Number(key) === code) {
          text = codeObj[key]
        }
      }
    } else {
      codeObj = {
        220029: `SKU: ${message} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001483')}`,
        220031: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001484')}${message} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001485')}`,
        220032: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001486')} ${message} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001487')}`,
        1112765: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001488')} ${message}`,
        1112772: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001489')} ${message}`,
        1112777: `${message}`,
        1112778: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001490')} ${message} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001491')}`,
        111294: `${message} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001492')}`,
        111297: `${message}`,
        1112759: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001493')}${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001494')} ${message}`,
        1112764: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001495')} ${message}`,
        1112803: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001496')}${message}`,
        111190: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001497')}${handleArrayData(message)}`,
        112834: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001498')}${message} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001499')}`,
        1112739: `${message}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001500')}`,
        122008: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001501')}${message}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001502')}`,
        1112760: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001503')}${message}`
      }
      for (let key in codeObj) {
        if (Number(key) === code) {
          text = codeObj[key]
        }
      }
    }
    return text;
  }
}