import Vue from 'vue';
import VueI18n from 'vue-i18n';
import local7e1f2916c1e646f6883add8dd14b14c8 from './lang/en-US';
import en_US from 'view-design/dist/locale/en-US';
import localeb169c1d3d864613bc9bd6dd4a66d813 from './lang/zh-CN';
import zh_CN from 'view-design/dist/locale/zh-CN';

Vue.use(VueI18n);
function handleSystemLanguage() {
  let language = '';
  let defaultLanguage = window.navigator.language;
  if (defaultLanguage) {
    if (['zh', 'zh-CN'].includes(defaultLanguage)) {
      language = 'zh-CN';
    } else if (['en', 'en-US'].includes(defaultLanguage)) {
      language = 'en-US';
    } else {
      language = 'zh-CN';
    }
    return language;
  }
}
let lang = localStorage.getItem('lang')　|| handleSystemLanguage() || 'zh-CN';
localStorage.setItem('lang', lang);
Vue.config.lang = lang;
Vue.locale('en-US', {...local7e1f2916c1e646f6883add8dd14b14c8, ...en_US});
Vue.locale('zh-CN', {...localeb169c1d3d864613bc9bd6dd4a66d813, ...zh_CN});

var localeMap = {"en-US":"English","zh-CN":"简体中文"}