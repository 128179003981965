
import { alias1bddc4d174174d47a6dfb0437dace856 } from '@/customFolder/customVueAlias.js';
import axios from 'axios';
import Vue from 'vue';
import {removeToken} from '@/utils/cookie';
import api from '@/api/';
import {Message, Modal} from 'view-design';
import {getToken} from '@/utils/cookie';
import supplierRouter from "@/views/supplier/router";
import {getHrefPath, errorUrlHandel, deleteTicket, setConfigCharacters} from "@/utils/common";

Vue.component('Message', Message);
Vue.component('Modal', Modal);

function logoutFun() {
  axios.get(api.get_logout + getToken()).then(response => {
    if (response.data.code === 0) {
      removeToken().then(() => {
        let url = deleteTicket(window.location.href);
        url = errorUrlHandel(url);
        window.location.href = response.data.others.logOutUrl + encodeURIComponent(url);
      });
    }
  });
}

export const showNoAuth = () => {
  document.getElementById('app').innerHTML = '';
  Modal.warning({
    title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001130'),
    transfer: true,
    zIndex: 1000,
    content: '<p>' + alias1bddc4d174174d47a6dfb0437dace856.t('key1001131') + '</p><p>' + alias1bddc4d174174d47a6dfb0437dace856.t('key1001132') + '</p>',
    okText: alias1bddc4d174174d47a6dfb0437dace856.t('key1000072'),
    onOk: () => {
      logoutFun();
    }
  });
};

// 待确认账单的提示框
export const showBillConfirmed = () => {
  Modal.confirm({
    title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001133'),
    transfer: true,
    zIndex: 1000,
    content: alias1bddc4d174174d47a6dfb0437dace856.t('key1001134'),
    okText: alias1bddc4d174174d47a6dfb0437dace856.t('key1001135'),
    cancelText: alias1bddc4d174174d47a6dfb0437dace856.t('key1001136'),
    onOk: () => {
      supplierRouter.push('/reconciliationManage');
    }
  });
};

// 供应商停用提示框

export const supplierDeactivationPrompt = () => {
  Modal.warning({
    title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001137'),
    transfer: true,
    zIndex: 1000,
    content: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001124')}${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001125')}`,
    okText: alias1bddc4d174174d47a6dfb0437dace856.t('key1000138'),
    onOk: () => {
      logoutFun();
    }
  });
};

// 校验当前登录到供应商系统之后判断当前商户是否是没有通过审核，如果没有通过审核自动跳转到商城--供应商入驻页面
export const auditSuppliers = (message) => {
  let merchantId = null;
  if (message) {
    const match = message.match(/\d+/); // 使用正则表达式匹配数字部分
    if (match) {
      merchantId = match[0];
    } else {
      console.log('获取供应商商户号失败！');
    }
    if (merchantId) {
      let url = `/login.html#/supplierSettlement?supplierMerchantId=${merchantId}`;
      let service = '/yms-shopping-service';
      window.location.href = getHrefPath(url, service);
    }
  }
};

/**
 * 当前登录商户不是分销商，弹出是否要开通分销商的确认弹窗
 * 当前登录分销商系统商户是通途商户，弹出是否要开通分销商的确认弹窗
 * 当前登录商户不是供应商，弹出是否要开通供应商的确认弹窗
 */

export const becomeDistributor = (type) => {
  let title = '';
  let okText = '';
  let content = '';
  switch (type) {
    case'supplier':
      title = alias1bddc4d174174d47a6dfb0437dace856.t('key1001138');
      okText = alias1bddc4d174174d47a6dfb0437dace856.t('key1001139');
      content = `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001126')}${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001127')}${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001128')}`;
      break;
    case 'distributor':
      title = alias1bddc4d174174d47a6dfb0437dace856.t('key1001140');
      okText = alias1bddc4d174174d47a6dfb0437dace856.t('key1001141');
      content = `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001126')}${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001127')}${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001129')}`;
      break;
    case 'tongTool':
      title = alias1bddc4d174174d47a6dfb0437dace856.t('key1001140');
      okText = alias1bddc4d174174d47a6dfb0437dace856.t('key1001141');
      content = alias1bddc4d174174d47a6dfb0437dace856.t('key1001142');
      break;
    }

  Modal.confirm({
    title: title,
    transfer: true,
    zIndex: 1000,
    render: (h) => {
      return h('div', [
        h('becomingSupplierDistributorModal', {
          props: {
            systemType: type,
            content: content,
            okText: okText
          }
        })
      ]);
    }
  });
  setTimeout(() => {
    let confirmFooter = document.querySelectorAll('.ivu-modal-confirm-footer');
    confirmFooter[0].style.display = 'none';
  }, 500);
}

// 当前商户没有开通域名站点
export const noDomainNameSite = (message) => {
  Modal.warning({
    title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000255'),
    transfer: true,
    zIndex: 1000,
    content: `${message}`,
    okText: alias1bddc4d174174d47a6dfb0437dace856.t('key1000072'),
    onOk: () => {
      logoutFun();
    }
  });
};
