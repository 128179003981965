import Vue from 'vue';
import Router from 'vue-router';
import config from '@/config';
import {getToken} from '@/utils/cookie';
import {
  getAccessToken,
  refreshToken,
  getErpconfig,
  nonexistent,
  getSinglePageRoleCommon,
  getQueryMenu,
  getConfigureLanguage
} from '@/utils/user';

import {getYmsInfoConfig, isBillConfirmed} from '@/utils/common';

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(Router);
let countTime = config.curTime;
const routes = [
  {
    path: '/',
    redirect: '/statistics',
    component: () => import('@/components/layout/main'), // 引入主文件
    children: [
      {
        path: '/statistics', // 即时经营数据
        component: () => import('@/views/supplier/components/statistics/statistics')
      },
      {
        path: '/noPersermission',
        component: () => import('@/components/common/noPersermission')
      },
      {
        path: '/supplierOrderList', // 订单中心---订单列表
        component: () => import('@/views/supplier/components/supplierOrder/supplierOrderList')
      },
      {
        path: '/shipmentRecord', // 订单中心---发货记录
        component: () => import('@/views/supplier/components/supplierOrder/shipmentRecord')
      },
      {
        path: '/shipmentRecordDetails', // 订单中心---查看发货记录详情
        component: () => import('@/views/supplier/components/supplierOrder/shipmentRecordDetails')
      },
      {
        path: '/additionalGoods', // 订单中心---追加货品
        component: () => import('@/views/supplier/components/supplierOrder/additionalGoods'),
        meta: {
          parentPath: '/shipmentRecord'
        }
      },
      {
        path: '/stockList', // 订单中心---备货单
        component: () => import('@/views/supplier/components/supplierOrder/stockList')
      },
      {
        path: '/initiateStocking', // 订单中心---发起备货
        component: () => import('@/views/supplier/components/supplierOrder/initiateStocking'),
        meta: {
          parentPath: '/stockList'
        }
      },
      {
        path: '/stockOrderDetails', // 订单中心---备货单--备货单详情
        component: () => import('@/views/supplier/components/supplierOrder/stockOrderDetails'),
        meta: {
          parentPath: '/stockList'
        }
      },
      {
        path: '/productCenter/productGoods', // 商品中心---商品管理
        component: () => import('@/views/supplier/components/productCenter/productGoods')
      },
      {
        path: '/viewSku', // 商品中心---商品管理---商品列表---查看SKU
        component: () => import('@/components/common/viewSku'),
        meta: {
          parentPath: '/productCenter/productGoods'
        }
      },
      {
        path: '/productDetails', // 商品中心---商品管理---商品列表---新增/编辑商品
        component: () => import('@/components/common/productDetails'),
        meta: {
          parentPath: '/productCenter/productGoods'
        }
      },
      {
        path: '/collectProducts', // 商品中心---商品管理---商品列表---采集1688商品
        component: () => import('@/views/supplier/components/productCenter/collectProducts'),
        meta: {
          parentPath: '/productCenter/productGoods'
        }
      },
      {
        path: '/productCenter/productBrand', // 商品中心---品牌管理
        component: () => import('@/views/supplier/components/productCenter/productBrand')
      },
      {
        path: '/reconciliationManage', // 对账管理列表
        component: () => import('@/views/supplier/components/reconciliationManage/reconciliationBill')
      },
      {
        path: '/reconciliationBillDetails', // 对账管理列表详情
        component: () => import('@/views/supplier/components/reconciliationManage/reconciliationBillDetails'),
        meta: {
          parentPath: '/reconciliationManage'
        }
      },
      {
        path: '/messageCenter', // 消息中心
        component: () => import('@/views/supplier/components/messageManage/messageCenter')
      },
      {
        path: '/messageMailboxes', // 消息中心---短信、邮箱查看
        component: () => import('@/views/supplier/components/messageManage/messageMailboxes'),
        meta: {
          parentPath: '/messageCenter'
        }
      },
      {
        path: '/importTask', // 导入/导出任务--导入查看
        component: () => import('@/views/supplier/components/importExportTask/importTask')
      },
      {
        path: '/exportTask', // 导入/导出任务--导出查看
        component: () => import('@/views/supplier/components/importExportTask/exportTask')
      },
      {
        path: '/wms/inventoryLevels', // 仓储管理--库存余量
        component: () => import('@/views/supplier/components/wms/wms-inStock/inventoryTab')
      },
      {
        path: '/wms/generateOrderList', // 仓储管理--拣货--生成拣货单
        component: () => import('@/views/supplier/components/wms/exWarehouse/generateOrderListTab')
      },
      {
        path: '/wms/generateOrderDetails', // 仓储管理--拣货--生成拣货单详情
        component: () => import('@/views/supplier/components/wms/exWarehouse/sellStockOutDtl')
      },
      {
        path: '/wms/pickList', // 仓储管理--拣货--拣货单列表
        component: () => import('@/views/supplier/components/wms/exWarehouse/pickList')
      },
      {
        path: '/wms/pickListDetails', // 仓储管理--拣货--拣货单详情
        component: () => import('@/views/supplier/components/wms/exWarehouse/pickListDetails'),
        meta: {
          parentPath: '/wms/pickList'
        }
      },
      {
        path: '/wms/sortOrderSetting', // 仓储管理--见货出单---见货出单设置
        component: () => import('@/views/supplier/components/wms/exWarehouse/sortOrderSetting')
      },
      {
        path: '/wms/sorting', // 仓储管理--见货出单---多品分拣
        component: () => import('@/views/supplier/components/wms/exWarehouse/sorting')
      },
      {
        path: '/wms/packWorking', // 仓储管理--见货出单---拣货复核
        component: () => import('@/views/supplier/components/wms/exWarehouse/packWorkingTab')
      },
      {
        path: '/wms/scanEx', // 仓储管理--扫描出库
        component: () => import('@/views/supplier/components/wms/exWarehouse/scanEx'),
        meta: {
          parentPath: '/wms/packingManage'
        }
      },
      {
        path: '/wms/packingManage', // 仓储管理--提货单
        name: 'wms/packingManage',
        component: () => import('@/views/supplier/components/wms/exWarehouse/packingManage')
      },
      {
        path: '/wms/searchPackage', // 仓储管理--出库单
        component: () => import('@/views/supplier/components/wms/exWarehouse/searchPackage')
      },
      {
        path: '/addManualSupplierOrder', // 仓储管理--出库单检索---新增出库单
        component: () => import('@/views/supplier/components/wms/exWarehouse/addManualSupplierOrder')
      },
      {
        path: '/wms/searchPackageDetails', // 仓储管理--出库单检索--出库单详情
        component: () => import('@/views/supplier/components/wms/exWarehouse/sellStockOutDtl'),
        meta: {
          parentPath: '/wms/searchPackage'
        }
      },
      {
        path: '/accountManage/basicInformation', // 账户管理--基本信息
        component: () => import('@/views/supplier/components/accountManage/basicInformation')
      },
      {
        path: '/accountManage/management', // 账户管理--员工管理
        component: () => import('@/views/supplier/components/accountManage/management')
      },
      {
        path: '/accountManage/collectionAccount', // 账户管理--收款账户
        component: () => import('@/views/supplier/components/accountManage/collectionAccount')
      },
      {
        path: '/platformAuthorization', // 第三方平台--平台授权
        component: () => import('@/views/supplier/components/thirdPartyPlatform/platformAuthorization')
      }
    ]
  },
  {
    path: '/printPickList', // 打印拣货单
    component: resolve => {
      require(['@/views/supplier/components/wms/exWarehouse/printPickList.vue'], resolve);
    }
  },
  {
    path: '/printPickListBatch', // 批量打印拣货单
    component: resolve => {
      require(['@/views/supplier/components/wms/exWarehouse/printPickListBatch.vue'], resolve);
    }
  },
  {
    path: '/sortingInfo', // 扫描多品分拣
    component: resolve => {
      require(['@/views/supplier/components/wms/exWarehouse/sortingInfo.vue'], resolve);
    }
  },
  {
    path: '/packingWork', // 扫描拣货复核
    component: resolve => {
      require(['@/views/supplier/components/wms/exWarehouse/packingWork.vue'], resolve);
    }
  },
  {
    path: '/packingWork/prepareGoods', // 扫描拣货复核(备货)
    component: resolve => {
      require(['@/views/supplier/components/wms/exWarehouse/packingWorkPg.vue'], resolve);
    }
  },
  {
    path: '/printPaperList', // 批量打印纸质清单
    component: resolve => {
      require(['@/views/supplier/components/supplierOrder/printPaperList.vue'], resolve);
    }
  },
  {
    path: '/viewGuideContent', // 查系统指引内容
    component: resolve => {
      require(['@/components/layout/viewGuideContent.vue'], resolve);
    }
  },
  {
    path: '/productDetailsByExternal', // 商品中心---商品管理---商品列表---新增/编辑商品
    component: () => import('@/components/common/productDetails'),
    meta: {
      parentPath: '/productCenter/productGoods'
    }
  },
];
const router = new Router({
  mode: 'hash',
  routes
});
router.beforeEach((to, from, next) => {
  localStorage.setItem('system', 'supplier');
  // 判断当前的token是否已经过期 parentMenuKey
  const token = getToken();
  let pathName = '';
  let pathIndex = '';
  if (Object.keys(to.meta).length > 0 && to.meta.parentPath) {
    pathIndex = to.meta.parentPath.split('/');
  } else {
    pathIndex = to.path.split('/');
  }
  if (pathIndex[2]) {
    pathName = pathIndex[1] + '_' + pathIndex[2];
  } else {
    pathName = pathIndex[1];
  }
  let menuKey = 'supplier_' + pathName;
  getYmsInfoConfig();
  getConfigureLanguage();
  if (token) {
    refreshToken(countTime);
    getQueryMenu().then(() => {
      Promise.all([getErpconfig(''), getSinglePageRoleCommon(menuKey), isBillConfirmed(to)]).then(result => {
        if (result[0] && result[1]) {
          nonexistent(to, next);
        }
      });
    });
  } else {
    getAccessToken(to.fullPath).then(result => {
      if (result) {
        setTimeout(function () {
          refreshToken(countTime);
        }, countTime);
        getQueryMenu().then(() => {
          Promise.all([getErpconfig(''), getSinglePageRoleCommon(menuKey), isBillConfirmed(to)]).then(result => {
            if (result[0] && result[1]) {
              nonexistent(to, next);
            }
          });
        });
      }
    });
  }
});
export default router;
